import { Booking } from "./Booking";
import RestRepository from "../../infra/RestRepository";

export default class BookingRestRepo extends RestRepository {
  constructor() {
    super("/api/bookings");
  }

  async addBooking(booking: Omit<Booking, "id">): Promise<Booking> {
    return (await this.post("/", booking)) as Promise<Booking>;
  }

  async getBookings(weekFirstDay: string): Promise<Booking[]> {
    return (await this.get(`/?week=${weekFirstDay}`)) as Promise<Booking[]>;
  }

  async deleteBooking(booking: Booking) {
    return (await this.delete(`/${booking.id}`)) as Promise<Booking>;
  }

  async saveBooking(booking: Booking) {
    return (await this.put(`/${booking.id}`, booking)) as Promise<Booking>;
  }
}
