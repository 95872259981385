import React from "react";
import {
  AimOutlined,
  QuestionCircleOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import { Button, Select, Tooltip } from "antd";

import { Listing } from "./EmployeeListing/FloorDashboardListing";
import { Panels } from "./Panels";

import "./FloorDashboard.css";
import { PeopleTree } from "../../component/people-tree/PeopleTree";
import { useDispatch, useSelector } from "react-redux";
import { useAuthUser, useBookingPermissions, useOfficeSpace } from "../../hook/hooks";
import { FloorViewer } from "../../component/floor/viewer/FloorViewer";
import FloorViewerController from "../../component/floor/viewer/FloorViewerController";
import { Booking, Shifts } from "../../../domain/booking/Booking";
import { changeViewedFloor, setHighlightedCollaborators } from "../../redux/actions";
import PanSection from "../../component/pan-section/PanSection";
import { useTranslation } from "react-i18next";
import { Collaborator } from "../../../domain/collaborator/Collaborator";
import { RootState } from "../../redux/state";

const FIRST_PANEL_WIDTH = 200;
const SECOND_PANEL_WIDTH = 200;

export default () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const permissions = useBookingPermissions();
  const space = useOfficeSpace();
  const allFloors = space.getAllFloors();
  const floors = allFloors.filter(f => f.floorPlanId);

  const viewedFloorId = useSelector<RootState, number | undefined>(s => s.viewedFloor);
  const floorId = viewedFloorId
    ? viewedFloorId
    : floors.length > 0
    ? floors[0].id
    : undefined;
  const floor = floorId ? space.getFloorById(floorId) : undefined;
  const floorPlan = floorId ? space.getFloorPlanByFloorId(floorId) : undefined;
  const maxBooking = space.getMaxBookingByFloorId(floorId);

  const day = useSelector<RootState, string>(s => s.selectedDay);
  const collaborators = useSelector<RootState, Collaborator[]>(s => s.collaborators);
  const user = useAuthUser();
  const shifts = useSelector<RootState, Shifts>(s => s.shifts);

  const allBookings = useSelector<RootState, Booking[]>(s => s.bookings);
  const bookingOnFloorThatDay = allBookings.filter(
    b =>
      b.type === "office" &&
      b.date === day &&
      floorPlan &&
      b.deskId &&
      space.getDesk(b.deskId)?.floorPlanId === floorPlan?.id
  );

  const currentCollaboratorId = user.collaboratorId;

  const controller = new FloorViewerController(
    dispatch,
    permissions,
    day,
    user,
    collaborators
  );

  const myDeskId = shifts[currentCollaboratorId]?.bookings[day]?.deskId;
  const myFloor = myDeskId ? space.getFloorByDeskId(myDeskId) : undefined;

  const firstPanel = React.useCallback(() => {
    return (
      <div
        style={{
          backgroundColor: "#f9f9f9",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: `${FIRST_PANEL_WIDTH}px`,
        }}
      >
        <PanSection title={t("main.communities")}>
          <PeopleTree key="people-tree" />
        </PanSection>
      </div>
    );
  }, []);

  // TODO: Change 90% for an absolute positioning layout
  const SecondPanel = () => {
    return (
      <div
        style={{
          width: `${SECOND_PANEL_WIDTH}px`,
        }}
      >
        <PanSection title={t("floorplan.where-i")}>
          {myFloor ? <div>{myFloor.name}</div> : <div>{t("floorplan.not-present")}</div>}

          {myFloor && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                icon={<AimOutlined />}
                onClick={() => {
                  dispatch(changeViewedFloor({ floorId: myFloor.id }));
                  dispatch(
                    setHighlightedCollaborators({
                      collaboratorIds: [currentCollaboratorId],
                    })
                  );
                }}
                size="small"
              >
                {t("floorplan.locate")}
              </Button>
            </div>
          )}
        </PanSection>

        <PanSection title={t("main.floor")}>
          <Select
            value={floorId}
            onChange={val => dispatch(changeViewedFloor({ floorId: val }))}
          >
            {floors.map(floor => (
              <Select.Option key={floor.id} value={floor.id}>
                {space.getFloorName(floor.id)}
              </Select.Option>
            ))}
          </Select>

          {floor && maxBooking !== undefined && (
            <>
              <div className="mt-md">
                {t("floor.occupancy")}
                <Tooltip className={"ml-xs"} title={t("floor.max-booking-tooltip")}>
                  <QuestionCircleOutlined />
                </Tooltip>{" "}
                :
              </div>
              <div
                style={{
                  color: bookingOnFloorThatDay.length >= maxBooking ? "red" : "unset",
                }}
              >
                {bookingOnFloorThatDay.length} / {maxBooking}
              </div>
            </>
          )}
        </PanSection>

        <PanSection title={t("floorplan.where-people")}>
          {floorPlan && <Listing floorPlan={floorPlan} />}
        </PanSection>
      </div>
    );
  };

  return (
    <div
      className="master-first-showing master-second-showing"
      id="panels-toplevel"
      style={{
        display: "flex",
        position: "relative",
        width: "100%",
      }}
    >
      <Panels
        First={firstPanel}
        Rest={floorPlan && <FloorViewer floorPlan={floorPlan} controller={controller} />}
        Second={SecondPanel}
      />

      <div
        id="first-panel-arrow"
        onClick={() => {
          document
            .getElementById("panels-toplevel")
            ?.classList.toggle("master-first-showing");
        }}
      >
        <RightCircleOutlined />
      </div>
      <div
        id="second-panel-arrow"
        onClick={() => {
          document
            .getElementById("panels-toplevel")
            ?.classList.toggle("master-second-showing");
        }}
      >
        <RightCircleOutlined />
      </div>
    </div>
  );
};
