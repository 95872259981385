import RestRepository from "../../infra/RestRepository";
import { Workspace, WorkspaceFormData } from "./Workspace";

export default class WorkspaceRestRepo extends RestRepository {
  constructor() {
    super("/api/workspaces");
  }

  async getWorkspace(wid: number): Promise<Workspace> {
    return super.get(wid);
  }

  update(data: WorkspaceFormData): Promise<Workspace> {
    return super.put("/", { ...data });
  }

  sendEmailOnBoarding(id: number) {
    return super.post("/emails/onboarding", { id });
  }
}
