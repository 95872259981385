import moment, { Moment } from "moment";

export default class Week {
  private static DATE_PATTERN = "YYYY-MM-DD";

  days = new Array<string>();
  selected = "";

  constructor(state?: { days?: string[]; selected: string }) {
    if (state) {
      if (state.days) {
        this.days.push(...state.days);
      } else {
        this.days.push(
          ...[0, 1, 2, 3, 4].map(idx =>
            moment(state.selected)
              .startOf("isoWeek")
              .add(idx, "days")
              .format("YYYY-MM-DD")
          )
        );
      }
      this.selected = state.selected;
    } else {
      this.resetThisWeek();
    }
  }

  private resetThisWeek() {
    this.resetWithMonday(moment().startOf("isoWeek"));
  }

  private resetWithMonday(monday: Moment) {
    this.days = [];
    this.selected = "";

    const thisDayStr = moment().format(Week.DATE_PATTERN);
    const d = moment(monday);

    // Monday to Friday
    [0, 1, 2, 3, 4].forEach(() => {
      const ds = d.format(Week.DATE_PATTERN);

      if (ds === thisDayStr) {
        this.selected = ds;
      }

      this.days.push(ds);
      d.add(1, "days");
    });

    // If today is in weekend, last day is selected
    if (!this.selected) this.selected = this.days[0];
  }

  public previousWeek() {
    this.resetWithMonday(moment(this.days[0]).subtract(7, "days"));
    return this;
  }

  public nextWeek() {
    this.resetWithMonday(moment(this.days[0]).add(7, "days"));
    return this;
  }

  public todayWeek() {
    const thisMonday = moment().startOf("isoWeek").format(Week.DATE_PATTERN);

    if (this.days.indexOf(thisMonday) > -1) return this;

    this.resetThisWeek();

    return this;
  }

  public dayByIndex(index: number): string {
    if (index < 0 || index > 4) return "";
    return this.days[index];
  }

  public indexOfSelected() {
    return this.indexOfDay(this.selected);
  }

  public indexOfDay(day: string) {
    return this.days.indexOf(day);
  }

  public selectDayAt(index: number) {
    if (index > -1 && index < 5) {
      this.selected = this.days[index];
    }
    return this;
  }

  public isSelected(day: string) {
    return this.selected === day;
  }

  public selectDay(day: string) {
    if (this.days.indexOf(day) > -1) {
      this.selected = day;
    }
    return this;
  }

  firstDay() {
    return this.days[0];
  }

  static isBeforeToday(day: string) {
    return moment().startOf("day") > moment(day);
  }

  static isToday(day: string) {
    return moment().format(Week.DATE_PATTERN) === day;
  }

  static formatDay(day: string) {
    return moment(day).format("DD/MM");
  }

  static dayIndex(day: string) {
    return moment(day).isoWeekday();
  }
}
