import { Desk } from "../../../../domain/floorPlan/FloorPlan";
import { Booking } from "../../../../domain/booking/Booking";
import { Zone } from "../../../../types";
import FloorViewerController, { DragToDesk, DragType } from "./FloorViewerController";
import React, { useCallback, useMemo } from "react";
import { Button } from "antd";
import Tippy from "@tippyjs/react";
import classNames from "classnames";
import DeskIcon from "../DeskShape";
import FloorBooking from "./FloorBooking";
import { useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";

export default (props: {
  desk: Desk;
  booking?: Booking;
  zoom: number;
  zones: Zone[];
  highlighted?: boolean;
  controller: FloorViewerController;
}) => {
  const { desk, zones, controller, zoom, booking, highlighted = false } = props;
  const { t } = useTranslation();

  const [striped, color, isDeskBookable] = useMemo(() => {
    const isDeskBookable = !booking && controller.isDeskBookable(desk);

    let color = "#ccc";
    let striped = false;

    if (desk.disabled) {
      striped = true;
    } else {
      const zone = zones.find(z => z.id === desk.zoneId);
      if (zone?.color) color = zone.color;

      if (!booking && !isDeskBookable) {
        striped = true;
      }
    }

    return [striped, color, isDeskBookable];
  }, [booking, controller, desk, zones]);

  const renderTooltip = useCallback(() => {
    if (!isDeskBookable) return <div>{t("desk.not-bookable")}</div>;
    return (
      <Button onClick={() => controller.bookDesk(desk)} size="small">
        {controller.hasUserBookingToMove() ? `${t("main.move")}` : `${t("main.book")}`}
      </Button>
    );
    // TODO @Billy
  }, [controller, desk, isDeskBookable]);

  const [collected, drop] = useDrop({
    accept: DragType.drag_to_desk,
    drop: item => controller.onDropDesk(item as DragToDesk, desk),
    // TODO @Billy
    canDrop: item => !booking && isDeskBookable,
    collect: monitor => ({
      color: monitor.canDrop() && monitor.isOver() ? "green" : color,
    }),
  });

  return (
    <>
      <Tippy
        content={renderTooltip()}
        theme="light"
        trigger="click"
        interactive={true}
        hideOnClick={true}
        disabled={!!booking}
      >
        <div
          className={classNames([
            "floor-desk",
            `desk-${desk.id}`,
            {
              disabled: desk.disabled,
              "not-bookable": !isDeskBookable,
            },
          ])}
          ref={drop}
          style={{
            top: (desk.cy - desk.h / 2) * zoom,
            left: (desk.cx - desk.w / 2) * zoom,
            width: desk.w * zoom,
            height: desk.h * zoom,
            transform: `rotate(${desk.rot}deg)`,
          }}
        >
          <DeskIcon
            width={desk.w * zoom}
            height={desk.h * zoom}
            striped={striped}
            color={collected.color}
          />
        </div>
      </Tippy>

      {booking && (
        <FloorBooking
          key={booking.id}
          booking={booking}
          zoom={zoom}
          desk={desk}
          controller={controller}
          highlighted={highlighted}
        />
      )}
    </>
  );
};
