import React from "react";

// Antd
import { Modal } from "antd";

type Props = {
  onOk?: () => void;
  onCancel?: () => void;
  children: React.ReactElement;
  title?: string;
  state: boolean;
};

export const ModalSetting = (props: Props) => {
  const { title, state, children, onOk, onCancel } = props;

  return (
    <Modal
      destroyOnClose
      footer={null}
      centered
      title={title}
      visible={state}
      onOk={onOk}
      onCancel={onCancel ? onCancel : () => {}}
    >
      {children}
    </Modal>
  );
};
