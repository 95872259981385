import { useCallback } from "react";
import { Desk } from "../../../../../domain/floorPlan/FloorPlan";
import md5 from "md5";

export type EditorDesk = Omit<Desk, "id"> & {
  /**
   * Define id as optional to deal with new desk
   */
  id?: number;
  /**
   * Hash is used to identify a unique desk. The 'id' attr is not used because it is required for fresh new desk.
   */
  hash: string;
};

export const useAssignStatus = (
  desks: EditorDesk[],
  selection: string[],
  setDesks: (d: EditorDesk[]) => void
) => {
  return useCallback(
    (status: "enabled" | "disabled") => {
      desks
        .filter(d => selection.includes(d.hash))
        .forEach(d => (d.disabled = status === "disabled"));

      setDesks([...desks]);
    },
    [desks, selection, setDesks]
  );
};

export const useAssignZone = (
  desks: EditorDesk[],
  setDesks: (d: EditorDesk[]) => void,
  selection: string[]
) => {
  return useCallback(
    (zoneId: number) => {
      desks
        .filter(d => selection.includes(d.hash))
        .forEach(d => (d.zoneId = zoneId > 0 ? zoneId : undefined));

      setDesks([...desks]);
    },
    [desks, selection, setDesks]
  );
};

export const useAlign = (
  desks: EditorDesk[],
  setDesks: (d: EditorDesk[]) => void,
  selection: string[]
) => {
  return useCallback(
    (side: "top" | "bottom" | "left" | "right") => {
      const selected = desks.filter(d => selection.includes(d.hash));
      if (selected.length === 0) return;

      if (side === "top") {
        const v = selected.map(d => d.cy).reduce((acc, v) => Math.min(acc, v), 99999);
        selected.forEach(d => (d.cy = v));
      } else if (side === "bottom") {
        const v = selected
          .map(d => d.cy + d.h / 2)
          .reduce((acc, v) => Math.max(acc, v), 0);
        selected.forEach(d => (d.cy = v - d.h / 2));
      } else if (side === "left") {
        const v = selected.map(d => d.cx).reduce((acc, v) => Math.min(acc, v), 99999);
        selected.forEach(d => (d.cx = v));
      } else if (side === "right") {
        const v = selected
          .map(d => d.cx + d.w / 2)
          .reduce((acc, v) => Math.max(acc, v), 0);
        selected.forEach(d => (d.cx = v - d.w / 2));
      }

      setDesks([...desks]);
    },
    [desks, selection, setDesks]
  );
};

export const useRotate = (
  desks: EditorDesk[],
  setDesks: (d: EditorDesk[]) => void,
  selection: string[]
) => {
  return useCallback(
    (ang: number, incr = false) => {
      desks
        .filter(d => selection.includes(d.hash))
        .forEach(desk => {
          if (incr) desk.rot += ang;
          else desk.rot = ang;
        });

      setDesks([...desks]);
    },
    [desks, selection, setDesks]
  );
};

export const useSize = (
  desks: EditorDesk[],
  setDesks: (d: EditorDesk[]) => void,
  selection: string[]
) => {
  return useCallback(
    (d: { width?: number; height?: number }) => {
      desks
        .filter(d => selection.includes(d.hash))
        .forEach(desk => {
          if (d.width) desk.w = d.width;
          if (d.height) desk.h = d.height;
        });

      setDesks([...desks]);
    },
    [desks, selection, setDesks]
  );
};

export const useCopy = (
  desks: EditorDesk[],
  setDesks: (d: EditorDesk[]) => void,
  selection: string[],
  setSelection: (d: string[]) => void
) => {
  return useCallback(() => {
    if (selection.length > 0) {
      const news = desks
        .filter(d => selection.includes(d.hash))
        .map(d => {
          const n = {
            id: undefined,
            hash: md5(`${Math.random()}`),
            cx: d.cx,
            cy: d.cy,
            disabled: d.disabled,
            floorPlanId: d.floorPlanId,
            h: d.h,
            rot: d.rot,
            w: d.w,
            wid: d.wid,
          };

          n.cx += 10;
          n.cy += 10;
          return n;
        });

      setDesks([...desks, ...news]);
      setSelection(news.map(n => n.hash));
    }
  }, [desks, selection, setDesks, setSelection]);
};

export const useAddNewDesk = (
  wid: number,
  floorPlanId: number,
  desks: EditorDesk[],
  setDesks: (d: EditorDesk[]) => void,
  setSelection: (d: string[]) => void
) => {
  return useCallback(() => {
    const ref = desks.length > 0 ? desks[0] : null;

    const d = {
      floorPlanId,
      wid,
      hash: md5(`${Math.random()}`),
      cx: 100,
      cy: 100,
      w: ref?.w || 50,
      h: ref?.h || 25,
      rot: 0,
    };

    setDesks([...desks, d]);
    setSelection([d.hash]);
  }, [desks, floorPlanId, setDesks, setSelection, wid]);
};
