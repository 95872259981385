import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

// Ant
import { Checkbox, Form, Input, Tooltip, Select, Button, Spin } from "antd";
import {
  CalendarOutlined,
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
  LinkOutlined,
  TeamOutlined,
} from "@ant-design/icons";

import { LuccaRestRepo } from "../../../../../domain/lucca/LuccaRestRepo";
import { showError } from "../../../../../domain/notification/NotificationService";

// Type, enums
import { Status } from "./LuccaIntegration";
import { RootState } from "../../../../redux/state";

import "../../integration-setting.less";
import { SelectValue } from "antd/lib/select";

type ValueType = {
  apiKey: string;
  syncCollaborators: boolean;
  syncBookingsFromLucca: boolean;
  syncBookingToLucca: boolean;
  bookingFromAccounts: number[];
  bookingToAccount: number | null | undefined;
};

type Account = {
  id: number;
  name: string;
};

type Props = {
  luccaInstallId: number | null;
  defaultValues: ValueType;
  status: Status;
  onFinish: (values: any) => void;
};

const luccaRestRepo = new LuccaRestRepo();

export const LuccaIntegrationForm = (props: Props) => {
  const { luccaInstallId, defaultValues, onFinish, status } = props;
  let luccaUrl;

  // State
  const [listAccounts, setListAccounts] = useState<Account[]>([]);
  const [listBookingFromAccounts, setListBookingFromAccounts] = useState<Account[]>([]);
  const [listBookingToAccount, setListBookingToAccount] = useState<Account[]>([]);

  // hooks
  const { t } = useTranslation();
  const currentLang = useSelector<RootState, string | undefined>(s => s.user?.lang);
  const [form] = Form.useForm();

  const isDisabled = status === Status.Disconnected;

  const onSelectBookingFromAccounts = (value: SelectValue) => {
    form.setFieldsValue({ bookingFromAccounts: value });
    setListBookingToAccount([
      ...listAccounts.filter(
        account => !form.getFieldValue("bookingFromAccounts").includes(account.id)
      ),
    ]);
  };

  const onSelectBookingToAccount = (value: SelectValue) => {
    form.setFieldsValue({ bookingToAccount: value });
    setListBookingFromAccounts([
      ...listAccounts.filter(
        account => form.getFieldValue("bookingToAccount") !== account.id
      ),
    ]);
  };

  useEffect(() => {
    if (status === Status.Connected && luccaInstallId) {
      luccaRestRepo
        .getLuccaAccounts()
        .then(res => {
          setListBookingFromAccounts([
            ...res.filter(
              account => form.getFieldValue("bookingToAccount") !== account.id
            ),
          ]);
          setListBookingToAccount([
            ...res.filter(
              account => !form.getFieldValue("bookingFromAccounts").includes(account.id)
            ),
          ]);
          setListAccounts(res);
        })
        .catch(() => {
          showError(t("lucca.fetch-account-failed"));
        });
    }
  }, [form, luccaInstallId, status, t]);

  if (currentLang === "en")
    luccaUrl =
      "https://support.lucca.fr/hc/en-us/articles/115000084851-Generating-an-API-key";
  else
    luccaUrl =
      "https://support.lucca.fr/hc/fr/articles/115000084851-G%C3%A9n%C3%A9rer-une-cl%C3%A9-API";

  return (
    <Form
      form={form}
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 20,
      }}
      name="lucca-settings-form"
      onFinish={onFinish}
      initialValues={{
        ...defaultValues,
      }}
    >
      <Form.Item
        label={
          <>
            {t("lucca.client-id")}
            <a
              className={"ml-xs"}
              href={luccaUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkOutlined />
            </a>
          </>
        }
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        name="apiKey"
        rules={[{ required: true, message: t("lucca.rule-api-key-required") }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        name="baseUrl"
        label={t("lucca.base-url")}
        rules={[
          {
            required: true,
            message: t("lucca.rule-base-url-required"),
          },
          {
            pattern: /^https:\/\/.*/,
            message: t("lucca.rule-base-url-pattern"),
          },
        ]}
      >
        <Input placeholder={"https://example-url.com"} />
      </Form.Item>

      <Form.Item
        wrapperCol={{ span: 24 }}
        name={"syncCollaborators"}
        valuePropName="checked"
      >
        <Checkbox disabled={isDisabled}>
          <span>
            <TeamOutlined />{" "}
          </span>
          {t("lucca.user-provisioning")}
          <Tooltip className={"ml-xs"} title={t("lucca.userProvisioningDescription")}>
            <QuestionCircleOutlined />
          </Tooltip>
        </Checkbox>
      </Form.Item>
      <Form.Item
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 16 }}
        name={"syncBookingsFromLucca"}
        valuePropName="checked"
      >
        <Checkbox
          disabled={isDisabled}
          onChange={e => {
            form.setFieldsValue({ syncBookingsFromLucca: e.target.checked });
          }}
        >
          <>
            <span>
              <CalendarOutlined />
            </span>
            <span className={"ml-xs"}>{t("lucca.booking-lucca-to-semana-label")}</span>
            <Tooltip className={"ml-xs"} title={t("lucca.booking-lucca-to-semana")}>
              <QuestionCircleOutlined />
            </Tooltip>
          </>
        </Checkbox>
      </Form.Item>

      <div className={"ml-lg"}>
        <label>{t("lucca.lucca-accounts", { val: t("lucca.accounts") })}</label>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, curValues) =>
            prevValues.syncBookingsFromLucca !== curValues.syncBookingsFromLucca
          }
        >
          {() => (
            <Form.Item wrapperCol={{ span: 24 }} name={"bookingFromAccounts"}>
              <Select
                onChange={e => onSelectBookingFromAccounts(e)}
                disabled={!form.getFieldValue("syncBookingsFromLucca")}
                style={{ width: "100%" }}
                mode="multiple"
              >
                {listBookingFromAccounts.map((account, idx) => (
                  <Select.Option
                    key={`bookingFromAccounts-${account.id}-${idx}`}
                    value={account.id}
                  >
                    {account.id} - {account.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Form.Item>
      </div>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) =>
          prevValues.syncBookingToLucca !== curValues.syncBookingToLucca
        }
      >
        {() => (
          <Form.Item
            wrapperCol={{ span: 24 }}
            name={"syncBookingToLucca"}
            valuePropName="checked"
          >
            <Checkbox
              disabled={isDisabled}
              onChange={e =>
                form.setFieldsValue({ syncBookingToLucca: e.target.checked })
              }
            >
              <>
                <span>
                  <CalendarOutlined />
                </span>
                <span className={"ml-xs"}>
                  {t("lucca.booking-semana-to-lucca-label")}
                </span>
                <Tooltip className={"ml-xs"} title={t("lucca.booking-semana-to-lucca")}>
                  <QuestionCircleOutlined />
                </Tooltip>

                {form.getFieldValue("syncBookingToLucca") === true && (
                  <div className={"mt-xs"}>
                    <ExclamationCircleOutlined style={{ color: "#ffa940" }} />
                    <span className={"ml-xs"}>
                      {t("lucca.synchronize-to-lucca-warning")}
                    </span>
                  </div>
                )}
              </>
            </Checkbox>
          </Form.Item>
        )}
      </Form.Item>

      <div className={"ml-lg"}>
        <label>{t("lucca.lucca-accounts", { val: t("lucca.account") })}</label>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, curValues) =>
            prevValues.syncBookingToLucca !== curValues.syncBookingToLucca
          }
        >
          {() => (
            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.syncBookingToLucca !== curValues.syncBookingToLucca
              }
              wrapperCol={{ span: 24 }}
              name={"bookingToAccount"}
            >
              <Select
                onChange={e => onSelectBookingToAccount(e)}
                disabled={!form.getFieldValue("syncBookingToLucca")}
                style={{ width: "100%" }}
              >
                {listBookingToAccount.map((account, idx) => (
                  <Select.Option
                    key={`bookingToAccount-${account.id}-${idx}`}
                    value={account.id}
                  >
                    {account.id} - {account.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Form.Item>
      </div>
      <Form.Item wrapperCol={{ span: 16, offset: 9 }}>
        <Button type="primary" htmlType="submit">
          {status !== Status.Pending && t("main.save")}
          {status === Status.Pending && <Spin size="small" />}
        </Button>
      </Form.Item>
    </Form>
  );
};
