import moment, { Moment } from "moment";

type DateType = string;
type TypeUnit = "days" | "month" | "year" | "weeks";

export class DateTime {
  static readonly DATE_PATTERN = "yyyy-MM-DD";
  constructor() {}

  static addFromNow(number: number, unit: TypeUnit) {
    return moment().add(number, unit);
  }

  static addAndFormatString(
    date: DateType,
    number: number,
    unit: TypeUnit,
    format: DateType = DateTime.DATE_PATTERN
  ) {
    return moment(date, format).add(number, unit).format(format);
  }

  static subtractFromNow(
    numberOfDays: number,
    unit: TypeUnit,
    format: DateType = DateTime.DATE_PATTERN
  ) {
    return moment().subtract(numberOfDays, unit).format(format);
  }

  static isAfter(currentDate: Moment, targetDate: Moment) {
    return moment(currentDate, DateTime.DATE_PATTERN).isAfter(targetDate);
  }

  static todayString(format: DateType = DateTime.DATE_PATTERN) {
    return moment().format(format);
  }

  static dateFormat(date: DateType, format: DateType = DateTime.DATE_PATTERN) {
    return moment(date, format);
  }

  static formatDateToString(date: DateType, format: DateType = DateTime.DATE_PATTERN) {
    return moment(date, format).format(format);
  }

  static getDateRange(
    start: DateType,
    end: DateType,
    format: DateType = DateTime.DATE_PATTERN
  ) {
    const dateRange: Array<string> = [];
    let currentDate = DateTime.formatDateToString(start, format);
    const formatEndDate = DateTime.formatDateToString(end, format);

    while (currentDate !== formatEndDate) {
      dateRange.push(currentDate);
      currentDate = DateTime.addAndFormatString(currentDate, 1, "days", format);
    }

    dateRange.push(currentDate);
    return dateRange;
  }

  static format(date: string, format: DateType = DateTime.DATE_PATTERN) {
    return moment(date, format).format(format);
  }
}
