import React from "react";
import { useTranslation } from "react-i18next";
import GoogleLogin from "react-google-login";
import { env } from "../../../../../infra/Environment";
import { GoogleRenderProps } from "./GoogleIntegration";

// Ant
import { Button, Spin } from "antd";
import { WarningOutlined } from "@ant-design/icons";

import { GoogleResponseSuccess, GoogleResponseFailure } from "./GoogleIntegration";

type Props = {
  isConnected: boolean;
  refreshTokenIsExpired?: boolean;
  isLoading: boolean;
  handleOpenSettings: () => void;
  onDisconnect: () => void;
  responseGoogleSuccess: (res: GoogleResponseSuccess) => void;
  responseGoogleFailure: (res: GoogleResponseFailure) => void;
};

export const GoogleIntegrationButton = ({
  isConnected,
  refreshTokenIsExpired,
  isLoading,
  handleOpenSettings,
  onDisconnect,
  responseGoogleSuccess,
  responseGoogleFailure,
}: Props) => {
  // hooks
  const { t } = useTranslation();

  const handleAuthorize = (renderProps: GoogleRenderProps) => {
    renderProps.onClick();
  };

  return (
    <>
      {((isConnected && refreshTokenIsExpired) || !isConnected) && (
        <GoogleLogin
          render={renderProps => (
            <>
              <Button
                type={refreshTokenIsExpired && !isLoading ? "primary" : "default"}
                icon={refreshTokenIsExpired && !isLoading && <WarningOutlined />}
                disabled={isLoading}
                onClick={() => handleAuthorize(renderProps)}
              >
                {!isLoading ? t("google.connect") : <Spin size="small" />}
              </Button>
              {refreshTokenIsExpired && !isLoading && (
                <p className={"mt-xs warning-token-expired"}>
                  {t("google.refresh-token-expired")}{" "}
                </p>
              )}
            </>
          )}
          scope={env.googleScopes}
          clientId={env.googleClientId}
          accessType={"offline"}
          responseType={"code"}
          onSuccess={responseGoogleSuccess}
          onFailure={responseGoogleFailure}
          prompt="consent"
        />
      )}

      {isConnected && !refreshTokenIsExpired && (
        <>
          <Button disabled={isLoading} type="primary" onClick={handleOpenSettings}>
            {t("google.settings")}
          </Button>
          <Button type="link" disabled={isLoading} onClick={onDisconnect}>
            {!isLoading ? (
              <span className={"disconnect-button-text"}>{t("google.disconnect")}</span>
            ) : (
              <Spin size="small" />
            )}
          </Button>
        </>
      )}
    </>
  );
};

export default GoogleIntegrationButton;
