import RestRepository from "../../infra/RestRepository";
import { Desk } from "./FloorPlan";

export default class FloorPlanRestRepo extends RestRepository {
  constructor() {
    super("/api/fp");
  }

  saveDesks(floorPlanId: number, desks: Partial<Desk>[]): Promise<Desk[]> {
    return this.post(`/${floorPlanId}/desks`, desks);
  }
}
