import React from "react";
import { EmployeeListItem } from "../EmployeeListItem";
import { Booking } from "../../../../domain/booking/Booking";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/state";
import { useTranslation } from "react-i18next";
import { FloorPlan } from "../../../../domain/floorPlan/FloorPlan";

export const Listing = (props: { floorPlan: FloorPlan }) => {
  const { floorPlan } = props;
  const desks = floorPlan.desks;
  const deskIds = desks.map(d => d.id);
  const { t } = useTranslation();

  const selectedDay = useSelector<RootState, string>(s => s.selectedDay);

  const bookings = useSelector<RootState, Booking[]>(s => s.bookings)
    .filter(b => b.date === selectedDay)
    .filter(b => b.deskId && deskIds.includes(b.deskId));

  const sectionBreakdown = bookings.map(booking => {
    return (
      <EmployeeListItem
        key={booking.collaboratorId}
        collaboratorId={booking.collaboratorId}
      />
    );
  });

  return (
    <div
      id="listing"
      style={{
        height: "100%",
        paddingRight: "14px",
        position: "relative",
        width: "214px",
        zIndex: 0, // this too, maybe externalize this?
      }}
    >
      <div
        className="floor-employee-listing"
        style={{
          flexShrink: 0,
          left: "0px",
          opacity: 1,
          position: "relative",
          width: "100%",
        }}
      >
        {sectionBreakdown}
        {sectionBreakdown.length === 0 && (
          <div
            style={{
              backgroundColor: "aliceblue",
              borderRadius: "4px",
              marginTop: "46px",
              padding: "10px",
            }}
          >
            {t("floorplan.nobody")}
          </div>
        )}
      </div>
    </div>
  );
};
