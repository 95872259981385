import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Form,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import { useOrganization } from "../../hook/hooks";
import React, { useCallback, useState } from "react";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { RootState } from "../../redux/state";
import { Policy } from "../../../domain/policy/Policy";
import CommunityTag from "../../component/people-tag/PeopleTag";
import { deletePolicy, savePolicy } from "../../redux/policy/actions";
import { Optional } from "../../../types";
import PeopleSearch from "../../component/people-search/PeopleSearch";
import { Collaborator } from "../../../domain/collaborator/Collaborator";
import Text from "antd/es/typography/Text";
import { Community } from "../../../domain/community/Community";

type CommunityCollabPolicy = Policy & {
  community?: Community;
  collaborator?: Collaborator;
};

const compareCollab = (a?: Collaborator, b?: Collaborator) => {
  if (a && !b) return 1;
  if (!a && b) return -1;
  if (!a && !b) return 0;

  return a!.name.toLocaleLowerCase().localeCompare(b!.name.toLowerCase());
};

type FormData = {
  appliesTo?: { communityId?: number; collaboratorId?: number };
  maxRemote?: number;
  maxOffice?: number;
  fixedDays?: string[];
};

const FixedDayTags = (props: { policy: Policy }) => {
  const { t } = useTranslation();

  const noFixedDays =
    !props.policy.fixedMon &&
    !props.policy.fixedTue &&
    !props.policy.fixedWed &&
    !props.policy.fixedThu &&
    !props.policy.fixedFri;

  return (
    <>
      {props.policy.fixedMon && <Tag>{t("calendar.week-day-1")}</Tag>}
      {props.policy.fixedTue && <Tag>{t("calendar.week-day-2")}</Tag>}
      {props.policy.fixedWed && <Tag>{t("calendar.week-day-3")}</Tag>}
      {props.policy.fixedThu && <Tag>{t("calendar.week-day-4")}</Tag>}
      {props.policy.fixedFri && <Tag>{t("calendar.week-day-5")}</Tag>}

      {noFixedDays && <span className="text-gray">{t("policy.no-fixed-days")}</span>}
    </>
  );
};

export default () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const org = useOrganization();

  const comparePolicy = (a: CommunityCollabPolicy, b: CommunityCollabPolicy) => {
    if (a.community && !b.community) return -1;
    if (!a.community && b.community) return 1;
    if (!a.community && !b.community)
      return compareCollab(a.collaborator, b.collaborator);

    // A && b are not empty

    if (a.community !== b.community) {
      return org.compareCommunity(a.community!, b.community!);
    } else {
      return compareCollab(a.collaborator, b.collaborator);
    }
  };

  const [form] = Form.useForm();

  const allPolicies = useSelector<RootState, Policy[]>(s => s.policies);

  const policies: CommunityCollabPolicy[] = allPolicies.map(policy => ({
    ...policy,
    community: policy.collaboratorId
      ? org.findCommunityByCollaboratorId(policy.collaboratorId)
      : org.findCommunityById(policy.communityId),
    collaborator: org.findCollaborator(policy.collaboratorId),
  }));

  policies.sort(comparePolicy);

  const [editingPolicy, setEditingPolicy] = useState<
    Partial<CommunityCollabPolicy> | undefined
  >(undefined);

  const [excludedCommunityIds, excludedCollabIds] = policies.reduce(
    (acc, p) => {
      if (p.collaborator && editingPolicy?.collaboratorId !== p.collaborator.id)
        acc[1].push(p.collaborator.id);
      else if (p.community && editingPolicy?.community?.id !== p.community.id)
        acc[0].push(p.community.id);
      return acc;
    },
    [[], []] as [number[], number[]]
  );

  const onStartEditing = useCallback(
    (policy?: CommunityCollabPolicy) => {
      const data: FormData = {
        maxOffice: policy?.maxOffice !== undefined ? policy.maxOffice : 5,
        maxRemote: policy?.maxRemote !== undefined ? policy.maxRemote : 5,
        fixedDays: [],
      };

      if (policy?.collaborator || policy?.community) {
        data.appliesTo = {
          communityId: !policy?.collaborator ? policy?.community?.id : undefined,
          collaboratorId: policy?.collaboratorId,
        };
      }

      if (policy?.fixedMon) data.fixedDays?.push("1");
      if (policy?.fixedTue) data.fixedDays?.push("2");
      if (policy?.fixedWed) data.fixedDays?.push("3");
      if (policy?.fixedThu) data.fixedDays?.push("4");
      if (policy?.fixedFri) data.fixedDays?.push("5");

      form.setFieldsValue(data);
      setEditingPolicy(policy || {});
    },
    [form]
  );

  const onCancelEditing = useCallback(() => {
    form.resetFields();
    setEditingPolicy(undefined);
  }, [form]);

  const onSave = useCallback(
    (data: FormData) => {
      const policy: Partial<Policy> = { ...editingPolicy };

      policy.communityId = undefined;
      policy.collaboratorId = undefined;

      if (data.appliesTo?.collaboratorId)
        policy.collaboratorId = data.appliesTo?.collaboratorId;
      else if (data.appliesTo?.communityId)
        policy.communityId = data.appliesTo?.communityId;

      policy.maxOffice = data.maxOffice;
      policy.maxRemote = data.maxRemote;

      // Fixed days
      policy.fixedMon = data.fixedDays?.includes("1");
      policy.fixedTue = data.fixedDays?.includes("2");
      policy.fixedWed = data.fixedDays?.includes("3");
      policy.fixedThu = data.fixedDays?.includes("4");
      policy.fixedFri = data.fixedDays?.includes("5");

      dispatch(savePolicy(policy as Optional<Policy, "id">));
      onCancelEditing();
    },
    [dispatch, editingPolicy, onCancelEditing]
  );

  const onDelete = useCallback(
    (record: CommunityCollabPolicy) => {
      dispatch(deletePolicy(record.id));
    },
    [dispatch]
  );

  return (
    <div style={{ width: "100%" }}>
      <h2>{t("policy.page-title")}</h2>

      <p>{t("policy.page-desc")}</p>
      <p>
        <Text type="secondary">{t("policy.page-desc-sec")}</Text>
      </p>

      <Row className="mt-md">
        <Col>
          <Button icon={<PlusOutlined />} onClick={() => onStartEditing()} type="primary">
            {t("main.add")}
          </Button>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Table dataSource={policies} rowKey="id" size="small">
            <Table.Column
              title={t("policy.applies-to")}
              key="appliesTo"
              render={(_text, record: CommunityCollabPolicy) => (
                <CommunityTag
                  key={record.id}
                  community={record.community}
                  collaborator={record.collaborator}
                />
              )}
            />

            <Table.Column
              title={t("policy.max-office")}
              key="maxOffice"
              dataIndex="maxOffice"
            />
            <Table.Column
              title={t("policy.max-remote")}
              key="maxRemote"
              dataIndex="maxRemote"
            />

            <Table.Column
              title={t("policy.fixed-days")}
              render={record => <FixedDayTags policy={record} />}
            />

            <Table.Column
              key="action"
              render={record => (
                <Space direction="horizontal">
                  <a
                    key="edit"
                    onClick={() => onStartEditing(record)}
                    title={t("main.edit")}
                  >
                    <EditOutlined />
                  </a>

                  <Popconfirm
                    title={t("policy.delete-confirm")}
                    onConfirm={() => onDelete(record)}
                  >
                    <a key="delete" title={t("main.delete")}>
                      <DeleteOutlined />
                    </a>
                  </Popconfirm>
                </Space>
              )}
            />
          </Table>
        </Col>
      </Row>

      <Modal
        title={t("policy.edit-title")}
        footer={null}
        visible={!!editingPolicy}
        onCancel={() => onCancelEditing()}
        width="60%"
      >
        {editingPolicy && (
          <Form
            onFinish={val => onSave(val)}
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
          >
            <Form.Item
              name="appliesTo"
              label={t("policy.applies-to")}
              rules={[{ required: true, message: t("form.required-field") }]}
            >
              <PeopleSearch
                excludedCollaboratorIds={excludedCollabIds}
                excludedCommunityIds={excludedCommunityIds}
              />
            </Form.Item>

            <Form.Item
              name="maxOffice"
              label={t("policy.max-office")}
              rules={[{ required: true, message: t("form.required-field") }]}
              tooltip={t("policy.max-office-help")}
            >
              <InputNumber min={0} max={5} step={1} />
            </Form.Item>

            <Form.Item
              name="maxRemote"
              label={t("policy.max-remote")}
              rules={[{ required: true, message: t("form.required-field") }]}
              tooltip={t("policy.max-remote-help")}
            >
              <InputNumber min={0} max={5} step={1} />
            </Form.Item>

            <Form.Item
              name="fixedDays"
              label={t("policy.fixed-days")}
              tooltip={t("policy.fixed-days-help")}
            >
              <Select mode="multiple" placeholder={t("policy.fixed-days-placeholder")}>
                <Select.Option key="1" value="1">
                  {t("calendar.week-day-1")}
                </Select.Option>
                <Select.Option key="2" value="2">
                  {t("calendar.week-day-2")}
                </Select.Option>
                <Select.Option key="3" value="3">
                  {t("calendar.week-day-3")}
                </Select.Option>
                <Select.Option key="4" value="4">
                  {t("calendar.week-day-4")}
                </Select.Option>
                <Select.Option key="5" value="5">
                  {t("calendar.week-day-5")}
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button key="save" type="primary" htmlType="submit">
                {t("main.save")}
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </div>
  );
};
