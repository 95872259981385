import React from "react";
import { stopEvent } from "./FloorEditor";
import { EditorDesk } from "./hook/tools";

export default (props: {
  desk: EditorDesk;
  zoom: number;
  onMouseDown: (e: React.MouseEvent) => void;
  onMouseUp: (e: React.MouseEvent) => void;
  onMouseDownResize: (e: React.MouseEvent) => void;
}) => {
  return (
    <div
      className="floor-selection"
      style={{
        top: (props.desk.cy - props.desk.h / 2) * props.zoom,
        left: (props.desk.cx - props.desk.w / 2) * props.zoom,
        width: props.desk.w * props.zoom,
        height: props.desk.h * props.zoom,
        transform: `rotate(${props.desk.rot}deg)`,
      }}
      onMouseDown={props.onMouseDown}
      onMouseUp={props.onMouseUp}
      onClick={stopEvent}
    >
      <div className="floor-handler-wrap">
        <div
          className="floor-handler-topright"
          onMouseDown={props.onMouseDownResize}
        ></div>
      </div>
    </div>
  );
};
