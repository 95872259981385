import { createAsyncThunk } from "@reduxjs/toolkit";
import { Optional } from "../../../types";
import { showError, showSuccess } from "../../../domain/notification/NotificationService";
import i18n from "../../../i18n";
import { refreshCollaborators } from "../collaborator/actions";
import { refreshPolicies } from "../policy/actions";
import { Community } from "../../../domain/community/Community";
import CommunityRestRepo from "../../../domain/community/CommunityRestRepo";

const communityRepo = new CommunityRestRepo();

export const refreshCommunities = createAsyncThunk<Community[]>(
  "api/refreshCommunities",
  async () => {
    return communityRepo.get("/");
  }
);

export const saveCommunity = createAsyncThunk<
  Community,
  Optional<Community, "id" | "wid">
>("api/saveCommunity", async (payload, thunkAPI) => {
  let community;
  try {
    if (payload.id) {
      community = await communityRepo.put(payload.id, payload);
    } else {
      community = await communityRepo.post("/", payload);
    }
    showSuccess(i18n.t("community.save-successfully"));
  } catch (e) {
    console.error(e);
    showError(i18n.t("community.save-error"));
    thunkAPI.rejectWithValue(e.message);
    return;
  }

  thunkAPI.dispatch(refreshCommunities());

  return community;
});

export const deleteCommunity = createAsyncThunk<void, number>(
  "api/deleteCommunity",
  async (payload, thunkAPI) => {
    return communityRepo
      .delete(payload)
      .then(
        () => showSuccess(i18n.t("community.delete-success")),
        e => {
          console.error(e);
          showError(i18n.t("community.delete-error"));
          thunkAPI.rejectWithValue(e.message);
        }
      )
      .then(() => {
        thunkAPI.dispatch(refreshCommunities());

        // Depend on communities
        thunkAPI.dispatch(refreshCollaborators());
        thunkAPI.dispatch(refreshPolicies());
      });
  }
);
