import React from "react";
import { useTranslation } from "react-i18next";

import { Select } from "antd";

import { AttendanceCommunity } from "../../../../domain/history/History";

type Props = {
  onChangeCommunity: (value: string) => void;
  communities: AttendanceCommunity[];
};

const renderCommunityOptions = (
  communities: AttendanceCommunity[],
  depth = 1
): JSX.Element[] => {
  const res: JSX.Element[] = [];

  for (const community of communities) {
    res.push(
      <Select.Option
        key={community.id}
        value={community.id}
        style={{ paddingLeft: 10 * depth }}
      >
        {community.name}
      </Select.Option>
    );

    if (community.children && community.children.length > 0) {
      res.push(...renderCommunityOptions(community.children, depth + 1));
    }
  }

  return res;
};

export default ({ onChangeCommunity, communities }: Props) => {
  const { t } = useTranslation();

  return (
    <Select defaultValue="" onChange={onChangeCommunity} style={{ width: "100%" }}>
      <Select.Option key="0" value="">
        {t("dashboard.all")}
      </Select.Option>
      {renderCommunityOptions(communities)}
    </Select>
  );
};
