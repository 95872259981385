import React, { useEffect, useRef } from "react";

// Antd
import { Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

// types
import { ChartData } from "../../../domain/history/History";

import Chart from "chart.js/auto";

export enum ChartType {
  Bar = "bar",
  Line = "line",
}

export enum DateRange {
  LAST_WEEK = "lastWeek",
  TWO_WEEKS = "twoWeeks",
  MONTH = "month",
}

type ChartAxisProps = {
  max?: number;
  min?: number;
};

type Props = {
  label?: string;
  labelTooltip?: string;
  type?: ChartType;
  showPercentage?: boolean;
  stacked?: boolean;
  legend?: boolean;
  data: Array<ChartData>;
  dataType?: string | null;
  dateRange: Array<string>;
  fill?: boolean;
};

const stdChart = {
  borderWidth: 3,
  borderOpacity: 1,
  backgroundOpacity: 1,
  hoverBackgroundOpacity: 0.5,
  tension: 0.15,
};

export default ({
  data,
  dataType,
  dateRange,
  label,
  labelTooltip,
  fill = true,
  stacked = false,
  legend = false,
  showPercentage = false,
  type = ChartType.Bar,
}: Props) => {
  // refs
  const canvaRef = useRef(null);

  const make_rgba_color = (color: any, opacity: any) => {
    if (color)
      return (
        "rgba(" + color.red + "," + color.green + "," + color.blue + "," + opacity + ")"
      );

    return "rgba(red: 000, green: 000, blue: 255)";
  };

  const organize_data = (xAxis: any, dataTable: any) => {
    const sets = [];
    for (let i = 0; i < dataTable.length; i++) {
      const d = dataTable[i];

      sets.push({
        label: d.label,
        borderColor: make_rgba_color(d.color, stdChart.borderOpacity),
        backgroundColor: make_rgba_color(d.color, stdChart.backgroundOpacity),
        borderWidth: stdChart.borderWidth,
        hoverBackgroundColor: make_rgba_color(d.color, stdChart.hoverBackgroundOpacity),
        hoverBorderColor: make_rgba_color(d.color, stdChart.borderOpacity),
        data: d.data,
        fill,
      });
    }

    const data = {
      labels: xAxis,
      datasets: sets,
    };

    return data;
  };

  const organize_options = (percentage = true, legendDisplay = true) => {
    const yAxis: ChartAxisProps = {};

    const options = {
      plugins: {
        legend: {
          display: legendDisplay,
          position: "right",
          align: "center",
        },
        tooltip: {
          callbacks: {
            label: function (context: any) {
              const label = context.dataset.label || "";
              const value = context.parsed.y;
              if (percentage) {
                return `${label}: ${value}%`;
              }

              return `${label}: ${value}`;
            },
          },
        },
      },

      scales: {
        y: {
          beginAtZero: true,
          suggestedMin: 0,
          stacked,
          display: true,
          ...yAxis,
        },
        x: {
          stacked,
          display: true,
        },
      },
    };

    return options;
  };

  useEffect(() => {
    let chart: Chart;
    let dataToShow;

    if (canvaRef?.current) {
      dataToShow =
        dataType && dataType.length > 0
          ? data.filter(dataset => dataset.label === dataType)
          : data;

      chart = new Chart(canvaRef.current, {
        type,
        options: organize_options(showPercentage, legend),
        data: organize_data(dateRange, dataToShow),
      });
    }

    return () => (chart ? chart.destroy() : null);
  }, [data, dataType]);

  return (
    <div>
      <div className={"mb-xs flex justify-content-center flex-align-items-center"}>
        <h2>{label}</h2>
        {labelTooltip && (
          <Tooltip className={"ml-xs"} title={labelTooltip}>
            <span className={"tooltip-icon"}>
              <QuestionCircleOutlined />
            </span>
          </Tooltip>
        )}
      </div>
      <canvas id="myChart" ref={canvaRef}></canvas>
    </div>
  );
};
