import { notification } from "antd";

export const showError = (message?: string, description?: string, duration?: number) => {
  if (!message) return;

  notification.error({
    description: description,
    duration: duration === undefined ? 5 : duration,
    message: message,
    placement: "bottomRight",
  });
};

export const showWarning = (message: string, description?: string) => {
  notification.warning({
    description: description,
    duration: null,
    message: message,
    placement: "bottomRight",
  });
};

export const showSuccess = (message: string, description?: string, duration?: number) => {
  notification.success({
    description: description,
    duration: duration === undefined ? 5 : duration,
    message: message,
    placement: "bottomRight",
  });
};
