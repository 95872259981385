import React, { useCallback } from "react";
import { Button, Tooltip } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import classNames from "classnames";

import "./TopBar.css";
import { store } from "../../redux/store";
import Week from "../../../domain/week/Week";
import { BookingBar } from "../my-booking-bar/BookingBar";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { changeUi, setSelectedDay, setWeek } from "../../redux/actions";
import { useRoutePage, useSelectedDay, useWeekDays } from "../../hook/hooks";
import AutocompleteSearch from "../autocomplete-search/AutocompleteSearch";
import { Pages } from "../../page/LeftMainMenu";

const dayCellStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  height: "46px",
  justifyContent: "center",
} as any;

export const TopBar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const page = useRoutePage();
  const selectedDay = useSelectedDay();
  const weekDays = useWeekDays();

  const mouseOverBar = React.useRef(false);
  const setMouseOverBar = (value: boolean) => (mouseOverBar.current = value);

  const changeSelectedDay = useCallback(
    (day: string) => () => {
      if (page === Pages.calendars) return;
      dispatch(setSelectedDay(day));
    },
    [dispatch, page]
  );

  const DayHeader = useCallback(
    (props: { day: string }) => {
      return (
        <div
          className={classNames({
            "topbar-day": page === Pages.floorplans,
            "topbar-day-viewed": page === Pages.floorplans && selectedDay === props.day,
          })}
          onClick={changeSelectedDay(props.day)}
          style={{
            color: Week.isBeforeToday(props.day) ? "#999" : "black",
            cursor: page === Pages.floorplans && "pointer",
            ...dayCellStyle,
          }}
        >
          {Week.isToday(props.day) && <div>{t("top-bar.today")}</div>}

          <div>
            {t(`calendar.week-day-${Week.dayIndex(props.day)}`) +
              " " +
              Week.formatDay(props.day)}
          </div>
        </div>
      );
    },
    [changeSelectedDay, selectedDay, t, page]
  );

  const onThisWeekClick = useCallback(() => {
    dispatch(setWeek(new Week({ selected: selectedDay }).todayWeek().firstDay()));
  }, [dispatch, selectedDay]);

  const onBackwardClick = useCallback(() => {
    dispatch(setWeek(new Week({ selected: selectedDay }).previousWeek().firstDay()));
  }, [dispatch, selectedDay]);

  const onForwardClick = useCallback(() => {
    dispatch(setWeek(new Week({ selected: selectedDay }).nextWeek().firstDay()));
  }, [dispatch, selectedDay]);

  const onDaysMouseEnter = () => {
    if (!store.getState().user?.collaboratorId) {
      return;
    }
    setMouseOverBar(true);
    dispatch(changeUi({ isBookingBarShown: true }));
  };

  const onDaysMouseLeave = () => {
    if (!store.getState().user?.collaboratorId) {
      return;
    }
    setMouseOverBar(false);
    setTimeout(() => {
      if (!mouseOverBar.current) {
        dispatch(changeUi({ isBookingBarShown: false }));
      }
    }, 1000);
  };

  return (
    <div id="topbar">
      <AutocompleteSearch />

      {(page === Pages.calendars || page === Pages.floorplans) && (
        <>
          <div id="date-controls">
            <Button onClick={onThisWeekClick} style={{ marginRight: "10px" }}>
              {t("top-bar.this-week")}
            </Button>
            <Tooltip mouseEnterDelay={0.5} title="Previous Week">
              <Button
                icon={
                  <div style={{ fontSize: "14px" }}>
                    <LeftOutlined />
                  </div>
                }
                onClick={onBackwardClick}
                shape="circle"
                style={{ marginRight: "2px" }}
                type="text"
              ></Button>
            </Tooltip>

            <Tooltip mouseEnterDelay={0.5} title="Next Week">
              <Button
                icon={
                  <div style={{ fontSize: "14px" }}>
                    <RightOutlined />
                  </div>
                }
                onClick={onForwardClick}
                shape="circle"
                style={{ marginRight: "12px" }}
                type="text"
              ></Button>
            </Tooltip>
          </div>

          <div
            id="days-controls"
            onMouseEnter={onDaysMouseEnter}
            onMouseLeave={onDaysMouseLeave}
          >
            {page === Pages.floorplans && <BookingBar />}
            {weekDays.map(day => (
              <DayHeader day={day} key={day} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
