import { AuthUser } from "../../domain/auth/AuthService";
import { Workspace } from "../../domain/workspace/Workspace";
import { Booking, Shifts } from "../../domain/booking/Booking";
import { Building } from "../../domain/building/Building";
import { Collaborator } from "../../domain/collaborator/Collaborator";
import Week from "../../domain/week/Week";
import { FloorPlan } from "../../domain/floorPlan/FloorPlan";
import { Policy } from "../../domain/policy/Policy";
import { Community } from "../../domain/community/Community";

export type TreeKey = { collaboratorId?: number; communityId?: number };

export type InitStatus = "initial" | "auth" | "data";

export type RootState = {
  initStatus: InitStatus;
  user?: AuthUser;
  workspace?: Workspace;

  bookings: Booking[];

  /**
   * @deprecated Prefer using raw booking + facility domain entities
   */
  shifts: Shifts;

  buildings: Building[];
  floorPlans: FloorPlan[];

  policies: Policy[];

  collaborators: Collaborator[];

  highlightedCollaborators: number[];

  communities: Community[];

  peopleTree: { expandedKeys: TreeKey[]; selectedKeys: TreeKey[] };

  viewedFloor?: number;
  weekDays: string[];
  selectedDay: string;

  pendingActions: number;
  searchTerm: string;

  ui: {
    isBookingBarShown: boolean;
    modalShown: null | string;
  };
};

const week = new Week();

export const initialState: RootState = {
  initStatus: "initial",

  bookings: [],
  buildings: [],
  floorPlans: [],
  policies: [],
  collaborators: [],
  highlightedCollaborators: [],
  pendingActions: 0,
  peopleTree: {
    expandedKeys: [],
    selectedKeys: [],
  },
  searchTerm: "",
  shifts: {},
  communities: [],
  ui: {
    isBookingBarShown: false,
    modalShown: null,
  },
  viewedFloor: undefined,
  selectedDay: week.selected,
  weekDays: week.days,
};
