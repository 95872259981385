import RestRepository from "../../infra/RestRepository";

export default class IdentityProviderRestRepo extends RestRepository {
  constructor() {
    super("/api/identity-providers");
  }

  getForWorkspace(workspace?: string) {
    let path = "/";
    if (workspace) path += "?workspace=" + workspace;

    return super.get(path);
  }
}
