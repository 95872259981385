type Rgb = {
  red: string;
  green: string;
  blue: string;
};

export type ChartData = {
  label: string;
  borderColor?: Rgb;
  color: Rgb;
  data: Array<number>;
  fill?: boolean;
  tension?: number;
};

export type DataHistoryFilter = {
  startDate?: Date;
  endDate?: Date;
};

export type DateRangeFilter = {
  startDate?: string;
  endDate?: string;
};

export type OccupancyData = {
  data: Array<number>;
  maxYAxis: number;
};

export type OccupancyChart = {
  occupancy: OccupancyData;
  average: Array<number>;
  buildings: OccupancyBuilding[];
};

export type OccupancyBuilding = {
  id: number;
  name: string;
  floors: OccupancyFloor[];
};

export type OccupancyFloor = {
  id: number;
  name: string;
};

export type AttendanceChart = {
  attendance: Array<ChartData>;
  average: Array<ChartData>;
  communities: AttendanceCommunity[];
};

export type AttendanceCommunity = {
  id: number;
  name: string;
  children?: AttendanceCommunity[];
};

export enum ValuesType {
  PERCENTAGE = "percentage",
  VALUES = "values",
}
