import { AuthUser } from "../auth/AuthService";
import * as Sentry from "@sentry/react";
import { getEnv } from "../../infra/Environment";
import { Integrations } from "@sentry/tracing";
import buildVars from "../../buildVars.json";
import initScreeb from "./initScreeb";

export interface ZendeskWidget {
  (
    type: "webWidget",
    command: "identify",
    payload: { name: string; email: string }
  ): void;
}

const w: Window & { zE?: ZendeskWidget } & {
  $screeb?: (command: string, id: string, params?: unknown) => void;
} = window;

// Sentry
Sentry.init({
  beforeSend(event) {
    // We are filtering this error, which is harmless and adds noise.
    if (event.exception?.values?.[0]?.value?.includes("ResizeObserver")) {
      return null;
    }
    return event;
  },
  dsn: "https://43a1366441254ff38cb2cf81cc0ebc95@o484677.ingest.sentry.io/5538240",
  environment: getEnv(),
  integrations: [new Integrations.BrowserTracing()],
  release: buildVars.appVersion,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.5,
});

export class Tracker {
  static init() {
    if (getEnv() === "prod") {
      try {
        // Init Zendesk (prod only)
        const f = document.getElementsByTagName("script")[0];
        const j = document.createElement("script");

        j.id = "ze-snippet";
        j.async = true;
        j.defer = true;
        j.src =
          "//static.zdassets.com/ekr/snippet.js?key=88594c36-727d-490e-afa6-6a4f6dd76e2f";
        if (f && f.parentNode) f.parentNode.insertBefore(j, f);

        // Screeb
        initScreeb();

        console.log("Tracker initialized");
      } catch (e) {
        console.error("Can't init tracker", e);
      }
    }
  }

  static identify(user: AuthUser) {
    if (getEnv() === "prod") {
      try {
        // Zendesk
        if (!w.zE) {
          console.error("Error loading Zendesk widget");
        } else {
          w.zE("webWidget", "identify", {
            name: user.name,
            email: user.email,
          });
        }

        // Sentry
        Sentry.setUser({ email: user.email });
        Sentry.setTag("wid", `${user.wid}`);

        // Screeb
        if (w.$screeb) {
          w.$screeb("identity", `${user.aid}`, {
            email: user.email,
            wid: user.wid,
            last_seen_at: new Date(),
            authenticated: true,
            lang: user.lang,
          });
        }
      } catch (e) {
        console.error("Error on tracking identification", e);
      }
    }
  }
}
