import { createReducer } from "@reduxjs/toolkit";
import {
  changeLang,
  changeUi,
  changeViewedFloor,
  incrPendingActions,
  loadAuthUser,
  loadData,
  locateCollaborator,
  refreshBookings,
  refreshBuildings,
  refreshFloorPlans,
  saveWorkspace,
  setHighlightedCollaborators,
  setPeopleTreeExpandedKeys,
  setPeopleTreeSelectedKeys,
  setSearchTerm,
  setSelectedDay,
  setWeek,
  updateUserPicture,
  updateUserInfos,
} from "./actions";
import { showError } from "../../domain/notification/NotificationService";
import { bookingToShifts } from "../../domain/booking/Booking";
import {
  getDefaultViewedFloorPlan,
  removeDefaultViewedFloorPlan,
} from "../../domain/floorPlan/floorPlanService";
import Week from "../../domain/week/Week";
import i18n from "i18next";
import { refreshCollaborators } from "./collaborator/actions";
import { initialState, RootState } from "./state";
import { refreshPolicies } from "./policy/actions";
import { refreshCommunities } from "./community/actions";

export const rootReducer = createReducer(initialState, builder => {
  builder.addCase(loadAuthUser.pending, (state: RootState) => {
    state.initStatus = "initial";
  });

  builder.addCase(loadAuthUser.fulfilled, (state: RootState, action) => {
    state.initStatus = "auth";
    state.user = action.payload;
  });

  builder.addCase(updateUserPicture, (state: RootState, action) => {
    const { collaboratorId, picturePath } = action.payload;

    if (state.user) {
      const collaboratorToFind = state.collaborators.find(c => c.id === collaboratorId);

      if (!collaboratorToFind) return state;

      collaboratorToFind.picturePath = picturePath;
      state.user.picturePath = picturePath;
    }
  });

  builder.addCase(updateUserInfos.fulfilled, (state, action) => {
    const { name, lang } = action.payload;

    if (state.user) {
      state.user.name = name ? name : state.user.name;
      state.user.lang = lang ? lang : state.user.lang;
    }
  });

  builder.addCase(updateUserInfos.rejected, () => {
    showError(i18n.t("error-api.unexpected-error"));
  });

  builder.addCase(loadData.fulfilled, (state: RootState, action) => {
    const floorFromLocalStorage = getDefaultViewedFloorPlan();

    const floorIds = action.payload.buildings?.[0]?.floors?.map(floor => floor.id);
    const firstFloor = action.payload.buildings?.[0]?.floors?.[0]?.id;
    if (floorFromLocalStorage) {
      // We check whether that's a valid floor
      if (!floorIds?.includes(floorFromLocalStorage)) {
        state.viewedFloor = firstFloor;
        removeDefaultViewedFloorPlan();
      } else {
        state.viewedFloor = floorFromLocalStorage;
      }
    } else {
      state.viewedFloor = firstFloor;
    }

    state.initStatus = "data";
    state.workspace = action.payload.workspace;
    state.buildings = action.payload.buildings;
    state.floorPlans = action.payload.floorPlans;
    state.policies = action.payload.policies;
    state.collaborators = action.payload.collaborators;
    state.communities = action.payload.communities;
    state.shifts = action.payload.shifts;
    state.bookings = action.payload.bookings;
    state.peopleTree = { selectedKeys: [], expandedKeys: [] };
  });

  builder.addCase(incrPendingActions, (state, action) => {
    state.pendingActions = state.pendingActions + action.payload.incr;
  });

  builder.addCase(setSearchTerm, (state, action) => {
    state.searchTerm = action.payload.value;
  });

  builder.addCase(setPeopleTreeSelectedKeys, (state, action) => {
    state.peopleTree.selectedKeys = action.payload;
  });

  builder.addCase(setPeopleTreeExpandedKeys, (state, action) => {
    state.peopleTree.expandedKeys = action.payload;
  });

  builder.addCase(setWeek, (state, action) => {
    const week = new Week({ selected: action.payload });

    state.weekDays = week.days;
    state.selectedDay = week.days[0];
  });

  builder.addCase(setSelectedDay, (state, action) => {
    state.selectedDay = action.payload;
  });

  builder.addCase(setHighlightedCollaborators, (state, action) => {
    if (!action.payload) {
      state.highlightedCollaborators = [];
    }

    if (action.payload?.collaboratorIds) {
      state.highlightedCollaborators = action.payload.collaboratorIds;
    }

    if (action.payload?.communityIds) {
      const eids = state.collaborators
        .filter(
          e => e.communityId && action.payload?.communityIds?.includes(e.communityId)
        )
        .map(e => e.id);
      state.highlightedCollaborators = eids;
    }
  });

  builder.addCase(refreshCollaborators.fulfilled, (state, action) => {
    state.collaborators = action.payload || [];
  });

  builder.addCase(refreshCommunities.fulfilled, (state, action) => {
    state.communities = action.payload || [];
  });

  builder.addCase(changeUi, (state, action) => {
    const { isBookingBarShown, modalShown } = action.payload;

    if (isBookingBarShown !== undefined) {
      state.ui.isBookingBarShown = isBookingBarShown;
    }
    if (modalShown !== undefined) {
      state.ui.modalShown = modalShown;
    }
  });

  builder.addCase(changeViewedFloor, (state, action) => {
    const { floorId } = action.payload;
    state.viewedFloor = floorId;
  });

  builder.addCase(changeLang.fulfilled, (state, action) => {
    if (state.user) {
      state.user = { ...state.user, lang: action.payload };
    }
  });

  builder.addCase(changeLang.rejected, () => {
    showError(i18n.t("error-api.unexpected-error"));
  });

  /*************************************
   * FloorPlan relative
   *************************************/

  builder.addCase(locateCollaborator, (state, action) => {
    state.selectedDay = action.payload.date;
    state.viewedFloor = action.payload.floorId;
    state.highlightedCollaborators = [action.payload.collaboratorId];
  });

  builder.addCase(refreshBuildings.fulfilled, (state, action) => {
    state.buildings = action.payload || [];
  });

  builder.addCase(refreshFloorPlans.fulfilled, (state, action) => {
    state.floorPlans = action.payload || [];
  });

  /*************************************
   * Booking related reducers
   *************************************/

  builder.addCase(refreshBookings.fulfilled, (state, action) => {
    state.shifts = bookingToShifts(action.payload);
    state.bookings = action.payload;
  });

  builder.addCase(saveWorkspace.fulfilled, (state, action) => {
    state.workspace = action.payload;
  });

  /*************************************
   * Policy related reducers
   *************************************/

  builder.addCase(refreshPolicies.fulfilled, (state, action) => {
    state.policies = action.payload || [];
  });
});

export default rootReducer;
