import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Fetch from "i18next-fetch-backend";

i18n
  .use(Fetch)
  .use(initReactI18next)
  .init(
    {
      fallbackLng: "en",
      supportedLngs: ["en", "fr"],
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    },
    error => {
      if (error) console.error("Can't initialize i18n", error);
    }
  );

export default i18n;
