import moment from "moment";

export type DateType = string;

export default class DateTime {
  static getDaysDiffFromToday(date: DateType) {
    return moment().diff(moment(date), "days");
  }

  static compare(firstDate: DateType, secondDate: DateType) {
    return moment(secondDate).diff(firstDate, "days");
  }
}
