import React from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";

import SidePanel from "../component/side-panel/SidePanel";
import { MemoedUserAccount as UserAccount } from "./setting/user-account/UserAccount";

import "./authed-app.less";
import ExportSetting from "./setting/ExportSetting";
import FloorDashboard from "./floorplan/FloorDashboard";
import { CalendarDashboard } from "./calendar/CalendarDashboard";
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import {
  useAuthUser,
  useRoutePage,
  useRouteWorkspace,
  useWorkspace,
} from "../hook/hooks";
import LeftMainMenu, { Pages } from "./LeftMainMenu";
import { TopBar } from "../component/top-bar/TopBar";
import classNames from "classnames";
import CollaboratorSetting from "./setting/collaborators/CollaboratorSetting";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import CommunitySetting from "./setting/community/CommunitySetting";
import IntegrationSetting from "./setting/IntegrationSetting";
import { RootState } from "../redux/state";
import FloorSetting from "./setting/building/FloorSetting";
import PolicyPage from "./policy/PolicyPage";
import DashboardPage from "./dashboard/DashboardPage";
import WorkspaceSetting from "./setting/WorkspaceSetting";

const Spinner = () => {
  const pendingActions = useSelector<RootState, number>(s => s.pendingActions);

  return (
    <div id="spinner" style={{ display: pendingActions > 0 ? "block" : "none" }}>
      <LoadingOutlined />
    </div>
  );
};

const SettingTabs = () => {
  const { t } = useTranslation();
  const workspace = useWorkspace();
  const history = useHistory();
  const { settingTab = "workspace" } = useParams<{ settingTab?: string }>();

  return (
    <Tabs
      activeKey={settingTab}
      onChange={tab => history.push(`/${workspace.name}/settings/${tab}`)}
    >
      <Tabs.TabPane tab={t("main.workspace")} key="workspace">
        <WorkspaceSetting />
      </Tabs.TabPane>

      <Tabs.TabPane tab={t("main.collaborators")} key="collaborators">
        <CollaboratorSetting />
      </Tabs.TabPane>

      <Tabs.TabPane tab={t("main.communities")} key="communities">
        <CommunitySetting />
      </Tabs.TabPane>

      <Tabs.TabPane tab={t("main.floors")} key="floors">
        <FloorSetting />
      </Tabs.TabPane>

      <Tabs.TabPane tab={t("main.export")} key="exports">
        <ExportSetting />
      </Tabs.TabPane>

      <Tabs.TabPane tab={t("main.integrations")} key="integrations">
        <IntegrationSetting />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default () => {
  const user = useAuthUser();
  const workspace = useWorkspace();

  const history = useHistory();
  const routePage = useRoutePage();
  const routeWorkspace = useRouteWorkspace();

  // Default route
  if (!routePage || !routeWorkspace || routeWorkspace !== workspace.name) {
    setTimeout(() => history.push(`/${workspace.name}/${Pages.calendars}`));
  }

  const page = routePage || Pages.calendars;

  return (
    <>
      <Spinner />
      <LeftMainMenu />

      {[Pages.floorplans, Pages.calendars].includes(page) && <TopBar />}

      <div
        className={classNames([
          "content",
          {
            "with-topbar": [Pages.floorplans, Pages.calendars].includes(page),
          },
        ])}
      >
        {page === Pages.calendars && (
          <div>
            <SidePanel />
          </div>
        )}

        <Switch>
          <Route path="/:workspace/calendars">
            <CalendarDashboard />
          </Route>

          <Route path="/:workspace/floorplans">
            <FloorDashboard />
          </Route>

          <Route path="/:workspace/policies">
            <PolicyPage />
          </Route>

          <Route path="/:workspace/settings/:settingTab?">
            <SettingTabs />
          </Route>

          <Route path="/:workspace/dashboard">
            <DashboardPage />
          </Route>

          <Route path="/:workspace/user-account">
            <UserAccount user={user} />
          </Route>
        </Switch>
      </div>
    </>
  );
};
