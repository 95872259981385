import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { all, delay, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import rootReducer from "./reducers";
import {
  addBooking,
  incrPendingActions,
  refreshBookings,
  refreshBuildings,
  removeBooking,
  saveDesks,
  setWeek,
  switchBookingDesk,
} from "./actions";
import { initialState } from "./state";

/**
 * Create Store
 */

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(sagaMiddleware),
  preloadedState: initialState,
  reducer: rootReducer,
});

/**
 * Configure Saga
 */

function* watchForLoading() {
  yield takeEvery("*", function* (action) {
    const as = action.type;

    if (as.endsWith("/pending")) {
      yield put(incrPendingActions({ incr: 1 }));
      return;
    }

    if (as.endsWith("/fulfilled") || as.endsWith("/rejected")) {
      yield put(incrPendingActions({ incr: -1 }));
      return;
    }
  });
}

function* fetchBuildings() {
  yield put(refreshBuildings() as any);
}

function* fetchBookings() {
  const weekDays = yield select(s => s.weekDays);
  yield put(refreshBookings({ weekFirstDay: weekDays[0] }) as any);
}

function* onSaveDesks() {
  yield takeLatest(saveDesks.fulfilled, function* () {
    yield fetchBuildings();
  });
}

function* onWeekSet() {
  yield takeLatest(setWeek, fetchBookings);
}

function* onAddBooking() {
  yield takeLatest(addBooking.fulfilled, fetchBookings);
}

function* onRemoveBooking() {
  yield takeLatest(removeBooking.fulfilled, fetchBookings);
}

function* onSwitchDesk() {
  yield takeLatest(switchBookingDesk.fulfilled, fetchBookings);
}

function* triggerRecurrentRefresh() {
  while (true) {
    yield delay(60000);
    const user = yield select(state => state.user);

    if (user && user.email) {
      yield fetchBookings();
    }
  }
}

function* rootSaga() {
  yield all([
    onSaveDesks(),
    onWeekSet(),
    onAddBooking(),
    onRemoveBooking(),
    onSwitchDesk(),
    triggerRecurrentRefresh(),
    watchForLoading(),
  ]);
}

sagaMiddleware.run(rootSaga);
