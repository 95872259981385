import React, { useCallback, useMemo } from "react";
import { useOrganization } from "../../hook/hooks";
import { Select } from "antd";
import CommunityTag from "../people-tag/PeopleTag";
import { Collaborator } from "../../../domain/collaborator/Collaborator";
import { Consumer } from "../../../types";
import { Community } from "../../../domain/community/Community";

type OptionEntry = {
  key: string;
  community?: Community;
  collaborator?: Collaborator;
  title: string;
  disabled: boolean;
};

type ValueType = { communityId?: number; collaboratorId?: number };

const toEntryKey = (val?: ValueType) => {
  if (val?.communityId) return "community|" + val.communityId;
  if (val?.collaboratorId) return "collaborator|" + val.collaboratorId;
  return "";
};

const fromEntryKey = (key: string): ValueType | undefined => {
  const tabs = key.split("|");
  if (tabs.length === 2) {
    if (tabs[0] === "community") return { communityId: Number(tabs[1]) };
    if (tabs[0] === "collaborator") return { collaboratorId: Number(tabs[1]) };
  }
};

export default (props: {
  onChange?: Consumer<ValueType | undefined>;
  value?: ValueType;
  excludedCollaboratorIds?: number[];
  excludedCommunityIds?: number[];
}) => {
  const { excludedCollaboratorIds = [], excludedCommunityIds = [] } = props;

  const excludedKeys = [
    ...excludedCommunityIds.map(id => toEntryKey({ communityId: id })),
    ...excludedCollaboratorIds.map(id => toEntryKey({ collaboratorId: id })),
  ];

  const org = useOrganization();
  const allCommunitiesWithCollab = org.getCommunitiesWithCollaborators();
  const allCollabWithoutCommunity = org.getCollaboratorsWithoutCommunity();

  const options = useMemo(() => {
    const res: OptionEntry[] = [];

    for (const community of allCommunitiesWithCollab) {
      res.push({
        key: toEntryKey({ communityId: community.id }),
        community,
        title: community.name,
        disabled: excludedCommunityIds.includes(community.id),
      });

      const collabs = community.collaborators;
      for (const collab of collabs) {
        res.push({
          key: toEntryKey({ collaboratorId: collab.id }),
          community,
          collaborator: collab,
          title: collab.name,
          disabled: excludedCollaboratorIds.includes(collab.id),
        });
      }
    }

    if (allCollabWithoutCommunity.length > 0) {
      res.push({ key: toEntryKey({ communityId: 0 }), title: "", disabled: true });

      for (const collab of allCollabWithoutCommunity) {
        res.push({
          key: toEntryKey({ collaboratorId: collab.id }),
          collaborator: collab,
          title: collab.name,
          disabled: excludedCollaboratorIds.includes(collab.id),
        });
      }
    }

    return res;
  }, [
    allCollabWithoutCommunity,
    allCommunitiesWithCollab,
    excludedCollaboratorIds,
    excludedCommunityIds,
  ]);

  const filterOption = useCallback((val: string, option) => {
    if (!val || val.trim() === "") return true;

    console.log(val + " match option", option);

    const re = new RegExp(".*" + val.trim() + ".*", "ig");

    if (option.title) return re.test(option.title);

    return false;
  }, []);

  const onChange = useCallback(
    (key?: string) => {
      if (!props.onChange) return;

      if (!key) {
        props.onChange(undefined);
      } else {
        props.onChange(fromEntryKey(key));
      }
    },
    [props]
  );

  return (
    <Select<string>
      showSearch
      allowClear
      filterOption={filterOption}
      // onSearch={onSearch}
      onChange={val => onChange(val)}
      style={{ width: 300 }}
      value={toEntryKey(props.value)}
    >
      {options.map(e => (
        <Select.Option
          key={e.key}
          value={e.key}
          disabled={excludedKeys.includes(e.key) || e.key === "community|0"}
          title={e.title}
        >
          {!e.collaborator && e.community && <CommunityTag community={e.community} />}
          {e.collaborator && <span className="ml-md">{e.collaborator.name}</span>}
        </Select.Option>
      ))}
    </Select>
  );
};
