import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Components
import { ModalSetting } from "../../../../component/modal-settings/ModalSetting";
import { LuccaIntegrationForm } from "./LuccaIntegrationForm";

// Ant
import { Button, Card, Col, Modal, Row, Spin } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import "../../integration-setting.less";

// type
import { LuccaInstall } from "../../../../../domain/lucca-install/LuccaInstall";

// Repo
import LuccaInstallRestRepo from "../../../../../domain/lucca-install/LuccaInstallRestRepo";

import {
  showError,
  showSuccess,
} from "../../../../../domain/notification/NotificationService";
import { getUserMessage } from "../../../../../infra/RestRepository";

const luccaInstallRepo = new LuccaInstallRestRepo();

export enum Status {
  Pending = "pending",
  Connected = "connected",
  Disconnected = "disconnected",
}

type LuccaContext = {
  luccaInstallId: null | number;
  status: Status;
};

const defaultValues = {
  apiKey: "",
  baseUrl: "",
  bookingFromAccounts: [],
  bookingToAccount: null,
  syncCollaborators: false,
  syncBookingsFromLucca: false,
  syncBookingToLucca: false,
};

export default (props: { wid: number }) => {
  // State
  const [luccaContext, setLuccaContext] = useState<LuccaContext>({
    luccaInstallId: null,
    status: Status.Pending,
  });

  const [luccaInstall, setLuccaInstall] = useState<Omit<LuccaInstall, "id" | "wid">>({
    ...defaultValues,
  });

  const [openSettings, setOpenSettings] = useState(false);

  const { t } = useTranslation();

  const onClick = () => {
    setOpenSettings(true);
  };

  const onFinish = React.useCallback(
    (values: any) => {
      setLuccaContext({
        ...luccaContext,
        status: Status.Pending,
      });

      const formattedValues = {
        ...values,
      };

      luccaInstallRepo
        .post("/", {
          id: luccaContext.luccaInstallId,
          wid: props.wid,
          ...formattedValues,
        })
        .then(res => {
          setLuccaContext({
            luccaInstallId: res.id,
            status: Status.Connected,
          });

          setLuccaInstall({
            ...luccaInstall,
            ...res,
          });

          showSuccess(t("lucca.connect-success"));
        })
        .catch(e => {
          showError(getUserMessage(e) || t("lucca.connect-failed"));
          setLuccaContext({
            ...luccaContext,
          });
        });
    },
    [luccaContext, luccaInstall, props.wid, t]
  );

  const handleCancelSettings = React.useCallback(() => {
    setOpenSettings(false);
  }, []);

  const onOk = () => {
    setLuccaContext({
      ...luccaContext,
      status: Status.Pending,
    });

    luccaInstallRepo
      .delete(`/${luccaContext.luccaInstallId}`)
      .then(res => {
        showSuccess(t("lucca.disconnect-success"));

        setLuccaContext({
          luccaInstallId: res.id,
          status: Status.Disconnected,
        });

        setLuccaInstall({
          ...luccaInstall,
          apiKey: "",
          baseUrl: "",
          bookingFromAccounts: [],
          bookingToAccount: null,
          syncCollaborators: false,
          syncBookingsFromLucca: false,
          syncBookingToLucca: false,
        });
      })
      .catch(() => {
        showError(t("lucca.disconnect-failed"));
        setLuccaContext({
          ...luccaContext,
          status: Status.Connected,
        });
      });
  };

  const onDisconnect = () => {
    Modal.confirm({
      title: t("lucca.suspend-confirm"),
      icon: <ExclamationCircleOutlined />,
      content: t("lucca.suspend-desc"),
      width: 600,
      onOk,
    });
  };

  useEffect(() => {
    luccaInstallRepo
      .get("/")
      .then(res => {
        if (res.id) {
          setLuccaContext({
            status: Status.Connected,
            luccaInstallId: res.id,
          });

          setLuccaInstall({
            ...res,
          });
        } else {
          setLuccaContext({
            status: Status.Disconnected,
            luccaInstallId: null,
          });
        }
      })
      .catch(() => {
        showError(t("lucca.fetch-lucca-failed"));
      });
  }, [t]);

  return (
    <Card className="integration-card">
      <Row gutter={[0, 15]} align={"middle"}>
        <Col flex="50px">
          <img
            alt="Connect to Lucca"
            height="40"
            src={"/logo-lucca.png"}
            srcSet={"/logo-lucca.png 1x, /logo-lucca.png 2x"}
            style={{ cursor: "pointer" }}
          />
        </Col>
        <Col md={22} lg={2} xl={2}>
          <span className={"integration-title"}>Lucca</span>
        </Col>
        <Col md={24} lg={24} xl={24}>
          <span className={"integration-description"}>
            {t("lucca.integration-description")}
          </span>
          {/* To uncomment when the link will be available
          <span className={"read-document"}>
            <Link to="/" target="_blank" rel="noopener noreferrer">
              <ReadOutlined />
            </Link>
          </span>
           */}
        </Col>
        <Col className={"disconnect-button"} span={24}>
          {luccaContext.status !== Status.Connected && (
            <Button
              disabled={luccaContext.status !== Status.Disconnected}
              onClick={onClick}
              type="ghost"
            >
              {luccaContext.status === Status.Disconnected ? (
                t("lucca.connect")
              ) : (
                <Spin size="small" />
              )}
            </Button>
          )}
          {luccaContext.status === Status.Connected && luccaContext.luccaInstallId && (
            <>
              <Button type="primary" onClick={() => setOpenSettings(true)}>
                {t("lucca.button-settings")}
              </Button>
              <Button
                type="link"
                disabled={luccaContext.status !== Status.Connected}
                onClick={onDisconnect}
              >
                {luccaContext.status === Status.Connected ? (
                  <span className={"disconnect-button-text"}>
                    {t("lucca.disconnect")}
                  </span>
                ) : (
                  <Spin size="small" />
                )}
              </Button>
            </>
          )}
        </Col>
      </Row>

      <ModalSetting
        onCancel={handleCancelSettings}
        title={t("lucca.settings")}
        state={openSettings}
      >
        <LuccaIntegrationForm
          defaultValues={luccaInstall}
          luccaInstallId={luccaContext.luccaInstallId}
          onFinish={onFinish}
          status={luccaContext.status}
        />
      </ModalSetting>
    </Card>
  );
};
