import React from "react";
import ReactDOM from "react-dom";

import App from "./ui/App";
import * as serviceWorker from "./serviceWorker";

import "./index.less";
import buildVars from "./buildVars.json";
import "./i18n";
import { Tracker } from "./domain/tracker/Tracker";

Tracker.init();

(window as any).buildVars = buildVars;

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
