import RestRepository from "../../infra/RestRepository";
import { UserAccount } from "./UserAccount";

export default class UserRestRepo extends RestRepository {
  constructor() {
    super("/api/user");
  }

  async switchWorkspace(workspace: string) {
    return super.put("/switch-workspace", { ws: workspace });
  }

  async update(userAccount: UserAccount): Promise<UserAccount> {
    return super.put("/", userAccount);
  }
}
