import React from "react";

export const ErrorScreen = () => {
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "center",
        width: "100vw",
      }}
    >
      <h1>Something went wrong.</h1>
      <div>
        We have been notified. If the problem persists, contact the Semana support.
      </div>
    </div>
  );
};
