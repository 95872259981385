export type Env = "prod" | "dev" | "local";

export type EnvSettings = {
  firebaseApiKey: string;
  firebaseAuthDomain: string;
  googleClientId: string;
  googleScopes: string;
};

export const getEnv = (): Env => {
  if (window.location.hostname === "app.semana.io") return "prod";
  if (window.location.hostname === "app-dev.semana.io") return "dev";
  return "local";
};

const getEnvSettings = (): EnvSettings => {
  if (getEnv() === "prod") {
    console.log("Production environment");

    return {
      firebaseApiKey: "AIzaSyCJ2h-RIm50HEPVTrgEj8wDU08hl6uRRRQ",
      firebaseAuthDomain: "auth.semana.io",
      googleClientId:
        "762716130883-0luh782rbtcro00gvfk00v7mm9vkbb4d.apps.googleusercontent.com",
      googleScopes:
        "https://www.googleapis.com/auth/admin.directory.user.readonly https://www.googleapis.com/auth/calendar.events",
    };
  }

  console.log("Dev environment");

  return {
    firebaseApiKey: "AIzaSyCNIslkLtVGpLZXMJdoAvx-B77qTNYJV4g",
    firebaseAuthDomain: "auth-dev.semana.io",
    googleClientId:
      "250617680629-s4cuicqqpfrv7jvbhjf0jcqvvp36gtjv.apps.googleusercontent.com",
    googleScopes:
      "https://www.googleapis.com/auth/admin.directory.user.readonly https://www.googleapis.com/auth/calendar.events",
  };
};

export const env = getEnvSettings();
