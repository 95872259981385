import React from "react";
import ImgCrop from "antd-img-crop";
import { useTranslation } from "react-i18next";
import { Button, Col, Row, Upload } from "antd";

// Hooks
import { useImageURL } from "../../../hook/hooks";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";

import { FileUtils } from "../../../../utils/file";

import "./user-profile-picture.less";

export const GenericAvatar = (props: { path?: string }) => {
  const { path } = props;

  return (
    <div className={"user-profile-picture"}>
      <img className={"user-profile-img"} src={path} />
    </div>
  );
};

const UserProfilePicture = (props: {
  path: string | undefined;
  handleChange: (info: UploadChangeParam<UploadFile<any>>) => void;
}) => {
  const { path, handleChange } = props;

  // hooks
  const { t } = useTranslation();
  const imageUrl = useImageURL(path);

  return (
    <Row>
      <Col span={24}>
        <h3>{t("user-account.photo")}</h3>
        {imageUrl ? <GenericAvatar path={imageUrl} /> : null}
        <ImgCrop
          beforeCrop={f =>
            FileUtils.validateUploadedFile(
              f,
              ["image/jpg","image/jpeg", "image/png"],
              "error.image-format-jpg-png",
              "error.image-size-too-big",
              2
            )
          }
          rotate
        >
          <Upload
            accept={"*"}
            showUploadList={false}
            multiple={false}
            action={"/api/user/upload/picture"}
            onChange={handleChange}
          >
            <Button type="ghost">
              {imageUrl
                ? t("user-account.change-profile-picture")
                : t("user-account.upload-profile-picture")}
            </Button>
          </Upload>
        </ImgCrop>
      </Col>
    </Row>
  );
};

export default React.memo(UserProfilePicture);
