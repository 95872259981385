import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/state";
import { Building, Floor } from "../../../../domain/building/Building";
import {
  Button,
  Checkbox,
  Col,
  Descriptions,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
  Table,
  Upload,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  refreshBuildings,
  refreshFloorPlans,
  saveBuilding,
  saveFloor,
} from "../../../redux/actions";
import { Optional } from "../../../../types";

import "./floor-setting.less";
import { EditOutlined, PictureOutlined, UploadOutlined } from "@ant-design/icons";
import { FloorPlan } from "../../../../domain/floorPlan/FloorPlan";
import { FloorEditor } from "../../../component/floor/editor/FloorEditor";
import { useOfficeSpace, useZones } from "../../../hook/hooks";
import {
  showError,
  showSuccess,
} from "../../../../domain/notification/NotificationService";

type FloorAndPlan = { floor: Floor; floorPlan: FloorPlan };

export default () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const buildings = useSelector<RootState, Building[]>(s => s.buildings);
  const zones = useZones();
  const floorPlans = useSelector<RootState, FloorPlan[]>(s => s.floorPlans);
  const space = useOfficeSpace();

  const [buildingForm] = Form.useForm();
  const [editingBuilding, setEditingBuilding] = useState<Partial<Building> | undefined>(
    undefined
  );

  const [floorForm] = Form.useForm();
  const [editingFloor, setEditingFloor] = useState<Partial<Floor> | undefined>(undefined);

  const [floorAndPlan, setFloorAndPlan] = useState<FloorAndPlan | undefined>(undefined);


  // Update floorAndPlan when floorPlans is updated from store
  useEffect(()=> {
    if (!floorAndPlan) return

      const floorPlan = floorPlans.find(fp => fp.id === floorAndPlan.floor.floorPlanId);
      if (floorPlan) setFloorAndPlan({ floor: floorAndPlan.floor, floorPlan });
      // Do not include floorAndPlan as dependency because it will infinite loop.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  } ,[floorPlans])

  const onClickEditFloorPlan = useCallback(
    (floor: Floor) => {
      const floorPlan = floorPlans.find(fp => fp.id === floor.floorPlanId);
      if (floorPlan) setFloorAndPlan({ floor, floorPlan });
    },
    [floorPlans]
  );

  const onStartEditingBuilding = useCallback(
    (building?: Building) => {
      const b = { ...(building || {}) };
      setEditingBuilding(b);
      buildingForm.setFieldsValue(b);
    },
    [buildingForm]
  );

  const onCancelEditingBuilding = useCallback(() => {
    buildingForm.resetFields();
    setEditingBuilding(undefined);
  }, [buildingForm]);

  const onSaveBuilding = useCallback(
    (data: Optional<Building, "id">) => {
      dispatch(saveBuilding({ ...editingBuilding, ...data }));
      onCancelEditingBuilding();
    },
    [dispatch, editingBuilding, onCancelEditingBuilding]
  );

  const onStartEditingFloor = useCallback(
    (floor?: Partial<Floor>) => {
      const f = { ...(floor || {}) };
      setEditingFloor(f);
      floorForm.setFieldsValue(f);
    },
    [floorForm]
  );

  const onCancelEditingFloor = useCallback(() => {
    floorForm.resetFields();
    setEditingFloor(undefined);
  }, [floorForm]);

  const onSaveFloor = useCallback(
    (data: Optional<Floor, "id">) => {
      dispatch(saveFloor({ ...editingFloor, ...data }));
      onCancelEditingFloor();
    },
    [dispatch, editingFloor, onCancelEditingFloor]
  );

  const onUploadChange = useCallback(
    info => {
      if (info.file.status === "done") {
        showSuccess(t("building.upload-floorplan-success"));
        dispatch(refreshFloorPlans());
        dispatch(refreshBuildings());
      } else if (info.file.status === "error") {
        showError(t("error-api.image-not-supported"));
      }
    },
    [dispatch, t]
  );

  if (floorAndPlan) {
    return (
      <>
        <Row>
          <Col span={8}>
            <h2>
              <Button onClick={() => setFloorAndPlan(undefined)}>&lt;</Button>&nbsp;
              {space.getFloorName(floorAndPlan.floor.id)}
            </h2>
          </Col>
          <Col span={16}>{t("floor-editor.help-message")}</Col>
        </Row>
        <FloorEditor floorplan={floorAndPlan.floorPlan} zones={zones} />
      </>
    );
  }

  return (
    <div>
      <Row>
        <Col span={20} offset={2}>
          <Button onClick={() => onStartEditingBuilding()} type="primary">
            {t("building.add")}
          </Button>
        </Col>
      </Row>

      {buildings.map(building => (
        <Row key={building.id}>
          <Col span={20} offset={2}>
            <div className="building">
              <Row>
                <Col md={6} span={24}>
                  <h3>
                    {building.name}&nbsp;
                    <a
                      onClick={() => onStartEditingBuilding(building)}
                      title={t("main.edit")}
                    >
                      <EditOutlined />
                    </a>
                  </h3>
                </Col>
                <Col md={18} span={24}>
                  <Descriptions size="small" column={1}>
                    <Descriptions.Item label={t("main.address")}>
                      {building.address}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("building.show-in-floor-name")}>
                      {building.showInFloorName ? t("main.yes") : t("main.no")}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>

              <Row wrap={false} className="mt-sm">
                <Col flex="auto">
                  <h4>{t("main.floors")}</h4>
                </Col>
                <Col flex="none">
                  <Button
                    onClick={() => onStartEditingFloor({ buildingId: building.id })}
                  >
                    {t("building.add-floor")}
                  </Button>
                </Col>
              </Row>

              <Table<Floor>
                dataSource={building.floors}
                rowKey="id"
                size="small"
                pagination={false}
                className="mt-sm"
              >
                <Table.Column
                  title={t("main.name")}
                  key="name"
                  dataIndex="name"
                  width="70%"
                  defaultSortOrder="ascend"
                />

                <Table.Column
                  key="desk-count"
                  title={t("building.desk-count")}
                  width="15%"
                  render={(record: Floor) => record.deskCount}
                />

                <Table.Column
                  key="max-booking"
                  title={t("floor.max-booking")}
                  width="15%"
                  render={(record: Floor) => {
                    if (record.maxBooking === null) {
                      return (
                        <span className="text-gray">
                          {space.getNotDisableDeskByFloorId(record.id)?.length || 0}
                        </span>
                      );
                    }

                    if (record.maxBooking !== undefined && record.maxBooking >= 0)
                      return record.maxBooking;
                  }}
                />

                <Table.Column
                  key="action"
                  width="15%"
                  render={(floor: Floor) => (
                    <Space direction="horizontal">
                      <a onClick={() => onStartEditingFloor(floor)}>
                        <EditOutlined />
                      </a>

                      {floor.floorPlanId && (
                        <a
                          onClick={() => onClickEditFloorPlan(floor)}
                          title={t("building.edit-floorplan")}
                        >
                          <PictureOutlined />
                        </a>
                      )}

                      {!floor.floorPlanId && (
                        <Upload
                          onChange={onUploadChange}
                          multiple={false}
                          action={`/api/buildings/${building.id}/floors/${floor.id}/floor-plan`}
                        >
                          <a title={t("building.upload-floorplan")}>
                            <UploadOutlined />
                          </a>
                        </Upload>
                      )}
                    </Space>
                  )}
                />
              </Table>
            </div>
          </Col>
        </Row>
      ))}

      {/* Building edition */}
      <Modal
        title={t("building.edit-title")}
        footer={null}
        visible={!!editingBuilding}
        onCancel={() => onCancelEditingBuilding()}
      >
        {editingBuilding && (
          <Form
            onFinish={val => onSaveBuilding(val)}
            form={buildingForm}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
          >
            <Form.Item
              name="name"
              label={t("main.name")}
              rules={[{ required: true, message: t("form.required-field") }]}
            >
              <Input />
            </Form.Item>

            <Form.Item name="address" label={t("main.address")}>
              <Input />
            </Form.Item>

            <Form.Item
              name="showInFloorName"
              wrapperCol={{ offset: 8, span: 16 }}
              valuePropName="checked"
            >
              <Checkbox>{t("building.show-in-floor-name")}</Checkbox>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button key="save" type="primary" htmlType="submit">
                {t("main.save")}
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>

      {/* Floor edition */}
      <Modal
        title={t("building.floor-edit-title")}
        footer={null}
        visible={!!editingFloor}
        onCancel={() => onCancelEditingFloor()}
      >
        {editingFloor && (
          <Form
            onFinish={val => onSaveFloor(val)}
            form={floorForm}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
          >
            <Form.Item
              name="name"
              label={t("main.name")}
              rules={[{ required: true, message: t("form.required-field") }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="maxBooking"
              label={t("floor.max-booking")}
              tooltip={t("floor.edit-max-booking-tooltip")}
            >
              <InputNumber
                min={0}
                placeholder={`${
                  space.getNotDisableDeskByFloorId(editingFloor?.id)?.length || 0
                }`}
              />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button key="save" type="primary" htmlType="submit">
                {t("main.save")}
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </div>
  );
};
