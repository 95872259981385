import firebase from "firebase";

import AuthRestRepo from "./AuthRestRepo";
import IdentityProviderRestRepo from "./IdentityProviderRestRepo";
import UserRestRepo from "../user-account/UserRestRepo";
import { env } from "../../infra/Environment";

firebase.initializeApp({
  apiKey: env.firebaseApiKey,
  authDomain: env.firebaseAuthDomain,
});

export type AuthResult = {
  displayName?: string;
  email?: string;
  pictureURL?: string;
  idToken: string;
  providerId?: string;

  oauthAccessToken?: string;
  oauthUserId?: string;
};

export type IdentityProvider = {
  id: number;
  wid: number;
  gcipId: string;
};

export type Lang = "en" | "fr";
export type UserRole = "collaborator" | "admin";
export type AuthUser = {
  aid: number;
  email: string;
  name: string;
  lang: Lang;
  collaboratorId: number;
  wid: number;
  owned: boolean;
  role: UserRole;
  workspaces: string[];
  picturePath: string | undefined;
};

const userRepo = new UserRestRepo();
const authRepo = new AuthRestRepo();
const idProviderRepo = new IdentityProviderRestRepo();

export default class AuthService {
  readonly auth: firebase.auth.Auth;

  constructor() {
    this.auth = firebase.auth();
  }

  async getAuthUser(): Promise<AuthUser> {
    return authRepo.getAuthUser();
  }

  async onProviderAuthResult(userCredential: firebase.auth.UserCredential) {
    if (userCredential.user) {
      const idToken = await userCredential.user.getIdToken();

      const res = await authRepo.login({
        idToken,
        displayName: userCredential.user.displayName || undefined,
        email: userCredential.user.email || undefined,
        pictureURL: userCredential.user.photoURL || undefined,
        providerId: userCredential.additionalUserInfo?.providerId || undefined,
        oauthAccessToken: (userCredential.credential as any)?.accessToken,
        oauthUserId: (userCredential.additionalUserInfo?.profile as any)?.id,
      });

      console.log("User authenticated", res);

      return res;
    }

    throw new Error("Error occurred during authentication");
  }

  async signOut() {
    await authRepo.logout();
    await this.auth.signOut();

    window.location.reload();
  }

  getIdProvidersForWorkspace(workspace?: string) {
    return idProviderRepo.getForWorkspace(workspace);
  }

  switchWorkspace(workspace: string) {
    return userRepo.switchWorkspace(workspace);
  }
}
