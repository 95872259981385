import React from "react";

import { PeopleTree } from "../people-tree/PeopleTree";
import PanSection from "../pan-section/PanSection";
import { useTranslation } from "react-i18next";

export default () => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        borderRight: "1px solid #f0f0f0",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        marginRight: "0px",
        overflow: "auto",
        paddingBottom: "20px",
        width: "250px",
      }}
    >
      <PanSection title={t("main.communities")}>
        <PeopleTree key="people-tree" />
      </PanSection>
    </div>
  );
};
