import React, { useCallback, useState, useEffect } from "react";
import { showError } from "../../../../domain/notification/NotificationService";
import { Card, Col, Row } from "antd";
import { useTranslation } from "react-i18next";

import Chart, { ChartType } from "../Chart";

// colors
import { chartColors } from "../../../colors";

// type
import {
  AttendanceCommunity,
  ChartData,
  DateRangeFilter,
  ValuesType,
} from "../../../../domain/history/History";

// Repo
import { DataHistoryRepo } from "../../../../domain/history/HistoryRepo";

import "../dashboard.less";
import AttendanceCommunitySelect from "./AttendanceCommunitySelect";

const weekDayRange = ["Mon", "Tue", "Wed", "Thu", "Fri"];

type Props = {
  dateRange: Array<string>;
  showPercentage: ValuesType;
  startDate: string;
  endDate: string;
};
const dataHistoryRepo = new DataHistoryRepo();

export default ({ dateRange, showPercentage, startDate, endDate }: Props) => {
  // State
  const [data, setData] = useState<Array<ChartData>>([]);
  const [averageData, setAverageData] = useState<Array<ChartData>>([]);

  const [selectedCommunity, setSelectedCommunity] = useState("");
  const [communities, setCommunities] = useState<AttendanceCommunity[]>([]);

  // hooks
  const { t } = useTranslation();

  const onChangeCommunity = useCallback((value: string) => {
    setSelectedCommunity(value);
  }, []);

  const formatChartData = (data: Array<ChartData>) => {
    return data.map(attendance => {
      let color = chartColors.purple;
      let label = "";

      switch (attendance.label) {
        case "remote":
          color = chartColors.purple;
          label = t("dashboard.remote");
          break;
        case "office":
          color = chartColors.blue;
          label = t("dashboard.office");
          break;
        case "off":
          color = chartColors.yellow;
          label = t("dashboard.day-off");
          break;
        case "not-booked":
          color = chartColors.gray;
          label = t("dashboard.not-booked");
          break;
        default:
          color = chartColors.purple;
      }

      return {
        label,
        borderColor: color,
        color,
        data: attendance.data,
      };
    });
  };

  useEffect(() => {
    const dateRangeArg: DateRangeFilter = { startDate, endDate };

    dataHistoryRepo
      .getCommunities(dateRangeArg, showPercentage, selectedCommunity)
      .then(res => {
        const formattedAttendanceData = formatChartData(res.attendance);
        const formattedAverageData = formatChartData(res.average);

        console.log("Communities", res.communities);

        setData([...formattedAttendanceData]);
        setAverageData([...formattedAverageData]);
        setCommunities(res.communities);
      })
      .catch(err => {
        showError(t("dashboard.error-fetching-communities"));
      });
  }, [startDate, endDate, selectedCommunity, showPercentage, t]);

  return (
    <Card>
      <Row>
        <Col span={24}>
          <Row gutter={[25, 25]} justify="center" align="middle">
            <Col span={20}>
              <h2>{t("dashboard.communities")}</h2>
            </Col>
            <Col span={4}>
              <label>{t("dashboard.show-data-from")}</label>
              <AttendanceCommunitySelect {...{ communities, onChangeCommunity }} />
            </Col>
            <Col span={12}>
              <Chart
                legend
                data={data}
                dateRange={dateRange}
                fill={false}
                type={ChartType.Line}
                label={t("dashboard.attendance")}
                labelTooltip={t("dashboard.attendance-tooltip")}
                showPercentage={showPercentage === ValuesType.PERCENTAGE}
              />
            </Col>
            <Col span={12}>
              <Chart
                legend
                data={averageData}
                dateRange={weekDayRange}
                type={ChartType.Bar}
                label={t("dashboard.weekday-average-attendance")}
                labelTooltip={t("dashboard.weekday-average-attendance-tooltip")}
                showPercentage={showPercentage === ValuesType.PERCENTAGE}
                stacked
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
