import { useEffect, useState } from "react";
import SlackRepo from "../../domain/slack/SlackRepo";
import SlackInstallRepo from "../../domain/slack-install/SlackInstallRepo";
import { showError, showSuccess } from "../../domain/notification/NotificationService";
import { useTranslation } from "react-i18next";
import {
  SlackInstall,
  SlackInstallFields,
  SlackInstallForm,
} from "../../domain/slack-install/SlackInstall";
import { getUserMessage } from "../../infra/RestRepository";

const slackRepo = new SlackRepo();
const slackInstallRepo = new SlackInstallRepo();

const useSlackInstall = (wid: number, aid: number) => {
  const { t } = useTranslation();

  const [slackInstall, setSlackInstall] = useState<SlackInstall | null>(null);
  const [openSettings, setOpenSettings] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const initialValues: SlackInstallForm | null = slackInstall
    ? {
        syncCollaborators: slackInstall?.syncCollaborators,
        reminderEnabled: slackInstall?.reminderEnabled,
        weekDay: slackInstall?.reminderSetting?.weekDay
          ? slackInstall.reminderSetting?.weekDay
          : 1,
        hour: slackInstall?.reminderSetting?.hour
          ? slackInstall.reminderSetting?.hour
          : 8,
      }
    : null;

  const onOpenSettings = () => {
    setOpenSettings(true);
  };

  const handleCancelSettings = () => {
    setOpenSettings(false);
  };

  const polling = (url: string, onFinish: () => void) => {
    const popup = window.open(url);

    if (!popup) return null;

    let x = 0;

    const polling = setInterval(() => {
      if (!popup || popup.closed || popup.closed === undefined) {
        clearInterval(polling);
        onFinish();
      }

      try {
        if (popup.location.hostname.includes("semana.io")) {
          if (popup.location.search) {
            const query = new URLSearchParams(popup.location.search);
            const slackCode = query.get("code");

            if (slackCode) {
              onFinish();
              clearInterval(polling);
            }
          }
        }
      } catch (error) {
        // Throw because of CORS limitation while the flow redirect to Semana
      }

      if (++x === 60) {
        onFinish();
        clearInterval(polling);
      }
    }, 500);
  };

  const onFinish = () => {
    slackInstallRepo
      .get("/")
      .then(res => {
        setIsLoading(false);

        if (res.wid) {
          setSlackInstall({
            id: res.id,
            slackUserId: res.content.user.id,
            token: res.content.bot.token,
            syncCollaborators: res.syncCollaborators,
            reminderEnabled: res.reminderEnabled,
            reminderSetting: res.reminderSetting,
          });

          setOpenSettings(true);
          showSuccess(t("slack.integration-success"));
        } else {
          showError(t("slack.integration-failed"));
        }
      })
      .catch(e => {
        showError(t("main.error-occurred"));
        setIsLoading(false);
      });
  };

  const startInstall = async () => {
    try {
      setIsLoading(true);

      const url = await slackRepo.getInstallUrl(wid, aid);

      polling(url, onFinish);
    } catch (e) {
      showError(t("slack.add-error"));
    }
  };

  const uninstallSlack = async () => {
    if (!slackInstall) return null;

    setIsLoading(true);

    try {
      const res = await slackRepo.uninstallApp(
        slackInstall.token,
        // TODO @Billy
        slackInstall.slackUserId,
        wid
      );
      setSlackInstall(null);

      setIsLoading(false);
      showSuccess(t("slack.disconnected-successfully"));
      return res;
    } catch (e) {
      setIsLoading(false);
      showError(t("slack.disconnected-error"));
    }
  };

  const saveSlackInstall = async (values: SlackInstallForm) => {
    setIsLoading(true);

    const fields: SlackInstallFields = {
      reminderEnabled: values.reminderEnabled,
      reminderSetting: {
        weekDay: values.weekDay,
        hour: values.hour,
      },
      syncCollaborators: values.syncCollaborators,
    };

    if (slackInstall) {
      slackInstallRepo
        .put(`/${slackInstall.id}`, fields)
        .then(res => {
          setIsLoading(false);
          showSuccess(t("slack.update-success"));

          setSlackInstall({
            id: res.id,
            slackUserId: res.content.user.id,
            token: res.content.bot.token,
            syncCollaborators: res.syncCollaborators,
            reminderEnabled: res.reminderEnabled,
            reminderSetting: res.reminderSetting,
          });

          setOpenSettings(false);
        })
        .catch(e => {
          showError(getUserMessage(e) || t("slack.connect-failed"));
          setIsLoading(false);
        });
    } else {
      showError(t("slack.update-error"));
    }

    return;
  };

  useEffect(() => {
    slackInstallRepo
      .get("/")
      .then(res => {
        if (res.wid === wid) {
          setSlackInstall({
            id: res.id,
            slackUserId: res.content.user.id,
            token: res.content.bot.token,
            syncCollaborators: res.syncCollaborators,
            reminderEnabled: res.reminderEnabled,
            reminderSetting: res.reminderSetting,
          });

          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch(() => {
        showError(t("slack.add-error"));
      });
  }, [wid, t]);

  return {
    handleCancelSettings,
    onOpenSettings,
    startInstall,
    uninstallSlack,
    saveSlackInstall,
    isLoading,
    initialValues,
    openSettings,
    slackInstall,
    status,
  };
};

export default useSlackInstall;
