import RestRepository from "../../infra/RestRepository";
import { Policy } from "./Policy";
import { Optional } from "../../types";

export type AutoBookBalance = { ok: number; conflict: number };

export class PolicyRestRepo extends RestRepository {
  constructor() {
    super("/api/policies");
  }

  async update(policy: Policy): Promise<AutoBookBalance> {
    return super.put(policy.id, policy);
  }

  async create(policy: Optional<Policy, "id">): Promise<AutoBookBalance> {
    return super.post("/", policy);
  }

  async delete(policyId: number): Promise<AutoBookBalance> {
    return super.delete(policyId);
  }
}
