import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Select } from "antd";
import {
  BookingSettingsFormData,
  Workspace,
} from "../../../../domain/workspace/Workspace";

type Props = {
  initialValues: Workspace;
  onSave: (values: BookingSettingsFormData) => void;
};

export default ({ initialValues, onSave }: Props) => {
  const { t } = useTranslation();

  return (
    <Form onFinish={val => onSave(val)} initialValues={initialValues} layout="vertical">
      <Form.Item name="bookingTimeWindow" label={t("workspace.window-label")}>
        <Select>
          {[1, 2, 3, 4, 5, 6].map(value => (
            <Select.Option key={value} value={value}>
              {t("workspace.window-value", { count: value })}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item>
        <Button key="save" type="primary" htmlType="submit">
          {t("main.save")}
        </Button>
      </Form.Item>
    </Form>
  );
};
