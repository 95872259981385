import { RcFile } from "antd/lib/upload";
import { showError } from "../domain/notification/NotificationService";
import i18n from "../i18n";

export class FileUtils {
  static validateUploadedFile = (
    uploadedFile: RcFile | File,
    acceptedTypes: string[],
    invalidFormatMessageKey: string,
    invalidSizeMessageKey?: string,
    maxSize?: number
  ) => {
    const isValid = acceptedTypes.includes(uploadedFile.type);

    if (!isValid) {
      showError(i18n.t(invalidFormatMessageKey));

      return false;
    }

    if (maxSize !== undefined) {
      const isTooBig = uploadedFile.size / 1024 / 1024 > maxSize;

      if (isTooBig) {
        if (invalidSizeMessageKey) showError(i18n.t(invalidSizeMessageKey, { maxSize }));
        return false;
      }
    }
    return isValid;
  };
}
