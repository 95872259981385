import { showWarning } from "../domain/notification/NotificationService";
import i18n from "i18next";

export class RestRepoError extends Error {
  httpStatus: number;
  i18nKey?: string;

  constructor(props: { message?: string; httpStatus?: number; i18nKey?: string }) {
    super(
      `(I18nKey=${props.i18nKey}, HttpStatus=${props.httpStatus}) - ` + props.message
    );

    this.httpStatus = props.httpStatus || -1;
    this.i18nKey = props.i18nKey;
  }
}

export class NetworkError extends RestRepoError {}
export class ServerError extends RestRepoError {}
export class ClientError extends RestRepoError {}
export class UnauthorizedError extends RestRepoError {}

export const getUserMessage = (e: Error) => {
  if (e instanceof RestRepoError && e.i18nKey && i18n.exists(e.i18nKey)) {
    return i18n.t(e.i18nKey);
  }
};

let initialAppVersion: string | null = null;

/**
 * This class wrap the API interactions dealing with error and authentication.
 *
 */
export default class RestRepository {
  readonly prefix: string;
  readonly reloadOnUnauthorized: boolean;

  constructor(pathPrefix: string, reloadOnUnauthorized = true) {
    this.prefix = pathPrefix;
    this.reloadOnUnauthorized = reloadOnUnauthorized;
  }

  public async get(path: string | number) {
    return this.callApi({ path });
  }

  public async post(path: string | number, body = {}) {
    return this.callApi({
      path,
      method: "POST",
      body,
    });
  }

  public async put(path: string | number, body: any) {
    return this.callApi({
      path,
      method: "PUT",
      body,
    });
  }

  public delete(path: string | number) {
    return this.callApi({
      path,
      method: "DELETE",
    });
  }

  private async callApi(params: {
    body?: any;
    headers?: { [key: string]: string };
    method?: "DELETE" | "GET" | "POST" | "PUT";
    path?: string | number;
  }) {
    const { body, headers, method = "GET" } = params;
    const path = `${params.path}`;

    let url = this.prefix;
    if (path) {
      url += !url.endsWith("/") && !path.startsWith("/") ? "/" + path : path;
    }

    let response;
    try {
      response = await fetch(url, {
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          ...headers,
        },
        method,
      });
    } catch (e) {
      console.warn("Semana service is not reachable", e);
      throw new NetworkError({ i18nKey: "error-api.network-error" });
    }

    this.reloadOnAppVersionChanged(response);

    let respBody: {
      ok: boolean;
      error?: { message?: string; code?: string };
      data?: any;
    };
    try {
      respBody = await response.json();
    } catch (err) {
      let message;
      console.error((message = "Can't parse response body"), err);
      respBody = { ok: false, error: { message } };
    }

    // Unauthorized
    if (response.status === 401) {
      // todo Move the auth state in Redux
      if (this.reloadOnUnauthorized) window.location.reload();

      throw new UnauthorizedError({
        message: respBody.error?.message || "Unauthorized",
        httpStatus: response.status,
        i18nKey: respBody.error?.code,
      });
    }

    // Gateway timeout
    if (response.status === 504) {
      throw new NetworkError({
        httpStatus: response.status,
        i18nKey: "error-api.network-error",
      });
    }

    if (response.status > 499) {
      const message = respBody.error?.message || "Error occurred on server";
      console.warn(`${message} - HTTP status ${response.status}`);

      throw new ServerError({
        message,
        httpStatus: response.status,
        i18nKey: respBody.error?.code,
      });
    }

    if (response.status > 399) {
      const message = respBody.error?.message || "Bad request";
      console.warn(`${message} - HTTP status ${response.status}`);

      throw new ClientError({
        message,
        httpStatus: response.status,
        i18nKey: respBody.error?.code,
      });
    }

    if (!respBody.ok) {
      const message = respBody.error?.message || "Server returns not ok response";
      console.warn(`${message} - HTTP status ${response.status}`);

      throw new ServerError({
        message,
        httpStatus: response.status,
        i18nKey: respBody.error?.code,
      });
    }

    return respBody.data;
  }

  private reloadOnAppVersionChanged(response: Response) {
    const appVersionInCall = response.headers.get("x-app-version");

    if (!initialAppVersion && appVersionInCall) {
      initialAppVersion = appVersionInCall;
    }

    if (initialAppVersion && appVersionInCall && initialAppVersion !== appVersionInCall) {
      console.debug(
        `Initial app version: ${initialAppVersion}, app version in API call: ${appVersionInCall}. Reloading.`
      );
      showWarning(i18n.t("warning.api-version-changed"));
      setTimeout(window.location.reload.bind(window.location), 5000);
    }
  }
}
