import React, { useEffect, useState } from "react";
import { showError } from "../../../../domain/notification/NotificationService";
import { Card, Col, Row, Select } from "antd";
import { useTranslation } from "react-i18next";

import Chart, { ChartType } from "../Chart";

// Colors
import { chartColors } from "../../../colors";

// type
import {
  ChartData,
  DateRangeFilter,
  OccupancyBuilding,
  OccupancyFloor,
  ValuesType,
} from "../../../../domain/history/History";

// Repo
import BuildingRestRepo from "../../../../domain/building/BuildingRestRepo";
import { DataHistoryRepo } from "../../../../domain/history/HistoryRepo";

const stdChart = {
  borderWidth: 3,
  borderOpacity: 0.75,
  backgroundOpacity: 0.25,
  hoverBackgroundOpacity: 0.5,
  tension: 0.15,
};

const { Option, OptGroup } = Select;

type Props = {
  dateRange: Array<string>;
  showPercentage: ValuesType;
  startDate: string;
  endDate: string;
};

const buildingRepo = new BuildingRestRepo();
const dataHistoryRepo = new DataHistoryRepo();

const weekDayRange = ["Mon", "Tue", "Wed", "Thu", "Fri"];

export default ({ dateRange, showPercentage, startDate, endDate }: Props) => {
  // State
  const [data, setData] = useState<Array<ChartData>>([]);
  const [averageData, setAverageData] = useState<Array<ChartData>>([]);
  const [buildings, setBuildings] = useState<OccupancyBuilding[]>([]);
  const [selectedBuilding, setSelectedBuilding] = useState<OccupancyBuilding | null>(
    null
  );
  const [selectedFloor, setSelectedFloor] = useState<string>("");

  // hooks
  const { t } = useTranslation();

  const onChangeBuilding = (value: number) => {
    const building = buildings.find((b: OccupancyBuilding) => b.id === value);

    if (building) setSelectedBuilding(building);
    else setSelectedBuilding(null);

    setSelectedFloor("");
  };

  const onChangeFloor = (value: any) => {
    setSelectedFloor(value);
  };

  useEffect(() => {
    const buildingId = selectedBuilding ? selectedBuilding.id.toString() : "";
    const floorId = selectedFloor ? selectedFloor.toString() : "";
    const dateRange: DateRangeFilter = { startDate, endDate };

    dataHistoryRepo
      .getBuildings(dateRange, showPercentage, buildingId, floorId)
      .then(res => {
        setBuildings(res.buildings);

        setAverageData([
          {
            label: "Week day average office occupancy",
            color: chartColors.blue,
            borderColor: chartColors.blue,
            fill: true,
            tension: stdChart.tension,
            data: res.average,
          },
        ]);

        setData([
          {
            label: "Office occupancy",
            borderColor: chartColors.blue,
            color: chartColors.blue,
            tension: stdChart.tension,
            fill: true,
            data: res.occupancy.data,
          },
        ]);
      })
      .catch(() => {
        showError(t("dashboard.error-fetching-buildings"));
      });

    buildingRepo.get("/").then(res => {
      setBuildings(res);
    });
  }, [startDate, endDate, selectedBuilding, selectedFloor, showPercentage, t]);

  return (
    <Card>
      <Row>
        <Col span={24}>
          <Row gutter={[25, 25]} justify="center" align="middle">
            <Col span={16}>
              <h2>{t("dashboard.floors")}</h2>
            </Col>
            <Col span={4}>
              <label>{t("dashboard.show-data-from")}</label>
              <Select
                onChange={onChangeBuilding}
                defaultValue={-1}
                style={{ width: "100%" }}
              >
                <OptGroup label={t("dashboard.buildings")}>
                  <Option value={-1}>{t("dashboard.all")}</Option>
                  {buildings?.map((building: OccupancyBuilding, idx: number) => (
                    <Option key={`building-select-filter-${idx}`} value={building.id}>
                      {building.name}
                    </Option>
                  ))}
                </OptGroup>
              </Select>
            </Col>
            <Col span={4}>
              <label>{t("dashboard.floors")}</label>
              <Select
                value={selectedFloor || ""}
                disabled={selectedBuilding === null}
                onChange={onChangeFloor}
                defaultValue="all"
                style={{ width: "100%" }}
              >
                <OptGroup label={t("dashboard.floors")}>
                  <Option value="">{t("dashboard.all")}</Option>
                  {selectedBuilding !== null &&
                    selectedBuilding.floors.length > 1 &&
                    selectedBuilding?.floors.map((floor: OccupancyFloor, idx: number) => (
                      <Option key={`floor-select-filter-${idx}`} value={floor.id}>
                        {floor.name}
                      </Option>
                    ))}
                </OptGroup>
              </Select>
            </Col>
            <Col span={12}>
              <Chart
                data={data}
                dateRange={dateRange}
                type={ChartType.Line}
                label={t("dashboard.office-occupancy")}
                labelTooltip={t("dashboard.office-occupancy-tooltip")}
                showPercentage={showPercentage === ValuesType.PERCENTAGE}
              />
            </Col>
            <Col span={12}>
              <Chart
                data={averageData}
                dateRange={weekDayRange}
                type={ChartType.Bar}
                label={t("dashboard.weekday-average-occupancy")}
                labelTooltip={t("dashboard.weekday-average-occupancy-tooltip")}
                showPercentage={showPercentage === ValuesType.PERCENTAGE}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
