import { useTranslation } from "react-i18next";
import { Tag } from "antd";
import React, { CSSProperties } from "react";
import { Collaborator } from "../../../domain/collaborator/Collaborator";
import { TeamOutlined, UserOutlined } from "@ant-design/icons";
import { Community } from "../../../domain/community/Community";
import { useOrganization } from "../../hook/hooks";

export default (props: {
  community?: Community;
  collaborator?: Collaborator;
  absolute?: boolean;
  size?: "md" | "lg";
}) => {
  const { community, collaborator, absolute = true, size = "md" } = props;

  const { t } = useTranslation();
  const org = useOrganization();

  const communityName = community
    ? absolute
      ? org.getBranchName(community)
      : community.name
    : undefined;
  const style: CSSProperties = {
    fontSize: size === "md" ? 12 : 16,
    color: org.getCommunityColor(community?.id),
  };

  return (
    <span className="people-tag">
      <Tag icon={<TeamOutlined />}>
        <span style={style}>{communityName || t("main.not-defined")}</span>
      </Tag>

      {collaborator && <span>&gt;&nbsp;</span>}

      {collaborator && <Tag icon={<UserOutlined />}>{collaborator.name}</Tag>}
    </span>
  );
};
