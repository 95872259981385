import React from "react";
import { CSSTransition } from "react-transition-group";
import { GenericAvatar } from "../../page/calendar/EmployeeBookingRow";
import { BookingStatus, MyBookingRow } from "./MyBookingRow";

import "./booking-bar.less";
import { useBookingPermissions, useMyCollaborator, useWeekDays } from "../../hook/hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/state";
import Tippy from "@tippyjs/react";

export const BookingBar = () => {
  const isBookingBarShown = useSelector<RootState, boolean>(s => s.ui.isBookingBarShown);
  const myEmployee = useMyCollaborator();

  const weekDays = useWeekDays();
  const permission = useBookingPermissions();

  if (!myEmployee) return null;

  const balance = permission.getBookingBalance(myEmployee.id, weekDays);

  return (
    <CSSTransition
      classNames="booking-bar-transition"
      in={isBookingBarShown}
      timeout={600}
      unmountOnExit={true}
    >
      <div className={"booking-bar-transition-enter"} id="booking-bar">
        <Tippy theme="light" content={<BookingStatus balance={balance} />}>
          <div
            style={{
              left: "5px",
              position: "absolute",
              top: "5px",
            }}
          >
            <GenericAvatar path={myEmployee?.picturePath} />
          </div>
        </Tippy>
        <MyBookingRow isBookingBar={true} />
      </div>
    </CSSTransition>
  );
};
