import RestRepository from "../../infra/RestRepository";
import { Optional } from "../../types";
import { Floor } from "./Building";

export default class BuildingRestRepo extends RestRepository {
  constructor() {
    super("/api/buildings");
  }

  async saveFloor(floor: Optional<Floor, "id">) {
    if (floor.id) {
      return super.put(`/${floor.buildingId}/floors/${floor.id}`, floor);
    } else {
      return super.post(`/${floor.buildingId}/floors`, floor);
    }
  }
}
