import React from "react";

import { useTranslation } from "react-i18next";

// Ant
import { Checkbox, Form, Select, Tooltip, Button, Spin } from "antd";
import { BellOutlined, QuestionCircleOutlined, TeamOutlined } from "@ant-design/icons";

import "../../integration-setting.less";
import { SlackInstallForm } from "../../../../../domain/slack-install/SlackInstall";

type Props = {
  defaultValues: SlackInstallForm | Record<string, unknown>;
  isLoading: boolean;
  onFinish: (values: SlackInstallForm) => void;
};

export default (props: Props) => {
  const { defaultValues, onFinish, isLoading } = props;

  // hooks
  const { t } = useTranslation();

  const weekDay = [
    { label: t("weekday.monday"), value: 1 },
    { label: t("weekday.tuesday"), value: 2 },
    { label: t("weekday.wednesday"), value: 3 },
    { label: t("weekday.thursday"), value: 4 },
    { label: t("weekday.friday"), value: 5 },
    { label: t("weekday.saturday"), value: 6 },
    { label: t("weekday.sunday"), value: 7 },
  ];

  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 20,
      }}
      name="slack-settings-form"
      onFinish={onFinish}
      initialValues={{
        ...defaultValues,
      }}
    >
      <Form.Item
        wrapperCol={{ span: 24 }}
        name={"syncCollaborators"}
        valuePropName="checked"
      >
        <Checkbox>
          <span>
            <TeamOutlined />{" "}
          </span>
          {t("slack.user-provisioning")}
          <Tooltip className={"ml-xs"} title={t("slack.user-provisioning-description")}>
            <QuestionCircleOutlined />
          </Tooltip>
        </Checkbox>
      </Form.Item>

      <Form.Item
        wrapperCol={{ span: 24 }}
        name={"reminderEnabled"}
        valuePropName="checked"
      >
        <Checkbox
          onChange={e => form.setFieldsValue({ reminderEnabled: e.target.checked })}
        >
          <>
            <span>
              <BellOutlined />
            </span>
            <span className={"ml-xs"}>{t("slack.weekly-reminder")}</span>
            <Tooltip className={"ml-xs"} title={t("slack.weekly-reminder-description")}>
              <QuestionCircleOutlined />
            </Tooltip>
          </>
        </Checkbox>
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) =>
          prevValues.reminderEnabled !== curValues.reminderEnabled
        }
      >
        {() => (
          <Form.Item
            labelAlign={"left"}
            labelCol={{
              span: 4,
            }}
            colon={false}
            label={t("slack.send-it-on")}
            style={{ marginBottom: 0 }}
          >
            <Form.Item
              colon={false}
              wrapperCol={{ span: 24 }}
              name={"weekDay"}
              style={{
                display: "inline-block",
                width: "calc(40% - 8px)",
                margin: "0px 8px",
              }}
            >
              <Select disabled={!form.getFieldValue("reminderEnabled")}>
                {weekDay.map((w, i) => (
                  <Select.Option key={`weekday-${w.label}-${i}`} value={w.value}>
                    {w.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              wrapperCol={{ span: 24 }}
              name={"hour"}
              style={{ display: "inline-block", width: "calc(40% - 8px)" }}
            >
              <Select disabled={!form.getFieldValue("reminderEnabled")}>
                {[...new Array(24)].map((n, i) => {
                  return (
                    <Select.Option key={`hour-${i}`} value={i}>
                      {i < 10 ? `0${i}:00` : `${i}:00`}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item wrapperCol={{ span: 16, offset: 9 }}>
        <Button type="primary" htmlType="submit">
          {isLoading && <Spin size="small" />}
          {!isLoading && t("main.save")}
        </Button>
      </Form.Item>
    </Form>
  );
};
