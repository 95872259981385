import React, { useCallback } from "react";
import { Input } from "antd";

import { setHighlightedCollaborators, setSearchTerm } from "../../redux/actions";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

export default () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onSearch = useCallback(
    (value: string) => {
      // If we had selected something, let's throw it away
      dispatch(setHighlightedCollaborators({ collaboratorIds: [] }));
      dispatch(setSearchTerm({ value }));
    },
    [dispatch]
  );

  return (
    <div>
      <Input.Search
        allowClear
        placeholder={t("search-bar.search-emp")}
        onChange={evt => onSearch(evt.target.value)}
      />
    </div>
  );
};
