import Organization from "../../../domain/community/Organization";
import { Community } from "../../../domain/community/Community";
import { Collaborator } from "../../../domain/collaborator/Collaborator";
import { TreeKey } from "../../redux/state";
import { Consumer } from "../../../types";
import { DataNode } from "rc-tree/lib/interface";
import * as React from "react";

type PeopleNode = {
  community?: Community;
  collaborator?: Collaborator;
  parent?: PeopleNode;
  children?: PeopleNode[];
  matched: boolean;
};

export default class PeopleTreeManager {
  private nodes: PeopleNode[] = [];
  private searched = false;

  constructor(private org: Organization) {
    const roots = org.findRootCommunities();

    for (const root of roots) {
      const node: PeopleNode = { community: root, matched: false };
      (node.children = this.children(node)), this.nodes.push(node);
    }
  }

  keyToString(key: TreeKey) {
    return key.communityId
      ? `community||${key.communityId}`
      : `collaborator||${key.collaboratorId}`;
  }

  stringToKey(val: string): TreeKey {
    const arr = val.split("||");
    if (arr.length === 2) {
      return {
        communityId: arr[0] === "community" ? Number(arr[1]) : undefined,
        collaboratorId: arr[0] === "collaborator" ? Number(arr[1]) : undefined,
      };
    } else {
      return {};
    }
  }

  private children(parent: PeopleNode): PeopleNode[] {
    return [
      ...this.org.findDirectChildren(parent.community!).map(c => {
        const node: PeopleNode = { community: c, parent, matched: false };
        node.children = this.children(node);
        return node;
      }),

      ...this.org
        .findEnabledCollaboratorsByCommunityId(parent.community!.id)
        .map(collab => ({ collaborator: collab, parent, matched: false })),
    ];
  }

  search(search?: string): TreeKey[] | undefined {
    if (!search) {
      this.searched = false;
      return;
    }

    this.searched = true;
    const searchRE = new RegExp(".*" + search + ".*", "i");

    this.visitAllNode(node => {
      node.matched = searchRE.test(
        node.community ? node.community.name : node.collaborator!.name
      );

      if (node.matched) {
        this.visitParent(node, parent => {
          parent.matched = true;
        });
      }
    });

    const keys: TreeKey[] = [];
    this.visitAllNode(node => {
      if (node.matched) {
        keys.push({
          communityId: node.community?.id,
          collaboratorId: node.collaborator?.id,
        });
      }
    });

    return keys;
  }

  getTreeData(
    communityTitle: (c: Community) => React.ReactNode,
    collabTitle: (c: Collaborator) => React.ReactNode
  ): DataNode[] {
    return this.nodes
      .map(node => this.toDataNode(node, communityTitle, collabTitle))
      .filter(v => !!v) as DataNode[];
  }

  private toDataNode(
    node: PeopleNode,
    communityTitle: (c: Community) => React.ReactNode,
    collabTitle: (c: Collaborator) => React.ReactNode
  ): DataNode | undefined {
    if ((this.searched && node.matched) || !this.searched) {
      if (node.community) {
        return {
          key: this.keyToString({ communityId: node.community.id }),
          isLeaf: false,
          title: communityTitle(node.community),
          children: node.children
            ?.map(n => this.toDataNode(n, communityTitle, collabTitle))
            .filter(v => !!v) as DataNode[],
        };
      }

      if (node.collaborator) {
        return {
          key: this.keyToString({ collaboratorId: node.collaborator.id }),
          isLeaf: true,
          title: collabTitle(node.collaborator),
        };
      }
    }
  }

  private visitAllNode(visitor: Consumer<PeopleNode>) {
    for (const node of this.nodes) {
      this.visitNodeAndChildren(node, visitor);
    }
  }

  private visitNodeAndChildren(node: PeopleNode, visitor: Consumer<PeopleNode>) {
    visitor(node);
    node.children?.forEach(n => this.visitNodeAndChildren(n, visitor));
  }

  private visitParent(node: PeopleNode, visitor: Consumer<PeopleNode>) {
    if (node.parent) {
      visitor(node.parent);
      this.visitParent(node.parent, visitor);
    }
  }
}
