import { Collaborator } from "../collaborator/Collaborator";
import { Community } from "./Community";

export type CommunityWithCollaborators = Community & { collaborators: Collaborator[] };

export default class Organization {
  readonly collaborators: Collaborator[];
  communities: Community[];

  constructor(collaborators: Collaborator[], communities: Community[]) {
    this.collaborators = [...collaborators];
    this.communities = [...communities];

    this.sort();
  }

  sort() {
    this.collaborators.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    );

    this.communities.sort((a, b) => this.compareCommunity(a, b));
  }

  compareCommunity(a: Community, b: Community) {
    return this.getBranchName(a)
      .toLowerCase()
      .localeCompare(this.getBranchName(b).toLowerCase());
  }

  findCommunityById(communityId?: number) {
    if (!communityId) return undefined;
    return this.communities.find(c => c.id === communityId);
  }

  findEnabledCollaboratorsByCommunityId(communityId?: number) {
    return this.findEnabledCollaborators().filter(
      collab => collab.communityId && collab.communityId === communityId
    );
  }

  getCommunitiesWithCollaborators(): CommunityWithCollaborators[] {
    return this.communities.map(c => ({
      ...c,
      collaborators: this.findEnabledCollaboratorsByCommunityId(c.id),
    }));
  }

  getCollaboratorsWithoutCommunity(): Collaborator[] {
    return this.collaborators.filter(c => !c.communityId);
  }

  getCollabName(collabId: number) {
    return this.findCollaborator(collabId)?.name || "NA";
  }

  findCollaborator(collabId?: number) {
    if (!collabId) return;
    return this.collaborators.find(e => e.id === collabId);
  }

  getCollaboratorColor(collabId: number) {
    const collab = this.findCollaborator(collabId);
    return this.findInClosestParent(collab?.communityId, c => c.color) || "black";
  }

  findEnabledCollaborators() {
    return this.collaborators.filter(c => c.status === "enabled");
  }

  findCommunityByCollaboratorId(collaboratorId: number) {
    const collab = this.findCollaborator(collaboratorId);
    if (collab) {
      return this.findCommunityById(collab.communityId);
    }
  }

  findInClosestParent<T>(
    communityId: number | undefined,
    func: (c: Community) => T | undefined
  ): T | undefined {
    let id = communityId;
    while (id) {
      const c = this.communities.find(c => c.id === id);

      if (c) {
        const val = func(c);
        if (val) return val;
      }

      id = c?.parentId;
    }
  }

  getBranchName(community: Community) {
    const segments: string[] = [];
    segments.push(community.name);

    let id = community.parentId;
    while (id) {
      const c = this.communities.find(c => c.id === id);
      if (c) segments.push(c.name);
      id = c?.parentId;
    }

    return segments.reverse().join(" > ");
  }

  findAllChildren(community: Community): Community[] {
    const res: Community[] = [];
    if (!community) return res;

    res.push(community);

    const directChildren = this.findDirectChildren(community);

    for (const directChild of directChildren) {
      res.push(...this.findAllChildren(directChild));
    }

    return res;
  }

  findDirectChildren(community: Community) {
    return this.communities.filter(c => c.parentId && c.parentId === community.id);
  }

  findZoneForCollab(collaboratorId: number) {
    const community = this.findCommunityByCollaboratorId(collaboratorId);

    const root = this.findInClosestParent(community?.id, c => {
      if (c.parentId) return undefined;
      return c;
    });

    return root?.id;
  }

  findRootCommunities() {
    return this.communities.filter(c => !c.parentId);
  }

  getCommunityColor(communityId?: number) {
    return this.findInClosestParent(communityId, c => c.color) || "#8a8a8a";
  }
}
