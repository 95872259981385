import React from "react";
import { useTranslation } from "react-i18next";

// antd
import { Button, Checkbox, Row, Col, Form, Select, Tooltip, Spin } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

import useEmailNotificationSettings from "../../../hook/useEmailNotificationsSettings";
import "../integration-setting.less";

export default () => {
  // hooks
  const [form] = Form.useForm();

  const { t } = useTranslation();

  const weekDay = [
    { label: t("weekday.monday"), value: 1 },
    { label: t("weekday.tuesday"), value: 2 },
    { label: t("weekday.wednesday"), value: 3 },
    { label: t("weekday.thursday"), value: 4 },
    { label: t("weekday.friday"), value: 5 },
    { label: t("weekday.saturday"), value: 6 },
    { label: t("weekday.sunday"), value: 7 },
  ];
  const { onSave, triggerSendOnboardingEmail, isLoading, initialValues } =
    useEmailNotificationSettings();

  return (
    <Row>
      <Col span={24}>
        <h2>{t("workspace.email-notifications")}</h2>
      </Col>
      <Col span={24}>
        <Form
          form={form}
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          initialValues={{
            ...initialValues,
          }}
          onFinish={onSave}
        >
          <Form.Item valuePropName="checked" name="emailOnboardingEnabled">
            <Checkbox
              onChange={e =>
                form.setFieldsValue({ emailOnboardingEnabled: e.target.checked })
              }
            >
              <>
                <span className={"ml-xs"}>{t("workspace.send-onboarding-email")}</span>
                <Tooltip
                  className={"ml-xs"}
                  title={t("workspace.send-onboarding-email-description")}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </>
            </Checkbox>
          </Form.Item>

          <Button onClick={triggerSendOnboardingEmail} className={"mb-lg"}>
            {isLoading ? <Spin size="small" /> : t("workspace.send-now")}
          </Button>

          <Form.Item valuePropName="checked" name="emailReminderEnabled">
            <Checkbox
              onChange={e =>
                form.setFieldsValue({ emailReminderEnabled: e.target.checked })
              }
            >
              <>
                <span className={"ml-xs"}>{t("workspace.send-weekly-reminder")}</span>
                <Tooltip
                  className={"ml-xs"}
                  title={t("workspace.send-weekly-reminder-description")}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </>
            </Checkbox>
          </Form.Item>

          <Form.Item
            labelCol={{
              span: "30px",
            }}
            wrapperCol={{
              span: 24,
            }}
            label={t("workspace.send-it-on")}
          >
            <div className={"send-it-on-block"}>
              <Form.Item
                name="weekDay"
                style={{ margin: "0px 8px" }}
                wrapperCol={{
                  span: 4,
                }}
              >
                <Select>
                  {weekDay.map((w, i) => (
                    <Select.Option key={`weekday-${w.label}-${i}`} value={w.value}>
                      {w.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={t("main.at")}
                colon={false}
                name="hour"
                labelCol={{
                  span: 3,
                }}
                wrapperCol={{
                  span: 4,
                }}
              >
                <Select>
                  {[...new Array(24)].map((n, i) => {
                    return (
                      <Select.Option key={`hour-${i}`} value={i}>
                        {i < 10 ? `0${i}:00` : `${i}:00`}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </Form.Item>
          <Form.Item>
            <Button key="save" type="primary" htmlType="submit">
              {t("main.save")}
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};
