import { Booking } from "../../../../domain/booking/Booking";
import { Desk } from "../../../../domain/floorPlan/FloorPlan";
import FloorViewerController, { DragToDesk, DragType } from "./FloorViewerController";
import classNames from "classnames";
import React from "react";
import { Tooltip } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useDrag } from "react-dnd";
import { useImageURL } from "../../../hook/hooks";

export default (props: {
  booking: Booking;
  desk: Desk;
  zoom: number;
  highlighted: boolean;
  controller: FloorViewerController;
}) => {
  const { desk, zoom, booking, controller, highlighted = false } = props;

  if (!desk) {
    console.error("Empty desk " + booking.deskId);
    return null;
  }

  const width = desk.w * zoom,
    height = desk.h * zoom;
  const ratio = highlighted ? 1.7 : 1.5;
  const avatarSize = height * ratio;

  return (
    <div
      id={`booking-${booking.id}`}
      className={classNames([
        "floor-booking",
        {
          highlighted: highlighted,
        },
      ])}
      style={{
        top:
          (desk.cy - (Math.cos((desk.rot / 180) * Math.PI) * desk.h) / 2 - desk.h / 2) *
          zoom,
        left:
          (desk.cx + (Math.sin((desk.rot / 180) * Math.PI) * desk.h) / 2 - desk.w / 2) *
          zoom,
        width,
        height,
      }}
    >
      <Avatar booking={booking} controller={controller} size={avatarSize} />
    </div>
  );
};

const Avatar = (props: {
  booking: Booking;
  size: number;
  controller: FloorViewerController;
}) => {
  const { booking, size, controller } = props;
  const draggable = controller.isBookingMovable(booking);
  const imageUrl = useImageURL(controller.getBookingImagePath(booking));

  // TODO @Billy
  const [collected, drag] = useDrag({
    item: {
      type: DragType.drag_to_desk,
      collaboratorId: booking.collaboratorId,
      bookingId: booking.id,
    } as DragToDesk,
    canDrag: draggable,
  });

  return (
    <div className="avatar" style={{ width: size, height: size }}>
      <div
        ref={drag}
        className={classNames({
          "avatar-img": true,
          draggable: draggable,
        })}
        style={{ borderRadius: size }}
      >
        <Tooltip title={controller.getBookingName(booking)} placement="bottom">
          <img draggable={false} src={imageUrl} />
        </Tooltip>
      </div>

      {controller.isBookingRemovable(booking) && (
        <div className="avatar-close" onClick={() => controller.removeBooking(booking)}>
          <CloseCircleOutlined
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              fill: "#555",
            }}
          />
        </div>
      )}
    </div>
  );
};
