function initScreeb() {
  (function (s, c, r, ee, b) {
    s["ScreebObject"] = r;
    s[r] =
      s[r] ||
      function () {
        (s[r].q = s[r].q || []).push(arguments);
      };
    b = c.createElement("script");
    b.type = "text/javascript";
    b.id = r;
    b.src = ee;
    b.async = 1;
    c.getElementsByTagName("head")[0].appendChild(b);
  })(window, document, "$screeb", "https://t.screeb.app/tag.js");

  // eslint-disable-next-line no-undef
  $screeb("init", "ba7b2a73-e705-49d8-8863-c4e7130daeb7");
}

export default initScreeb;
