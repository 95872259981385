import RestRepository from "../../infra/RestRepository";
import { Collaborator } from "./Collaborator";

export class CollaboratorRestRepo extends RestRepository {
  constructor() {
    super("/api/collaborators");
  }

  async find(): Promise<Collaborator[]> {
    return super.get("/");
  }

  async create(collaborator: Omit<Collaborator, "id">) {
    return super.post("/", collaborator);
  }

  async update(collaborator: Collaborator) {
    return super.put(collaborator.id, collaborator);
  }
}
