import * as React from "react";
import arrowImage from "./arrow-1.png";
import { MyBookingRow } from "../../component/my-booking-bar/MyBookingRow";
import { useTranslation } from "react-i18next";

import "./CalendarDashboard.css";
import { WeeklyCalendar } from "./WeeklyCalendar";
import { useSelector } from "react-redux";
import { RootState, TreeKey } from "../../redux/state";
import { useWorkspace } from "../../hook/hooks";

export const CalendarDashboard = () => {
  const { t } = useTranslation();
  const workspace = useWorkspace();
  const selectedKeys = useSelector<RootState, TreeKey[]>(s => s.peopleTree.selectedKeys);

  const key = selectedKeys.length > 0 ? selectedKeys[0] : undefined;

  if (!key) {
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          fontSize: "15px",
          height: "100%",
          justifyContent: "flex-start",
          marginLeft: "20px",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "grid",
            fontSize: "14px",
            gridTemplateColumns: "200px 1fr 1fr 1fr 1fr 1fr",
            gridTemplateRows: "42px auto",
            paddingRight: "20px",

            width: "100%",
          }}
        >
          <MyBookingRow />
        </div>
        <div
          style={{
            backgroundColor: "#f1f1f1",
            borderRadius: "20px",
            marginTop: "130px",
            padding: "10px 20px 10px 20px",
            position: "relative",
          }}
        >
          {workspace && workspace.welcomeMessage ? (
            <div className={"welcome-message"}>{workspace.welcomeMessage}</div>
          ) : (
            <div>{t("calendar.book-msg")}</div>
          )}
        </div>
        <div
          style={{
            backgroundColor: "#f1f1f1",
            borderRadius: "20px",
            marginTop: "50px",
            padding: "10px 20px 10px 20px",
            position: "relative",
          }}
        >
          <img
            src={arrowImage}
            style={{
              left: "-120px",
              position: "absolute",
              top: "-80px",
              transform: "scale(0.4) rotate(0.73turn)",
            }}
          />
          <div>{t("calendar.select-team")}</div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          height: "100%",
          marginLeft: "20px",
          marginTop: "-20px",
          width: "100%",
        }}
      >
        <WeeklyCalendar
          collaboratorId={key?.collaboratorId}
          communityId={key?.communityId}
        />
      </div>
    </>
  );
};
