import { createAsyncThunk } from "@reduxjs/toolkit";
import { Collaborator } from "../../../domain/collaborator/Collaborator";
import { CollaboratorRestRepo } from "../../../domain/collaborator/CollaboratorRestRepo";
import { showError, showSuccess } from "../../../domain/notification/NotificationService";
import i18n from "../../../i18n";
import { getUserMessage } from "../../../infra/RestRepository";

const collabRepo = new CollaboratorRestRepo();

export const saveMultipleCollaborator = createAsyncThunk<void, Collaborator[]>(
  "api/saveMultipleCollaborator",
  async (payload, thunkAPI) => {
    return Promise.all(payload.map(collab => collabRepo.update(collab)))
      .then(() => {
        showSuccess(i18n.t("collaborator.save-success"));
        thunkAPI.dispatch(refreshCollaborators());
      })
      .catch(err => {
        showError(i18n.t("collaborator.save-error"));
        throw err;
      });
  }
);

export const saveCollaborator = createAsyncThunk<Collaborator, Collaborator>(
  "api/saveCollaborator",
  async (payload, thunkAPI) => {
    return (payload.id ? collabRepo.update(payload) : collabRepo.create(payload))
      .then(collab => {
        showSuccess(i18n.t("collaborator.save-success"));
        thunkAPI.dispatch(refreshCollaborators());
        return collab;
      })
      .catch(err => {
        showError(getUserMessage(err) || i18n.t("collaborator.save-error"));
        throw err;
      });
  }
);

export const refreshCollaborators = createAsyncThunk<Collaborator[]>(
  "api/refreshEmployees",
  async () => collabRepo.get("/")
);
