import React from "react";
import SlackIntegration from "./integrations/slack-integration/SlackIntegration";
import LuccaIntegration from "./integrations/lucca-integration/LuccaIntegration";
import GoogleIntegration from "./integrations/google-integration/GoogleIntegration";
import { useAuthUser } from "../../hook/hooks";

// Antd
import { Col, Row } from "antd";

import "./integration-setting.less";

export default () => {
  const {wid, aid} = useAuthUser();

  return (
    <Row gutter={[0, 40]}>
      <Col className={"column-integration"} xs={12} md={8} lg={6} xl={6}>
        <GoogleIntegration />
      </Col>
      <Col className={"column-integration"} xs={12} md={8} lg={6} xl={6}>
        <SlackIntegration wid={wid} aid={aid} />
      </Col>
      <Col className={"column-integration"} xs={12} md={8} lg={6} xl={6}>
        <LuccaIntegration wid={wid} />
      </Col>
    </Row>
  );
};
