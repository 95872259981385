import React from "react";

import "./Panels.css";

interface Props {
  First: any;
  Rest: any;
  Second: any;
}

export const Panels = (props: Props) => {
  const { First, Rest, Second } = props;

  return (
    <div
      id="panels-toplevel"
      style={{
        border: "0px solid orange",
        display: "flex",
        overflow: "hidden",
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      <>
        <div id="master-first">
          <First />
        </div>

        <div id="master-second">
          <Second />
        </div>
      </>
      <div
        id="master-third"
        style={{
          borderRadius: "4px 0px 4px 4px",
          height: "100%",
          marginLeft: "0px",
          position: "absolute",
          transitionDuration: "0s",
          width: "100%",
        }}
      >
        {Rest}
      </div>
    </div>
  );
};
