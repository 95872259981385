import * as React from "react";
import { useCallback, useMemo } from "react";
import { Tree } from "antd";
import { DataNode, EventDataNode } from "rc-tree/lib/interface";

import "./PeopleTree.less";
import {
  useAuthUser,
  useOrganization,
  useRoutePage,
  useOfficeSpace,
} from "../../hook/hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  locateCollaborator,
  setHighlightedCollaborators,
  setPeopleTreeExpandedKeys,
  setPeopleTreeSelectedKeys,
} from "../../redux/actions";
import { Shifts } from "../../../domain/booking/Booking";
import { useDrag } from "react-dnd";
import { DragToDesk, DragType } from "../floor/viewer/FloorViewerController";
import { RootState, TreeKey } from "../../redux/state";
import { Pages } from "../../page/LeftMainMenu";
import CommunityTag from "../people-tag/PeopleTag";
import { Collaborator } from "../../../domain/collaborator/Collaborator";
import PeopleTreeManager from "./PeopleTreeManager";

const CollaboratorHandler = (props: { collaborator: Collaborator; isAdmin: boolean }) => {
  const { collaborator, isAdmin } = props;

  const [, drag] = useDrag({
    item: {
      type: DragType.drag_to_desk,
      collaboratorId: collaborator.id,
    } as DragToDesk,
    canDrag: isAdmin,
  });

  return (
    <span
      ref={drag}
      id={`peopletree-employee-${collaborator.id}`}
      className="collaborator-handler"
    >
      {collaborator.name}
    </span>
  );
};

export const PeopleTree = () => {
  const dispatch = useDispatch();
  const space = useOfficeSpace();
  const user = useAuthUser();
  const isAdmin = user.role === "admin";

  const peopleTree = useSelector<
    RootState,
    { expandedKeys: TreeKey[]; selectedKeys: TreeKey[] }
  >(s => s.peopleTree);
  let { expandedKeys = [] } = peopleTree;
  const { selectedKeys = [] } = peopleTree;

  const org = useOrganization();
  const peopleTreeManager = useMemo(() => new PeopleTreeManager(org), [org]);

  const page = useRoutePage();
  const shifts = useSelector<RootState, Shifts>(s => s.shifts);
  const selectedDay = useSelector<RootState, string>(s => s.selectedDay);

  const searchTerm = useSelector<RootState, string>(s => s.searchTerm || "");

  const searchedKeys = peopleTreeManager.search(searchTerm);
  if (searchedKeys) {
    expandedKeys = searchedKeys;
  }

  const treeData = peopleTreeManager.getTreeData(
    community => <CommunityTag community={community} absolute={false} />,
    collab => <CollaboratorHandler collaborator={collab} isAdmin={isAdmin} />
  );

  const tryToLocateCollaborator = useCallback(
    (collaboratorId: number) => {
      if (page !== Pages.floorplans) return;

      const booking = shifts[collaboratorId]?.bookings[selectedDay];
      if (booking?.deskId) {
        const floorId = space.getFloorIdByDeskId(booking.deskId);
        if (floorId) {
          dispatch(
            locateCollaborator({
              collaboratorId: collaboratorId,
              date: booking.date,
              floorId,
            })
          );
        }
      }
    },
    [page, shifts, selectedDay, space, dispatch]
  );

  const onSelect = (
    selectedKeys: React.ReactText[],
    info: {
      event: "select";
      nativeEvent: MouseEvent;
      node: EventDataNode;
      selected: boolean;
      selectedNodes: DataNode[];
    }
  ) => {
    if (info.node.selected) {
      dispatch(setPeopleTreeSelectedKeys([]));
      dispatch(setHighlightedCollaborators(null));
    } else {
      const treeKey = peopleTreeManager.stringToKey(info.node.key as string);
      dispatch(setPeopleTreeSelectedKeys([treeKey]));

      if (treeKey.collaboratorId) {
        dispatch(
          setHighlightedCollaborators({ collaboratorIds: [treeKey.collaboratorId] })
        );
        tryToLocateCollaborator(treeKey.collaboratorId);
      }

      if (treeKey.communityId) {
        dispatch(setHighlightedCollaborators({ communityIds: [treeKey.communityId] }));
      }
    }
  };

  const onExpand = (data: React.Key[]) => {
    dispatch(
      setPeopleTreeExpandedKeys(data.map(k => peopleTreeManager.stringToKey(k as string)))
    );
  };

  return (
    <div className="people-tree">
      <div id="people-tree-no-searchterm" className="mt-md">
        <Tree
          expandedKeys={expandedKeys.map(k => peopleTreeManager.keyToString(k))}
          selectedKeys={selectedKeys.map(k => peopleTreeManager.keyToString(k))}
          onExpand={onExpand}
          onSelect={onSelect}
          treeData={treeData}
        />
      </div>
    </div>
  );
};
