import RestRepository from "../../infra/RestRepository";

// Types
import { ValuesType } from "./History";
import { OccupancyChart, AttendanceChart, DateRangeFilter } from "./History";

export class DataHistoryRepo extends RestRepository {
  constructor() {
    super("/api/data-history");
  }

  async initDataHistory(): Promise<Array<string>> {
    return super.get("/init");
  }

  async getBuildings(
    dateRange: DateRangeFilter,
    showPercentage: ValuesType,
    buildingId: string,
    floorId: string
  ): Promise<OccupancyChart> {
    const { startDate, endDate } = dateRange;
    return super.get(
      `/buildings?start=${startDate}&end=${endDate}&buildingId=${buildingId}&floorId=${floorId}&showPercentage=${showPercentage}`
    );
  }

  async getCommunities(
    dateRange: DateRangeFilter,
    showPercentage: ValuesType,
    communityId: string
  ): Promise<AttendanceChart> {
    const { startDate, endDate } = dateRange;
    return super.get(
      `/communities?start=${startDate}&end=${endDate}&communityId=${communityId}&showPercentage=${showPercentage}`
    );
  }
}
