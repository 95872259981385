import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { showError, showSuccess } from "../../domain/notification/NotificationService";
import { Booking, bookingToShifts, Shifts } from "../../domain/booking/Booking";
import BookingRestRepo from "../../domain/booking/BookingRestRepo";
import { Desk, FloorPlan } from "../../domain/floorPlan/FloorPlan";
import FloorPlanRestRepo from "../../domain/floorPlan/FloorPlanRestRepo";
import { Building, Floor } from "../../domain/building/Building";
import BuildingRestRepo from "../../domain/building/BuildingRestRepo";
import i18n from "../../i18n";
import { getUserMessage } from "../../infra/RestRepository";
import WorkspaceRestRepo from "../../domain/workspace/WorkspaceRestRepo";
import { Optional } from "../../types";
import AuthService, { AuthUser, Lang } from "../../domain/auth/AuthService";
import { CollaboratorRestRepo } from "../../domain/collaborator/CollaboratorRestRepo";
import { Collaborator } from "../../domain/collaborator/Collaborator";
import { Workspace } from "../../domain/workspace/Workspace";
import { RootState, TreeKey } from "./state";
import { Policy } from "../../domain/policy/Policy";
import { PolicyRestRepo } from "../../domain/policy/PolicyRestRepo";
import { Community } from "../../domain/community/Community";
import CommunityRestRepo from "../../domain/community/CommunityRestRepo";
import { Tracker } from "../../domain/tracker/Tracker";

import {
  BookingSettingsFormData,
  EmailsSettingsFormData,
} from "../../domain/workspace/Workspace";

const communityRepo = new CommunityRestRepo();
const collabRepo = new CollaboratorRestRepo();
const policyRepo = new PolicyRestRepo();
const workspaceRepo = new WorkspaceRestRepo();
const bookingRepo = new BookingRestRepo();
const floorPlanRepo = new FloorPlanRestRepo();
const buildingRepo = new BuildingRestRepo();

const authService = new AuthService();

export const incrPendingActions = createAction<{ incr: number }>("incrPendingAction");

export const changeUi = createAction<
  {
    isBookingBarShown?: boolean;
    modalShown?: null | string;
    revealFp?: boolean;
    hasFloorPlanBeenDisplayed?: boolean;
  },
  "changeUi"
>("changeUi");

export const changeViewedFloor = createAction<{ floorId: number }, "changeViewedFloor">(
  "changeViewedFloor"
);

export const setSearchTerm = createAction<{ value: string }, "setSearchTerm">(
  "setSearchTerm"
);

export const locateCollaborator = createAction<{
  collaboratorId: number;
  date: string;
  floorId: number;
}>("locateCollaborator");

export const setPeopleTreeSelectedKeys = createAction<
  TreeKey[],
  "setPeopleTreeSelectedKeys"
>("setPeopleTreeSelectedKeys");

export const setPeopleTreeExpandedKeys = createAction<
  TreeKey[],
  "setPeopleTreeExpandedKeys"
>("setPeopleTreeExpandedKeys");

export const setWeek = createAction<string, "setWeek">("setWeek");

export const setSelectedDay = createAction<string, "setSelectedDay">("setSelectedDay");

export const setHighlightedCollaborators = createAction<
  { collaboratorIds?: number[]; communityIds?: number[] } | null,
  "setHighlightedCollaborators"
>("setHighlightedCollaborators");

export type InitData = {
  buildings: Building[];
  floorPlans: FloorPlan[];

  policies: Policy[];

  collaborators: Collaborator[];
  communities: Community[];

  bookings: Booking[];
  shifts: Shifts;

  workspace: Workspace;
};

export const loadData = createAsyncThunk<InitData, AuthUser>(
  "api/loadData",
  async payload => {
    try {
      const apiResults = await Promise.all([
        collabRepo.get("/"),
        bookingRepo.get("/"),
        policyRepo.get("/"),
        communityRepo.get("/"),
        buildingRepo.get("/"),
        floorPlanRepo.get("/"),
        workspaceRepo.getWorkspace(payload.wid),
      ]);

      const [
        collabData,
        bookingsData,
        policyData,
        communityData,
        buildingData,
        floorPlanData,
        workspace,
      ] = apiResults;

      return {
        buildings: buildingData,
        floorPlans: floorPlanData,
        policies: policyData,
        collaborators: collabData,
        communities: communityData,
        bookings: bookingsData,
        shifts: bookingToShifts(bookingsData),
        workspace,
      } as InitData;
    } catch (err) {
      console.error(err);
      showError(i18n.t("error.load-data-failed"));
      throw err;
    }
  }
);

export const loadAuthUser = createAsyncThunk<AuthUser | undefined>(
  "api/loadAuthUser",
  async (_payload, thunkAPI) => {
    try {
      const user = await authService.getAuthUser();

      if (user?.aid) {
        Tracker.identify(user);

        // i18n
        if (i18n.language !== user.lang) {
          thunkAPI.dispatch(changeLang(user.lang));
        }

        thunkAPI.dispatch(loadData(user));

        return user;
      }
    } catch (e) {
      console.error("Get auth user failed", e);

      const msg = getUserMessage(e);
      if (msg) showError(msg);
    }

    return;
  }
);

export const updateUserPicture = createAction<{
  collaboratorId: number;
  picturePath: string;
}>("updateUserPicture");
export const updateUserInfos = createAsyncThunk<
  { name: string; lang: Lang },
  { name: string; lang: Lang }
>("api/updateUserInfos", async payload => {
  const { name, lang } = payload;

  if (lang) {
    await i18n.changeLanguage(lang);
  }

  return { name, lang };
});

export const changeLang = createAsyncThunk<"en" | "fr", "en" | "fr">(
  "api/changeLang",
  async payload => {
    console.log("Change lang", payload);

    await i18n.changeLanguage(payload);
    return payload;
  }
);

/********************************
 *  Workspace related actions
 ********************************/

export const saveWorkspace = createAsyncThunk<
  Workspace,
  BookingSettingsFormData | EmailsSettingsFormData
>("api/saveWorkspace", async payload => {
  return workspaceRepo
    .update({ ...payload })
    .then(w => {
      showSuccess(i18n.t("workspace.save-success"));
      return w;
    })
    .catch(err => {
      showError(i18n.t("workspace.save-error"));
      throw err;
    });
});

/********************************
 *  Booking related actions
 ********************************/

export const addBooking = createAsyncThunk<Booking, Omit<Booking, "id" | "status">>(
  "api/addBooking",
  async (payload, thunkAPI) => {
    try {
      const booking = await bookingRepo.addBooking({
        status: "ok",
        ...payload,
      });

      showSuccess(i18n.t("booking.book-success"));
      return booking;
    } catch (e) {
      showError(getUserMessage(e) || i18n.t("booking.add-failed"));
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const removeBooking = createAsyncThunk<Booking, Booking>(
  "api/removeBooking",
  async (payload, thunkAPI) => {
    try {
      await bookingRepo.deleteBooking(payload);
      showSuccess(i18n.t("booking.remove-success"));
      return payload;
    } catch (e) {
      showError(getUserMessage(e) || i18n.t("booking.remove-failed"));
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const switchBookingDesk = createAsyncThunk<
  Booking,
  { bookingId: number; deskId: number }
>("api/switchBookingDesk", async (payload, thunkAPI) => {
  try {
    const booking = (thunkAPI.getState() as RootState).bookings.find(
      b => b.id === payload.bookingId
    );
    if (booking) {
      if (booking?.deskId !== payload.deskId) {
        const updated = await bookingRepo.saveBooking({
          ...booking,
          deskId: payload.deskId,
        });

        showSuccess(i18n.t("desk.switch-success"));
        return updated;
      } else {
        return Promise.resolve(booking);
      }
    } else {
      return thunkAPI.rejectWithValue("Can't retrieve booking");
    }
  } catch (e) {
    showError(getUserMessage(e) || i18n.t("booking.switch-failed"));
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const refreshBookings = createAsyncThunk<Booking[], { weekFirstDay?: string }>(
  "api/refreshBookings",
  async (payload, thunkAPI) => {
    const weekFirstDay =
      payload.weekFirstDay || (thunkAPI.getState() as RootState).weekDays[0];
    return bookingRepo.getBookings(weekFirstDay);
  }
);

/********************************
 *  Floor related actions
 ********************************/

export const saveDesks = createAsyncThunk<
  Desk[],
  { floorPlanId: number; desks: Optional<Desk, "id">[] }
>("api/saveDesks", async (payload, thunkAPI) => {
  try {
    const desks = await floorPlanRepo.saveDesks(payload.floorPlanId, payload.desks);
    showSuccess(i18n.t("desk.save-success"));

    thunkAPI.dispatch(refreshFloorPlans());

    return desks;
  } catch (e) {
    showError(i18n.t("desk.save-error"));
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const refreshBuildings = createAsyncThunk<Building[]>(
  "api/refreshBuildings",
  async () => {
    return buildingRepo.get("/");
  }
);

export const refreshFloorPlans = createAsyncThunk<FloorPlan[]>(
  "api/refreshFloorPlans",
  async () => {
    return floorPlanRepo.get("/");
  }
);

export const saveBuilding = createAsyncThunk<Building, Optional<Building, "id">>(
  "api/saveBuilding",
  async (payload, thunkAPI) => {
    try {
      let building;
      if (payload.id) {
        building = await buildingRepo.put(payload.id, payload);
      } else {
        building = await buildingRepo.post("/", payload);
      }

      showSuccess(i18n.t("building.save-success"));

      thunkAPI.dispatch(refreshBuildings());

      return building;
    } catch (e) {
      showError(i18n.t("building.save-error"));
      thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const saveFloor = createAsyncThunk<Floor, Optional<Floor, "id">>(
  "api/saveFloor",
  async (payload, thunkAPI) => {
    try {
      const floor = await buildingRepo.saveFloor(payload);

      showSuccess(i18n.t("building.save-floor-success"));

      thunkAPI.dispatch(refreshBuildings());

      return floor;
    } catch (e) {
      showError(i18n.t("building.save-floor-error"));
      thunkAPI.rejectWithValue(e.message);
    }
  }
);
