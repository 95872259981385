import React, { Key, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Collaborator } from "../../../../domain/collaborator/Collaborator";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
  Upload,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import PicName from "../../../component/pic-name/PicName";
import {
  DownloadOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  UploadOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import {
  refreshCollaborators,
  saveCollaborator,
  saveMultipleCollaborator,
} from "../../../redux/collaborator/actions";
import { useOrganization } from "../../../hook/hooks";
import CommunityTag from "../../../component/people-tag/PeopleTag";
import { RootState } from "../../../redux/state";
import { UploadChangeParam } from "antd/lib/upload/interface";
import {
  showError,
  showSuccess,
  showWarning,
} from "../../../../domain/notification/NotificationService";
import DateTime from "../../../../domain/datetime/DateTime";
import LastLoginDisplay from "./LastLoginDisplay";

const defaultCollab: Partial<Collaborator> = {
  status: "enabled",
  role: "collaborator",
  owned: true,
};

export default () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const org = useOrganization();
  const { communities } = org;

  const allCollaborators = useSelector<RootState, Collaborator[]>(s => s.collaborators);

  const [collaborators, setCollaborators] = useState<Collaborator[]>(allCollaborators);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);
  const [search, setSearch] = useState<string>("");
  const [editingCollaborator, setEditingCollaborator] = useState<
    Partial<Collaborator & { community: string }> | undefined
  >(undefined);
  const [uploading, setUploading] = useState<boolean>(false);

  const confirmSuspend = useCallback(
    (onOk: () => void, onCancel?: () => void) => {
      Modal.confirm({
        title: t("collaborator.suspend-confirm"),
        icon: <ExclamationCircleOutlined />,
        content: t("collaborator.suspend-desc"),
        onOk,
        onCancel,
      });
    },
    [t]
  );

  const onStartEditing = useCallback((collab?: Collaborator) => {
    setEditingCollaborator(collab ? { ...collab } : { ...defaultCollab });
  }, []);

  const onCancelEditing = useCallback(() => {
    setEditingCollaborator(undefined);
  }, []);

  const onSave = useCallback(
    (val: Collaborator) => {
      if (!editingCollaborator) return;

      if (editingCollaborator.status === "enabled" && val.status === "suspended") {
        confirmSuspend(
          () => {
            dispatch(saveCollaborator({ ...editingCollaborator, ...val }));
            onCancelEditing();
          },
          () => onCancelEditing()
        );
      } else {
        dispatch(saveCollaborator({ ...editingCollaborator, ...val }));
        onCancelEditing();
      }
    },
    [editingCollaborator, confirmSuspend, dispatch, onCancelEditing]
  );

  const onMultipleApply = useCallback(
    data => {
      const communityId = data.communityId ? Number(data.communityId) : undefined;
      const status = data.status && data.status !== "0" ? data.status : undefined;
      const role = data.role && data.role !== "0" ? data.role : undefined;

      const onOk = () => {
        dispatch(
          saveMultipleCollaborator(
            allCollaborators
              .filter(c => selectedKeys.includes(c.id))
              .map(collab => ({
                ...collab,
                communityId: communityId ? communityId : collab.communityId,
                status: status ? status : collab.status,
                role: role ? role : collab.role,
              }))
          )
        );
      };

      if (status && status === "suspended") {
        confirmSuspend(onOk);
      } else {
        onOk();
      }

      setSelectedKeys([]);
    },
    [allCollaborators, confirmSuspend, dispatch, selectedKeys]
  );

  const refreshSearchCollaborators = useCallback(() => {
    if (search) {
      const re = new RegExp(`.*${search}.*`, "i");

      setCollaborators(
        allCollaborators.filter(collab => re.test(collab.name) || re.test(collab.email))
      );
    } else {
      setCollaborators(allCollaborators);
    }
  }, [allCollaborators, search]);

  const onUploadChange = useCallback(
    (info: UploadChangeParam) => {
      if (info.file.status === "done") {
        if (info.file.response.data?.ignored > 0) {
          showWarning(
            t("collaborator.import-csv-warning-ignored", {
              count: info.file.response.data?.ignored,
            })
          );
        } else {
          showSuccess(t("collaborator.import-csv-success"));
        }

        dispatch(refreshCollaborators());
        setUploading(false);
      } else if (info.file.status === "error") {
        showError(t("collaborator.import-csv-error"));
        setUploading(false);
      } else {
        setUploading(true);
      }
    },
    [dispatch, t]
  );

  const onClickExport = useCallback(() => {
    window.open("/api/collaborators/csv");
  }, []);

  // Trigger refresh only if allCollaborators reference or search change
  useEffect(() => {
    refreshSearchCollaborators();
    // TODO @Billy
  }, [allCollaborators, search]);

  return (
    <div>
      <Row>
        <Col span={18} offset={2}>
          {selectedKeys.length === 0 && (
            <>
              <Button
                icon={<UserAddOutlined />}
                onClick={() => onStartEditing()}
                type="primary"
              >
                {t("main.add")}
              </Button>

              <Tooltip title={t("collaborator.export-csv")}>
                <Button icon={<DownloadOutlined />} onClick={onClickExport}>
                  {t("main.export")}
                </Button>
              </Tooltip>

              <Upload
                beforeUpload={f => {
                  if (f.name.toLowerCase().endsWith(".csv")) {
                    return true;
                  } else {
                    showError(t("collaborator.import-csv-error-format"));
                    return Upload.LIST_IGNORE;
                  }
                }}
                onChange={onUploadChange}
                multiple={false}
                showUploadList={false}
                action="/api/collaborators/csv"
              >
                <Tooltip title={t("collaborator.import-csv")}>
                  <Button icon={uploading ? <LoadingOutlined /> : <UploadOutlined />}>
                    {t("main.import")}
                  </Button>
                </Tooltip>
              </Upload>
            </>
          )}

          {selectedKeys.length > 0 && (
            <Form layout="inline" onFinish={data => onMultipleApply(data)}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {t("main.apply") + " (" + selectedKeys.length + ")"}
                </Button>
              </Form.Item>

              <Form.Item name="communityId" label={t("main.community")}>
                <Select dropdownMatchSelectWidth={false}>
                  <Select.Option key="-1" value="">
                    &nbsp;
                  </Select.Option>
                  <Select.Option key="0" value="0">
                    <CommunityTag />
                  </Select.Option>
                  {communities.map(c => (
                    <Select.Option key={c.id} value={c.id}>
                      <CommunityTag community={c} />
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="status" label={t("main.status")}>
                <Select>
                  <Select.Option key="0" value="">
                    &nbsp;
                  </Select.Option>
                  <Select.Option value="enabled">
                    {t("collaborator.status.enabled")}
                  </Select.Option>
                  <Select.Option value="suspended">
                    {t("collaborator.status.suspended")}
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item name="role" label={t("main.role")}>
                <Select>
                  <Select.Option key="0" value="">
                    &nbsp;
                  </Select.Option>
                  <Select.Option value="collaborator">
                    {t("collaborator.role.collaborator")}
                  </Select.Option>
                  <Select.Option value="admin">
                    {t("collaborator.role.admin")}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Form>
          )}
        </Col>
        <Col span={2}>
          <Input
            onChange={e => setSearch(e.target.value)}
            allowClear
            placeholder={t("main.search")}
          />
        </Col>
      </Row>

      <Row>
        <Col span={20} offset={2}>
          <Table<Collaborator>
            dataSource={collaborators}
            rowKey="id"
            size="small"
            rowSelection={{
              selectedRowKeys: selectedKeys,
              onChange: keys => setSelectedKeys(keys),
            }}
          >
            <Table.Column<Collaborator>
              title={t("main.name")}
              key="name"
              render={(_text, record) => <PicName collaborator={record} />}
              sorter={{
                compare: (a, b) =>
                  a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
                multiple: 1,
              }}
              defaultSortOrder="ascend"
              onCellClick={record => onStartEditing(record)}
            />

            <Table.Column title={t("main.email")} key="email" dataIndex="email" />

            <Table.Column<Collaborator>
              title={t("main.community")}
              key="communityId"
              sorter={{
                compare: (a, b) => {
                  const cA = org.findCommunityById(a.communityId);
                  const cB = org.findCommunityById(b.communityId);

                  if (cA && cB) return org.compareCommunity(cA, cB);
                  if (cA && !cB) return -1;
                  if (!cA && cB) return 1;

                  return 0;
                },
                multiple: 2,
              }}
              filters={org.communities.map(c => ({ text: c.name, value: c.id }))}
              onFilter={(value, record) => record.communityId === value}
              render={(_text, record) => (
                <CommunityTag community={org.findCommunityById(record.communityId)} />
              )}
            />

            <Table.Column<Collaborator>
              title={t("main.status")}
              key="status"
              filters={[
                { value: "enabled", text: t("collaborator.status.enabled") },
                { value: "suspended", text: t("collaborator.status.suspended") },
              ]}
              onFilter={(value, record) => record.status === value}
              render={record => t("collaborator.status." + record.status)}
            />

            <Table.Column<Collaborator>
              title={t("main.role")}
              key="role"
              filters={[
                { value: "collaborator", text: t("collaborator.role.collaborator") },
                { value: "admin", text: t("collaborator.role.admin") },
              ]}
              onFilter={(value, record) => record.role === value}
              render={record => t("collaborator.role." + record.role)}
            />

            <Table.Column<Collaborator>
              title={t("collaborator.last-login")}
              key="lastLoginDate"
              sorter={{
                compare: (a, b) => {
                  if (!a.lastLoginDate && !b.lastLoginDate) {
                    return 0;
                  }

                  if (a.lastLoginDate && b.lastLoginDate) {
                    return DateTime.compare(a.lastLoginDate, b.lastLoginDate);
                  }

                  if (a.lastLoginDate && !b.lastLoginDate) return -1;
                  if (!a.lastLoginDate && b.lastLoginDate) return 1;

                  return 0;
                },
              }}
              render={record => <LastLoginDisplay date={record.lastLoginDate} />}
            />
            <Table.Column
              key="action"
              render={record => (
                <a onClick={() => onStartEditing(record)} title={t("main.edit")}>
                  <EditOutlined />
                </a>
              )}
            />
          </Table>
        </Col>
      </Row>

      <Modal
        title={t("collaborator.edit-title")}
        visible={!!editingCollaborator}
        onCancel={() => onCancelEditing()}
        destroyOnClose={true}
        footer={null}
      >
        {editingCollaborator && (
          <Form
            onFinish={val => onSave(val)}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={editingCollaborator}
          >
            <Form.Item
              name="name"
              label={t("main.name")}
              rules={[{ required: true, message: t("form.required-field") }]}
            >
              <Input disabled={!editingCollaborator.owned} />
            </Form.Item>

            <Form.Item
              name="email"
              label={t("main.email")}
              rules={[
                { required: true, message: t("form.required-field") },
                {
                  validateTrigger: "onBlur",
                  type: "email",
                  message: t("form.invalid-email-format"),
                },
              ]}
            >
              <Input disabled={!editingCollaborator.owned} />
            </Form.Item>

            <Form.Item name="communityId" label={t("main.community")}>
              <Select>
                <Select.Option key="0" value="">
                  <CommunityTag />
                </Select.Option>
                {communities.map(c => (
                  <Select.Option key={c.id} value={c.id}>
                    <CommunityTag community={c} />
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="status"
              label={t("main.status")}
              rules={[{ required: true, message: t("form.required-field") }]}
            >
              <Select>
                <Select.Option value="enabled">
                  {t("collaborator.status.enabled")}
                </Select.Option>
                <Select.Option value="suspended">
                  {t("collaborator.status.suspended")}
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="role"
              label={t("main.role")}
              rules={[{ required: true, message: t("form.required-field") }]}
            >
              <Select>
                <Select.Option value="collaborator">
                  {t("collaborator.role.collaborator")}
                </Select.Option>
                <Select.Option value="admin">
                  {t("collaborator.role.admin")}
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button key="save" type="primary" htmlType="submit">
                {t("main.save")}
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </div>
  );
};
