import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useDispatch } from "react-redux";
import { saveWorkspace } from "../redux/actions";

import WorkspaceRestRepo from "../../domain/workspace/WorkspaceRestRepo";
import { ReminderOn } from "../../domain/workspace/Workspace";
import { showError, showSuccess } from "../../domain/notification/NotificationService";
import { useWorkspace } from "./hooks";

type FormData = {
  emailOnboardingEnabled: boolean;
  emailReminderEnabled: boolean;
  weekDay: ReminderOn["weekDay"];
  hour: ReminderOn["hour"];
};

const workspaceRestRepo = new WorkspaceRestRepo();

const useEmailNotificationSettings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const workspace = useWorkspace();

  // state
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    emailOnboardingEnabled: workspace.emailOnboardingEnabled,
    emailReminderEnabled: workspace.emailReminderEnabled,
    weekDay: workspace.emailReminderSetting?.weekDay
      ? workspace.emailReminderSetting?.weekDay
      : 1,
    hour: workspace.emailReminderSetting?.hour ? workspace.emailReminderSetting?.hour : 8,
  };

  const onSave = (formData: FormData) => {
    const fields = {
      emailOnboardingEnabled: formData.emailOnboardingEnabled,
      emailReminderEnabled: formData.emailReminderEnabled,
      emailReminderSetting: {
        weekDay: formData.weekDay,
        hour: formData.hour,
      },
    };

    dispatch(saveWorkspace(fields));
  };

  const triggerSendOnboardingEmail = async () => {
    setIsLoading(true);
    try {
      await workspaceRestRepo.sendEmailOnBoarding(workspace.id);
      showSuccess(t("workspace.success-email-onboarding"));
    } catch (e) {
      showError(t("workspace.error-email-onboarding"));
    }

    setIsLoading(false);
  };

  return {
    onSave,
    triggerSendOnboardingEmail,
    isLoading,
    initialValues,
  };
};

export default useEmailNotificationSettings;
