import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Divider, InputNumber, Select, Space, Tooltip } from "antd";
import {
  AlignLeftOutlined,
  AlignRightOutlined,
  CheckOutlined,
  CopyOutlined,
  PlusOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SelectOutlined,
  StopOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignTopOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import { Desk, FloorPlan } from "../../../../domain/floorPlan/FloorPlan";
import {
  EditorDesk,
  useAddNewDesk,
  useAlign,
  useAssignStatus,
  useAssignZone,
  useCopy,
  useRotate,
  useSize,
} from "./hook/tools";

import "./floor-editor.less";
import { Zone } from "../../../../types";
import { useDispatch } from "react-redux";
import { saveDesks } from "../../../redux/actions";
import DeskIcon from "../DeskShape";
import FloorSelection from "./FloorSelection";
import { useTranslation } from "react-i18next";
import md5 from "md5";
import CommunityTag from "../../people-tag/PeopleTag";
import { useOrganization } from "../../../hook/hooks";

export type Tool = "resize" | "move";
export type Point = { x: number; y: number };

export type Action = {
  tool: Tool;
  startActionPoint: { x: number; y: number };
  selection: string[];
  startDesk: EditorDesk[];
};

const deskToEditorDesks = (desks: Desk[] = []) => {
  const copies: EditorDesk[] = [];
  for (const desk of desks) copies.push({ ...desk, hash: md5(`${desk.id}`) });
  return copies;
};

export const stopEvent = (e: React.MouseEvent) => {
  e.stopPropagation();
  e.preventDefault();
};

const FpDesk = (props: {
  onClick: (e: React.MouseEvent) => void;
  desk: EditorDesk;
  zoom: number;
  zones: Zone[];
}) => {
  const { desk, zones, onClick, zoom } = props;

  const zone = zones.find(z => z.id === desk.zoneId);
  const color = zone?.color || "#ccc";

  return (
    <div
      className="floor-desk"
      onClick={onClick}
      style={{
        top: (desk.cy - desk.h / 2) * zoom,
        left: (desk.cx - desk.w / 2) * zoom,
        width: desk.w * zoom,
        height: desk.h * zoom,
        transform: `rotate(${desk.rot}deg)`,
      }}
    >
      <DeskIcon
        width={desk.w * zoom}
        height={desk.h * zoom}
        striped={desk.disabled}
        color={color}
      />
    </div>
  );
};

export const FloorEditor = (props: { floorplan: FloorPlan; zones: Zone[] }) => {
  const { floorplan, zones } = props;

  const dispatch = useDispatch();
  const org = useOrganization();

  const [desks, setDesks] = useState(deskToEditorDesks(floorplan.desks));
  const [width, setWidth] = useState<number | undefined>(undefined);
  const [height, setHeight] = useState<number | undefined>(undefined);
  const [rotation, setRotation] = useState<number | undefined>(undefined);

  const [zoom, setZoom] = useState(1);
  const [selection, setSelection] = useState(Array<string>());
  const [zone, setZone] = useState<number | undefined>();
  const selected = selection.length > 0;
  const [action, setAction] = useState<Action | null>(null);

  const [canvas, setCanvas] = useState({ x: 0, y: 0, w: 0, h: 0 });
  const canvasRef = useRef<HTMLDivElement>(null);
  const viewportRef = useRef<HTMLDivElement>(null);

  // Tools
  const rotate = useRotate(desks, setDesks, selection);
  const align = useAlign(desks, setDesks, selection);
  const size = useSize(desks, setDesks, selection);
  const assignZone = useAssignZone(desks, setDesks, selection);
  const assignStatus = useAssignStatus(desks, selection, setDesks);
  const copy = useCopy(desks, setDesks, selection, setSelection);
  const addNewDesk = useAddNewDesk(
    floorplan.wid,
    floorplan.id,
    desks,
    setDesks,
    setSelection
  );

  // When floor change
  useEffect(() => {
    // Set desks
    setDesks(deskToEditorDesks(floorplan.desks));

    // Adapt zoom
    const viewportElt = viewportRef.current;
    if (viewportElt && floorplan) {
      const viewportWidth = viewportElt.getBoundingClientRect().width;
      setZoom((viewportWidth / floorplan.width) * 0.9);
    }
  }, [floorplan]);

  // Set canvas coords/dimension
  useEffect(() => {
    if (canvasRef.current) {
      const canvasElt = canvasRef.current.getBoundingClientRect();
      setCanvas({
        x: canvasElt.x,
        y: canvasElt.y,
        w: floorplan.width * zoom,
        h: floorplan.height * zoom,
      });
    }
  }, [floorplan, zoom]);

  const coords = useCallback(
    (e: React.MouseEvent): Point => {
      const x = (e.pageX - canvas.x) / zoom,
        y = (e.pageY - canvas.y) / zoom;

      return { x, y };
    },
    [canvas.x, canvas.y, zoom]
  );

  const select = useCallback(
    (arg: "all" | string[]) => {
      if (arg === "all") {
        if (selection.length === desks.length) {
          // Unselect all
          setSelection([]);
        } else {
          // Select all
          setSelection(desks.map(d => d.hash));
        }
      } else {
        setSelection(arg);

        if (arg.length === 1) {
          const desk = desks.find(d => d.hash === arg[0]);

          setWidth(desk?.w);
          setHeight(desk?.h);
          setRotation(desk?.rot);
          setZone(desk?.zoneId);
        } else {
          setWidth(undefined);
          setHeight(undefined);
          setRotation(undefined);
          setZone(undefined);
        }
      }
    },
    [desks, selection]
  );

  const onZoneChange = useCallback(
    (zoneId: number | undefined) => {
      setZone(zoneId);
      if (zoneId) assignZone(zoneId);
    },
    [assignZone]
  );

  const onClickSave = useCallback(() => {
    dispatch(saveDesks({ floorPlanId: floorplan.id, desks }));
  }, [desks, dispatch, floorplan.id]);

  const onClickZoom = useCallback(
    (io: number) => {
      const z = io > 0 ? zoom * 1.1 : zoom / 1.1;
      setZoom(z);

      if (floorplan) {
        const root = document.getElementById("svg-desks");
        if (root) {
          root.setAttribute("width", `${floorplan.width * z}`);
          root.setAttribute("height", `${floorplan.height * z}`);
        }
      }
    },
    [floorplan, zoom]
  );

  const onClickDesk = useCallback(
    (e: React.MouseEvent, deskHash: string) => {
      if (!selection.includes(deskHash)) {
        select([...selection, deskHash]);
      }

      e.preventDefault();
      e.stopPropagation();
    },
    [select, selection]
  );

  const onMouseDownSelection = useCallback(
    (e: React.MouseEvent, tool: Tool) => {
      setAction({
        tool,
        startActionPoint: coords(e),
        selection,
        startDesk: desks.filter(d => selection.includes(d.hash)).map(d => ({ ...d })),
      });

      e.preventDefault();
      e.stopPropagation();
    },
    [coords, desks, selection]
  );

  const onMouseUpSelection = useCallback(
    (e: React.MouseEvent) => {
      setAction(null);

      e.stopPropagation();
      e.preventDefault();
    },
    [setAction]
  );

  const onMouseClickUpBackground = useCallback((e: React.MouseEvent) => {
    // Unselect desks
    select([]);
    setAction(null);
    e.stopPropagation();
    e.preventDefault();
  }, []);

  const onMouseMoveCanvas = useCallback(
    (e: React.MouseEvent) => {
      if (!action) return;

      const p = coords(e);

      // Action delta
      const dx = p.x - action.startActionPoint.x;
      const dy = p.y - action.startActionPoint.y;

      desks
        .filter(d => action.selection.includes(d.hash))
        .forEach((desk, idx) => {
          const deskStart = action.startDesk[idx];

          if (action.tool === "move") {
            desk.cx = deskStart.cx + dx;
            desk.cy = deskStart.cy + dy;
          } else if (action.tool === "resize") {
            desk.w = deskStart.w + dx * 2;
            desk.h = deskStart.h - dy * 2;
          }
        });

      setDesks([...desks]);

      e.preventDefault();
      e.stopPropagation();
    },
    [action, coords, desks]
  );

  const { t } = useTranslation();

  return (
    <div className="floor floor-editor">
      {/* Toolbar */}
      <div className="floor-toolbar">
        <Space>
          <Tooltip title={t("floor-editor.zoom")}>
            <Button.Group>
              <Button icon={<ZoomInOutlined />} onClick={() => onClickZoom(1)} />
              <Button icon={<ZoomOutOutlined />} onClick={() => onClickZoom(-1)} />
            </Button.Group>
          </Tooltip>

          <Tooltip title={t("floor-editor.add-desk")}>
            <Button icon={<PlusOutlined />} onClick={addNewDesk} />
          </Tooltip>

          <Tooltip title={t("floor-editor.select-desk")}>
            <Button icon={<SelectOutlined />} onClick={() => select("all")} />
          </Tooltip>

          <Tooltip title={t("floor-editor.copy")}>
            <Button icon={<CopyOutlined />} onClick={copy} disabled={!selected} />
          </Tooltip>

          <Tooltip title={t("floor-editor.set-rotation")}>
            <Button.Group>
              <InputNumber
                onChange={v => rotate(Number(v))}
                value={rotation}
                style={{ width: 100 }}
                placeholder={t("floor-editor.rotation")}
                disabled={!selected}
              />
              <Button
                icon={<RotateRightOutlined />}
                onClick={() => rotate(5, true)}
                disabled={!selected}
              />
              <Button
                icon={<RotateLeftOutlined />}
                onClick={() => rotate(-5, true)}
                disabled={!selected}
              />
            </Button.Group>
          </Tooltip>

          <Tooltip title={t("floor-editor.set-width")}>
            <InputNumber
              onChange={v => size({ width: Number(v) })}
              value={width}
              placeholder={t("floor-editor.width")}
              disabled={!selected}
            />
          </Tooltip>

          <Tooltip title={t("floor-editor.set-height")}>
            <InputNumber
              onChange={v => size({ height: Number(v) })}
              value={height}
              placeholder={t("floor-editor.height")}
              disabled={!selected}
            />
          </Tooltip>

          <Tooltip title={t("floor-editor.align-desk")}>
            <Button.Group>
              <Button
                icon={<VerticalAlignTopOutlined />}
                onClick={() => align("top")}
                disabled={!selected}
              />
              <Button
                icon={<VerticalAlignBottomOutlined />}
                onClick={() => align("bottom")}
                disabled={!selected}
              />
              <Button
                icon={<AlignLeftOutlined />}
                onClick={() => align("left")}
                disabled={!selected}
              />
              <Button
                icon={<AlignRightOutlined />}
                onClick={() => align("right")}
                disabled={!selected}
              />
            </Button.Group>
          </Tooltip>

          <Divider type="vertical" />

          {/* Zone and Enable */}
          <Tooltip title={t("floor-editor.assign-zone")}>
            <Button.Group>
              <Select
                value={zone}
                onChange={onZoneChange}
                placeholder={t("main.zone")}
                disabled={!selected}
              >
                <Select.Option key={-1} value={-1}>
                  <div className="zone-badge" />
                  {t("floor-editor.unassigned")}
                </Select.Option>

                {zones.map(z => (
                  <Select.Option key={z.id} value={z.id}>
                    <CommunityTag community={org.findCommunityById(z.id)} />
                  </Select.Option>
                ))}
              </Select>
            </Button.Group>
          </Tooltip>

          <Tooltip title={t("floor-editor.enable-desk")}>
            <Button.Group>
              <Button
                icon={<CheckOutlined />}
                onClick={() => assignStatus("enabled")}
                disabled={!selected}
              />
              <Button
                icon={<StopOutlined />}
                onClick={() => assignStatus("disabled")}
                disabled={!selected}
              />
            </Button.Group>
          </Tooltip>

          <Divider type="vertical" />

          <Button onClick={onClickSave} type="primary">
            {t("floor-editor.save")}
          </Button>
        </Space>
      </div>

      <div
        className="floor-viewport"
        ref={viewportRef}
        onClick={onMouseClickUpBackground}
      >
        <div
          ref={canvasRef}
          className="floor-canvas"
          style={{ width: canvas.w, height: canvas.h }}
          onMouseMove={onMouseMoveCanvas}
          onClick={onMouseClickUpBackground}
        >
          {/* Floor Layer */}
          {floorplan && (
            <div className="floor-background" draggable={false}>
              <img
                width={canvas.w}
                height={canvas.h}
                src={floorplan.url}
                draggable={false}
              />
            </div>
          )}

          {/* Desk layer */}
          {desks.map(desk => (
            <FpDesk
              key={desk.hash}
              desk={desk}
              zoom={zoom}
              zones={zones}
              onClick={e => onClickDesk(e, desk.hash)}
            />
          ))}

          {/* Selection layer */}
          {desks
            .filter(d => selection.includes(d.hash))
            .map(desk => (
              <FloorSelection
                key={desk.hash}
                desk={desk}
                zoom={zoom}
                onMouseDown={e => onMouseDownSelection(e, "move")}
                onMouseUp={onMouseUpSelection}
                onMouseDownResize={e => onMouseDownSelection(e, "resize")}
              />
            ))}
        </div>
      </div>
    </div>
  );
};
