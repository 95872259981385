import React from "react";
import { useTranslation } from "react-i18next";
import DateTime from "../../../../domain/datetime/DateTime";

type Props = {
  date?: string;
};

export default ({ date }: Props) => {
  const { t } = useTranslation();

  if (!date) return <div>{t("collaborator.never-connected")}</div>;

  const days = DateTime.getDaysDiffFromToday(date);

  return (
    <div>
      {days === 0 && t("main.today")}
      {days === 1 && t("collaborator.one-day-ago")}
      {days > 1 && days < 7 && t("collaborator.days-ago", { days })}
      {days >= 7 && days < 14 && t("collaborator.one-week-ago")}
      {days >= 14 && t("collaborator.more-than-two-weeks")}
    </div>
  );
};
