import React from "react";
import { useImageURL } from "../../hook/hooks";
import { Collaborator } from "../../../domain/collaborator/Collaborator";

import "./pic-name.less";

export default (props: { collaborator: Collaborator }) => {
  const imageURL = useImageURL(props.collaborator.picturePath);

  return (
    <div className="pic-name">
      <div className="img-wrap">
        <img src={imageURL} />
      </div>
      {props.collaborator.name}
    </div>
  );
};
