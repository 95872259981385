import React, { useCallback, useState } from "react";
import { Button, DatePicker, Space } from "antd";
import { Moment } from "moment";
import { DownloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;

export default () => {
  const [period, setPeriod] = useState({ start: "", end: "" });

  const onPeriodChange = (values: [Moment | null, Moment | null] | null) => {
    const start =
      values && values?.length > 0 && values[0] ? values[0].format("YYYY-MM-DD") : "";
    const end =
      values && values?.length > 1 && values[1] ? values[1].format("YYYY-MM-DD") : "";

    setPeriod({ start, end });
  };

  const onClickExport = useCallback(() => {
    window.open(`/api/bookings/export?start=${period.start}&end=${period.end}`);
  }, [period]);

  const { t } = useTranslation();

  return (
    <div>
      <p>{t("export.content")}</p>

      <Space>
        <RangePicker format="yyyy-MM-DD" onChange={range => onPeriodChange(range)} />

        <Button icon={<DownloadOutlined />} onClick={onClickExport} type="primary">
          {t("main.export")}
        </Button>
      </Space>
    </div>
  );
};
