import RestRepository from "../../infra/RestRepository";

export default class SlackRepo extends RestRepository {
  constructor() {
    super("/slack");
  }

  async getInstallUrl(wid: number, aid: number): Promise<string> {
    const data = await this.get(`/install-url?wid=${wid}&aid=${aid}`);
    return data.url;
  }

  async uninstallApp(token: string, slackUserId: string, wid: number): Promise<string> {
    const data = await this.get(
      `/uninstall?slackUserId=${slackUserId}&wid=${wid}&token=${token}`
    );
    return data;
  }
}
