import React, { PropsWithChildren } from "react";

import "./pan-section.less";

export default (props: PropsWithChildren<any> & { title: string }) => {
  return (
    <div className="pan-section" {...props}>
      <div className="pan-section-title">{props.title}</div>
      <div className="pan-section-content">{props.children}</div>
    </div>
  );
};
