import React, { useCallback, useEffect, useState } from "react";
import { Button, Space, Tooltip } from "antd";
import { ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
import { Desk, FloorPlan } from "../../../../domain/floorPlan/FloorPlan";
import { useSelector } from "react-redux";
import { Booking } from "../../../../domain/booking/Booking";
import FloorViewerController from "./FloorViewerController";

import "./floor-viewer.less";
import scrollIntoView from "scroll-into-view";
import FloorBookingDesk from "./FloorBookingDesk";
import { useZones } from "../../../hook/hooks";
import { RootState } from "../../../redux/state";

export type Point = { x: number; y: number };

export const FloorViewer = (props: {
  floorPlan: FloorPlan;
  controller: FloorViewerController;
}) => {
  const { floorPlan, controller } = props;
  const desks = floorPlan.desks;

  const day = useSelector<RootState, string>(s => s.selectedDay);
  const zones = useZones();
  const bookings = useSelector<RootState, Booking[]>(s => s.bookings).filter(
    b => b.date === day && b.type === "office" && b.deskId
  );

  const highlightedCollaboratorIds = useSelector<RootState, number[]>(
    s => s.highlightedCollaborators
  );

  const deskWithBooking: { desk: Desk; booking?: Booking }[] = desks.map(desk => ({
    desk,
    booking: bookings.find(b => b.deskId === desk.id),
  }));

  const [zoom, setZoom] = useState(1);
  const [canvas, setCanvas] = useState({
    w: floorPlan.width * zoom,
    h: floorPlan.height * zoom,
  });

  // Scroll to highlighted person
  useEffect(() => {
    if (highlightedCollaboratorIds?.length === 1) {
      const booking = bookings.find(
        b => b.collaboratorId === highlightedCollaboratorIds[0]
      );
      if (booking) {
        const bookingElt = document.getElementById(`booking-${booking.id}`);
        if (bookingElt) scrollIntoView(bookingElt);
      }
    }
  }, [highlightedCollaboratorIds]);

  // Adapt zoom on change floor
  useEffect(() => {
    if (floorPlan) {
      const widthAvg = floorPlan.desks.length
        ? floorPlan.desks.reduce((acc, d) => acc + d.w, 0) / floorPlan.desks.length
        : 50;
      const z = 50 / widthAvg;
      setZoom(z);
    }
  }, [floorPlan]);

  useEffect(() => {
    setCanvas({ ...canvas, w: floorPlan.width * zoom, h: floorPlan.height * zoom });
  }, [floorPlan, zoom]);

  const onClickZoom = useCallback(
    (io: number) => {
      const z = io > 0 ? zoom * 1.1 : zoom / 1.1;
      setZoom(z);
    },
    [zoom]
  );

  return (
    <div className="floor floor-viewer">
      {/* Toolbar */}
      <div className="floor-toolbar">
        <Tooltip title="Zoom">
          <Space>
            <Button
              icon={<ZoomInOutlined />}
              onClick={() => onClickZoom(1)}
              size="large"
            />
            <Button
              icon={<ZoomOutOutlined />}
              onClick={() => onClickZoom(-1)}
              size="large"
            />
          </Space>
        </Tooltip>
      </div>

      <div className="floor-viewport">
        <div className="floor-canvas" style={{ width: canvas.w, height: canvas.h }}>
          {/* Floor Layer */}
          {floorPlan && (
            <div className="floor-background" draggable={false}>
              <img
                width={canvas.w}
                height={canvas.h}
                src={floorPlan.url}
                draggable={false}
              />
            </div>
          )}

          {/* Desk & Booking layer */}
          {deskWithBooking.map(dwb => (
            <FloorBookingDesk
              key={dwb.desk.id}
              desk={dwb.desk}
              zoom={zoom}
              zones={zones}
              controller={controller}
              booking={dwb.booking}
              highlighted={
                dwb.booking &&
                highlightedCollaboratorIds.includes(dwb.booking.collaboratorId)
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};
