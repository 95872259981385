import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useOrganization } from "../../../hook/hooks";
import React, { useCallback, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import { DeleteOutlined, EditOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import CommunityTag from "../../../component/people-tag/PeopleTag";
import { CirclePicker } from "react-color";
import { Community } from "../../../../domain/community/Community";
import { deleteCommunity, saveCommunity } from "../../../redux/community/actions";
import { Optional } from "../../../../types";

export default () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const org = useOrganization();
  const { communities } = org;

  const [editingCommunity, setEditingCommunity] = useState<
    Partial<Community> | undefined
  >(undefined);

  const onStartEditing = useCallback(
    (community?: Community) => {
      const c = community ? { ...community } : {};
      form.setFieldsValue(c);
      setEditingCommunity(c);
    },
    [form]
  );

  const onCancelEditing = useCallback(() => {
    form.resetFields();
    setEditingCommunity(undefined);
  }, [form]);

  const onColorChange = useCallback(
    (color: string) => {
      if (!editingCommunity) return;

      form.setFieldsValue({ color });

      const updated = { ...editingCommunity, color };
      setEditingCommunity(updated);
    },
    [editingCommunity, form]
  );

  const onSave = useCallback(
    (data: Partial<Community>) => {
      dispatch(
        saveCommunity({ ...editingCommunity, ...data } as Optional<
          Community,
          "id" | "wid"
        >)
      );
      onCancelEditing();
    },
    [dispatch, editingCommunity, onCancelEditing]
  );

  const onDelete = useCallback(
    (record: Community) => {
      dispatch(deleteCommunity(record.id));
    },
    [dispatch]
  );

  return (
    <div>
      <Row>
        <Col span={20} offset={2}>
          <Button
            icon={<UsergroupAddOutlined />}
            onClick={() => onStartEditing()}
            type="primary"
          >
            {t("main.add")}
          </Button>
        </Col>
      </Row>

      <Row>
        <Col span={20} offset={2}>
          <Table<Community> dataSource={communities} rowKey="id" size="small">
            <Table.Column<Community>
              title={t("main.name")}
              key="name"
              render={(_text, record) => <CommunityTag community={record} />}
              sorter={(a, b) => org.compareCommunity(a, b)}
              defaultSortOrder="ascend"
            />

            <Table.Column
              title={t("community.member-count")}
              key="memberCount"
              render={(_text, record: Community) =>
                org.findEnabledCollaboratorsByCommunityId(record.id).length
              }
            />

            <Table.Column
              key="action"
              render={record => (
                <Space direction="horizontal">
                  <a
                    key="edit"
                    onClick={() => onStartEditing(record)}
                    title={t("main.edit")}
                  >
                    <EditOutlined />
                  </a>
                  <Popconfirm
                    title={t("community.delete-confirm")}
                    onConfirm={() => onDelete(record)}
                  >
                    <a key="delete" title={t("main.delete")}>
                      <DeleteOutlined />
                    </a>
                  </Popconfirm>
                </Space>
              )}
            />
          </Table>
        </Col>
      </Row>

      <Modal
        title={t("community.edit-title")}
        footer={null}
        visible={!!editingCommunity}
        onCancel={() => onCancelEditing()}
      >
        {editingCommunity && (
          <Form
            onFinish={val => onSave(val)}
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
          >
            <Form.Item
              name="name"
              label={t("main.name")}
              rules={[{ required: true, message: t("form.required-field") }]}
            >
              <Input />
            </Form.Item>

            {!editingCommunity.id && (
              <Form.Item name="parentId" label={t("community.parent")}>
                <Select
                  onChange={val =>
                    setEditingCommunity({
                      ...editingCommunity,
                      parentId: val ? Number(val) : undefined,
                    })
                  }
                >
                  <Select.Option key="0" value="">
                    {t("community.no-parent")}
                  </Select.Option>
                  {communities
                    .filter(c => c.id !== editingCommunity?.id)
                    .map(c => (
                      <Select.Option key={c.id} value={c.id}>
                        <CommunityTag community={c} />
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )}

            {!editingCommunity.parentId && (
              <>
                <Form.Item
                  name="color"
                  label={t("main.color")}
                  rules={[
                    { required: true, message: t("form.required-field") },
                    {
                      validator: async () =>
                        /^#([0-9A-F]{3}){1,2}$/i.test(editingCommunity.color || "")
                          ? Promise.resolve()
                          : Promise.reject(),
                      message: t("form.required-color-hex"),
                    },
                  ]}
                >
                  <Space direction="vertical">
                    <Input
                      value={editingCommunity.color}
                      onChange={event => onColorChange(event.target.value)}
                    />
                    <CirclePicker
                      color={editingCommunity.color || "black"}
                      onChange={color => onColorChange(color.hex)}
                    />
                  </Space>
                </Form.Item>
              </>
            )}

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button key="save" type="primary" htmlType="submit">
                {t("main.save")}
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </div>
  );
};
