import RestRepository from "../../infra/RestRepository";
import { LuccaAccounts } from "./Lucca";

export class LuccaRestRepo extends RestRepository {
  constructor() {
    super("/api/lucca");
  }

  async getLuccaAccounts(): Promise<LuccaAccounts[]> {
    return super.get("/accounts/list");
  }
}
