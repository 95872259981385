import React, { CSSProperties } from "react";

export default (props: {
  width: number;
  height: number;
  color: string;
  striped?: boolean;
}) => {
  const { width, height, color, striped = false } = props;

  const patternId = "stripes-" + Math.random();
  const padd = height * 0.6;

  const rectStyle: CSSProperties = {
    fill: striped ? `url(#${patternId})` : color,
  };

  return (
    <svg
      width={width}
      height={height + padd}
      style={{ marginTop: -padd }}
      viewBox="0 0 1000 800"
      preserveAspectRatio="none"
    >
      <defs>
        <pattern
          id={patternId}
          viewBox="0,0,100,100"
          width="25%"
          height="25%"
          patternTransform="rotate(35)"
        >
          <rect x="0" y="0" width="90%" height="100%" style={{ fill: color }} />
        </pattern>
      </defs>

      <g>
        <rect x="0" y="300" width="1000" height="500" rx="15" style={rectStyle} />
        <path
          d="M 200,280 C 300,0 700,0 800,280"
          fillOpacity="0"
          style={{ stroke: color, strokeWidth: 70 }}
        />
      </g>
    </svg>
  );
};
