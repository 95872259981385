import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  showError,
  showSuccess,
  showWarning,
} from "../../../domain/notification/NotificationService";
import i18n from "../../../i18n";
import { Policy } from "../../../domain/policy/Policy";
import { PolicyRestRepo } from "../../../domain/policy/PolicyRestRepo";
import { Optional } from "../../../types";
import { refreshBookings } from "../actions";

const policyRepo = new PolicyRestRepo();

export const savePolicy = createAsyncThunk<void, Optional<Policy, "id">>(
  "api/savePolicy",
  async (payload, thunkAPI) => {
    return (
      payload.id ? policyRepo.update(payload as Policy) : policyRepo.create(payload)
    )
      .then(balance => {
        showSuccess(i18n.t("policy.save-success"));

        if (balance.conflict > 0) {
          showWarning(i18n.t("policy.warning-autobooking-missing-desk"));
        }

        thunkAPI.dispatch(refreshPolicies());
        thunkAPI.dispatch(refreshBookings({}));
      })
      .catch(() => showError(i18n.t("policy.save-error")));
  }
);

export const deletePolicy = createAsyncThunk<void, number>(
  "api/deletePolicy",
  async (payload, thunkAPI) => {
    return policyRepo
      .delete(payload)
      .then(balance => {
        showSuccess(i18n.t("policy.delete-success"));

        if (balance.conflict > 0) {
          showWarning(i18n.t("policy.warning-autobooking-missing-desk"));
        }

        thunkAPI.dispatch(refreshPolicies());
        thunkAPI.dispatch(refreshBookings({}));
      })
      .catch(() => showError(i18n.t("policy.delete-error")));
  }
);

export const refreshPolicies = createAsyncThunk<Policy[]>(
  "api/refreshPolicies",
  async () => policyRepo.get("/")
);
