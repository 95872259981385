import React, { useCallback, useEffect, useState } from "react";
import UserProfilePicture from "./UserProfilePicture";
import { Button, Col, Form, Input, Row, Select, Tabs } from "antd";

import { useTranslation } from "react-i18next";
import AuthService, { AuthUser } from "../../../../domain/auth/AuthService";
import { useDispatch } from "react-redux";
import { updateUserPicture } from "../../../redux/actions";
import UserRestRepo from "../../../../domain/user-account/UserRestRepo";
import {
  showSuccess,
  showError,
} from "../../../../domain/notification/NotificationService";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";

// Actions
import { updateUserInfos } from "../../../redux/actions";

// Types
import { UserAccount } from "../../../../domain/user-account/UserAccount";

import "./user-profile-account.less";

const userRepo = new UserRestRepo();
const authService = new AuthService();

export const UserAccountPage = (props: { user: AuthUser }) => {
  // TODO @Billy Chen
  const { collaboratorId, workspaces, lang, picturePath } = props.user;
  const [form] = Form.useForm();

  // State
  const [imageUrl, setImageUrl] = useState(picturePath);

  // TODO @Billy Chen
  const [loading, setLoading] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleChange = useCallback(
    (info: UploadChangeParam<UploadFile<any>>) => {
      if (info.file.status === "uploading") {
        setLoading(true);
        return;
      }
      if (info.file.status === "error") {
        setLoading(false);
        showError(t("error.unexpected-upload-error"));
      }
      if (info.file.status === "done") {
        if (info.file.response?.data) {
          dispatch(
            updateUserPicture({
              collaboratorId,
              picturePath: info.file.response.data.picturePath,
            })
          );
          setImageUrl(info.file.response.data.picturePath);
        }
        setLoading(false);
      }
    },
    // TODO @Billy Chen
    [dispatch, t]
  );

  const onWorkspaceSwitch = useCallback(async (ws: string) => {
    await authService.switchWorkspace(ws);
    window.location.reload();
  }, []);

  const onFinish = async (values: UserAccount) => {
    const { name, lang } = values;

    const userParams: UserAccount = {
      name,
      lang,
    };

    try {
      await userRepo.update(userParams);

      dispatch(updateUserInfos(userParams));
      showSuccess(t("user-account.update-success"));
    } catch (e) {
      showError(t("error-api.unexpected-error"));
      console.error(e);
    }
  };

  const onFieldsChange = () => {
    setHasChanged(true);
  };

  useEffect(() => {
    form.resetFields();
  }, [form, props.user]);

  return (
    <div>
      <Tabs style={{ height: "100%" }} defaultActiveKey="1">
        <Tabs.TabPane tab={t("main.user-account")} key="1">
          <Row gutter={[0, 30]}>
            <Col span={24}>
              <UserProfilePicture {...{ handleChange }} path={imageUrl} />
            </Col>
            <Col span={24}>
              <Form
                onFinish={onFinish}
                onFieldsChange={onFieldsChange}
                requiredMark={false}
                labelAlign="left"
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 12 }}
                initialValues={{
                  name: props.user.name,
                  email: props.user.email,
                  lang: props.user.lang,
                }}
              >
                <Form.Item name="email" label={t("user-account.email")}>
                  <Input disabled placeholder={t("user-account.email-placeholder")} />
                </Form.Item>

                <Form.Item
                  name="name"
                  label={t("user-account.name")}
                  rules={[
                    {
                      required: true,
                      message: t("user-account.username-required"),
                    },
                  ]}
                >
                  <Input
                    disabled={props.user.owned}
                    placeholder={t("user-account.name-placeholder")}
                  />
                </Form.Item>

                <Form.Item name="lang" label={t("main.language")}>
                  <Select>
                    <Select.Option value="fr">{t("main.french")}</Select.Option>
                    <Select.Option value="en">{t("main.english")}</Select.Option>
                  </Select>
                </Form.Item>
              </Form>
            </Col>

            <Col span={24}>
              <Button
                type="primary"
                disabled={!hasChanged}
                className={"mr-xs"}
                onClick={() => form.submit()}
              >
                {t("user-account.update")}
              </Button>
              <Button className={"m-xs"} onClick={() => authService.signOut()}>
                {t("main.logout")}
              </Button>
            </Col>
          </Row>
        </Tabs.TabPane>

        {workspaces.length > 0 && (
          <Tabs.TabPane tab={t("main.workspaces")} key="2">
            <div style={{ width: "250px" }}>
              <div style={{ marginBottom: "10px" }}>
                {t("user-account.available-workspaces")}
              </div>
              {workspaces.map(ws => {
                return (
                  <div
                    key={ws}
                    style={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <div>• {ws}</div>{" "}
                    {workspaces.length > 1 && (
                      <Button onClick={() => onWorkspaceSwitch(ws)}>
                        {t("main.switch")}
                      </Button>
                    )}
                  </div>
                );
              })}
            </div>
          </Tabs.TabPane>
        )}
      </Tabs>
    </div>
  );
};

export const MemoedUserAccount = React.memo(UserAccountPage);
