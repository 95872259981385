import React, { useCallback } from "react";
import { Badge } from "antd";
import chroma from "chroma-js";
import { CloseCircleOutlined } from "@ant-design/icons";
import {
  locateCollaborator,
  removeBooking,
  setHighlightedCollaborators,
} from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { Shifts } from "../../../domain/booking/Booking";
import {
  useAuthUser,
  useOrganization,
  usePageChanger,
  useSelectedDay,
  useOfficeSpace,
} from "../../hook/hooks";
import { RootState } from "../../redux/state";
import { Pages } from "../LeftMainMenu";

export const EmployeeListItem = (props: { collaboratorId: number }) => {
  const { collaboratorId } = props;
  const dispatch = useDispatch();

  const space = useOfficeSpace();
  const user = useAuthUser();
  const shifts = useSelector<RootState, Shifts>(s => s.shifts);
  const selectedDay = useSelectedDay();
  const org = useOrganization();
  const pageChanger = usePageChanger();

  const [isBeingHovered, setIsBeingHovered] = React.useState(false);

  const isHrManager = user.role === "admin";

  const onClickRemove = useCallback(() => {
    const booking = shifts[collaboratorId].bookings[selectedDay];

    const seatToUnHighLight = document.getElementById(`${booking.deskId}`);
    seatToUnHighLight?.classList.remove("foobar");

    dispatch(removeBooking(booking));
    dispatch(setHighlightedCollaborators(null));

    return false;
  }, [shifts, selectedDay, dispatch, collaboratorId]);

  const onClickEmployee = useCallback(() => {
    if (!collaboratorId) return;

    // Get the corresponding floor
    const deskId = shifts[collaboratorId]?.bookings[selectedDay]?.deskId;
    if (!deskId) return;

    const floorId = space.getFloorIdByDeskId(deskId);
    if (!floorId) return;

    pageChanger(Pages.floorplans);
    dispatch(
      locateCollaborator({
        collaboratorId: collaboratorId,
        date: selectedDay,
        floorId,
      })
    );
  }, [selectedDay, shifts, space, dispatch, collaboratorId]);

  return (
    <div
      onClick={onClickEmployee}
      onMouseLeave={() => setIsBeingHovered(false)}
      onMouseOver={() => setIsBeingHovered(true)}
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <div
        draggable={isHrManager}
        key={collaboratorId}
        onDragStart={event => {
          event.dataTransfer.setData(
            "text/plain",
            JSON.stringify({ collaboratorId: collaboratorId, type: "employee-drag" })
          );
        }}
        style={{
          cursor: "pointer",
          display: "inline-flex",
          height: "22px",
          overflow: "hidden",
        }}
      >
        <Badge color={chroma(org.getCollaboratorColor(collaboratorId)).hex()} />
        <div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {org.getCollabName(collaboratorId)}
        </div>
      </div>
      <span key={"element-B"} style={{ flexGrow: 1 }}></span>
      {isBeingHovered && isHrManager && (
        <span
          className="peopletree-cross"
          key={"element-C"}
          onClick={onClickRemove}
          style={{ cursor: "pointer" }}
        >
          <CloseCircleOutlined />
        </span>
      )}
    </div>
  );
};
