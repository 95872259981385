import React, { useCallback, useEffect, useState } from "react";
import firebase from "firebase";
import { StyledFirebaseAuth } from "react-firebaseui";
import firebaseui from "firebaseui";
import AuthService, { IdentityProvider } from "../../../domain/auth/AuthService";
import { getUserMessage } from "../../../infra/RestRepository";
import i18n from "i18next";
import { showError } from "../../../domain/notification/NotificationService";
import { useTranslation } from "react-i18next";

import "./signup-page.less";
import { useDispatch } from "react-redux";
import { loadAuthUser } from "../../redux/actions";
import { Button } from "antd";

const authService = new AuthService();

const buildSigninOptions = (providers: IdentityProvider[]) => {
  const res: firebaseui.auth.Config["signInOptions"] = [];

  const signinProviderIds =
    providers.length > 0
      ? providers.map(p => p.gcipId)
      : [
          firebase.auth.EmailAuthProvider.PROVIDER_ID,
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          "microsoft.com",
        ];

  for (const idpId of signinProviderIds) {
    if (idpId === firebase.auth.EmailAuthProvider.PROVIDER_ID) {
      res.push({ provider: idpId, requireDisplayName: false });
    } else {
      // Default options
      res.push(idpId);
    }
  }

  return res;
};

export default (props: { workspace?: string }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [authMsg, setAuthMsg] = useState<string | undefined>(undefined);
  const [providers, setProviders] = useState<IdentityProvider[] | null>(null);
  const [uiConfig, setUiConfig] = useState<firebaseui.auth.Config | null>(null);

  const handleError = useCallback(
    (err: Error) => {
      console.error("Error on signin", err);
      const msg = getUserMessage(err) || i18n.t("error.login-failed");
      setAuthMsg(msg);
    },
    [setAuthMsg]
  );

  // Load identity providers
  useEffect(() => {
    authService
      .getIdProvidersForWorkspace(props.workspace)
      .then(idps => setProviders(idps))
      .catch(err => {
        console.error("getIdProvidersForWorkspace failed", err);
        showError(i18n.t("main.error-connection"));

        setProviders([]);
      });
  }, [props.workspace]);

  // Set config according providers
  useEffect(() => {
    // Wait for provider setup
    if (!providers) return;

    const conf = {
      callbacks: {
        signInSuccessWithAuthResult: (userCredential: firebase.auth.UserCredential) => {
          if (
            userCredential.user &&
            userCredential.additionalUserInfo?.providerId ===
              firebase.auth.EmailAuthProvider.PROVIDER_ID &&
            !userCredential.user?.emailVerified
          ) {
            // Send email verification
            userCredential.user
              .sendEmailVerification()
              .then(() => {
                setAuthMsg(t("auth.verification-email-sent"));
              })
              .catch(handleError);
          } else {
            authService
              .onProviderAuthResult(userCredential)
              .then(() => {
                dispatch(loadAuthUser());
              })
              .catch(handleError);
          }

          // False : no redirect
          return false;
        },
        signInFailure: (error: firebaseui.auth.AuthUIError) =>
          handleError(new Error(error.message)),
      },
      signInFlow: "popup",
      signInOptions: buildSigninOptions(providers),
    };

    setUiConfig(conf);
    // TODO @Billy
  }, [providers]);

  return (
    <div className="hero">
      <div id="title"></div>
      <div id="tagline">{t("main.tagline")}</div>

      {authMsg && (
        <div className="auth-msg">
          <p>{authMsg}</p>
          <Button onClick={() => setAuthMsg(undefined)}>{t("main.back")}</Button>
        </div>
      )}

      {!authMsg && uiConfig && (
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={authService.auth} />
      )}
    </div>
  );
};
