import React from "react";
import { showError } from "../../../../../domain/notification/NotificationService";
import { useTranslation } from "react-i18next";

// Ant
import { Button, Card, Col, Modal, Row, Spin } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import "../../integration-setting.less";
import useSlackInstall from "../../../../hook/useSlackInstall";
import { ModalSetting } from "../../../../component/modal-settings/ModalSetting";
import SlackIntegrationForm from "./SlackIntegrationForm";
import { SlackInstallForm } from "../../../../../domain/slack-install/SlackInstall";

export default (props: { wid: number, aid: number }) => {
  const { wid, aid } = props;

  // hooks
  const {
    handleCancelSettings,
    onOpenSettings,
    startInstall,
    uninstallSlack,
    saveSlackInstall,
    initialValues,
    openSettings,
    slackInstall,
    isLoading,
  } = useSlackInstall(wid, aid);
  const { t } = useTranslation();

  const onDisconnect = () => {
    Modal.confirm({
      title: t("slack.suspend-confirm"),

      icon: <ExclamationCircleOutlined />,
      content: t("slack.suspend-desc"),
      width: 600,
      onOk: uninstallSlack,
    });
  };

  const onSubmit = async (values: SlackInstallForm) => {
    try {
      await saveSlackInstall(values);
    } catch (e) {
      showError(t("slack.save-error"));
    }
  };

  return (
    <Card className="integration-card">
      <Row gutter={[0, 15]} align={"middle"}>
        <Col flex="50px">
          <img
            alt="Add to Slack"
            height="40"
            src={"/logo-slack.png"}
            srcSet={"/logo-slack.png 1x, /logo-slack.png 2x"}
            style={{ cursor: "pointer" }}
          />
        </Col>
        <Col md={22} lg={2} xl={2}>
          <span className={"integration-title"}>Slack</span>
        </Col>
        <Col md={24} lg={24} xl={24}>
          <span className={"integration-description"}>
            {t("slack.integration-description")}
          </span>
        </Col>
        <Col span={24}>
          {slackInstall ? (
            <>
              <Button disabled={isLoading} type="primary" onClick={onOpenSettings}>
                {t("slack.settings")}
              </Button>
              {!isLoading && (
                <Button type="link" disabled={isLoading} onClick={onDisconnect}>
                  {!isLoading ? (
                    <span className={"disconnect-button-text"}>
                      {t("slack.disconnect")}
                    </span>
                  ) : (
                    <Spin size="small" />
                  )}
                </Button>
              )}
            </>
          ) : (
            <Button disabled={isLoading} onClick={startInstall} type="ghost">
              {isLoading ? <Spin size="small" /> : t("slack.connect")}
            </Button>
          )}
        </Col>
      </Row>
      <ModalSetting
        title={t("slack.title")}
        onCancel={handleCancelSettings}
        state={openSettings}
      >
        <SlackIntegrationForm
          defaultValues={{
            ...initialValues,
          }}
          onFinish={onSubmit}
          isLoading={isLoading}
        />
      </ModalSetting>
    </Card>
  );
};
