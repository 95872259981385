import React, { Suspense } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";

import { store } from "./redux/store";

import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import { loadAuthUser } from "./redux/actions";
import * as Sentry from "@sentry/react";
import { AuthUser } from "../domain/auth/AuthService";
import { ErrorScreen } from "./page/error/ErrorScreen";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import AuthedApp from "./page/AuthedApp";
import { ConfigProvider } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import SignupPage from "./page/signup/SignupPage";
import { useRouteWorkspace } from "./hook/hooks";
import { BrowserRouter } from "react-router-dom";
import { InitStatus, RootState } from "./redux/state";
import { useTranslation } from "react-i18next";

const App = (): JSX.Element => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const locale = i18n.language === "fr" ? frFR : undefined;

  // Get the first segment of the URL
  const routeWorkspace = useRouteWorkspace();

  const initStatus = useSelector<RootState, InitStatus>(s => s.initStatus);
  const user = useSelector<RootState, AuthUser | undefined>(s => s.user);

  React.useEffect(() => {
    dispatch(loadAuthUser());
  }, [dispatch]);

  // Display login page if unauthenticated
  if (initStatus === "auth" && !user) {
    return <SignupPage workspace={routeWorkspace} />;
  }

  // Otherwise display the app
  if (initStatus === "data" && user) {
    return (
      <ConfigProvider locale={locale}>
        <AuthedApp />
      </ConfigProvider>
    );
  }

  // Display laoding while auth context is not known
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        width: "100vw",
      }}
    >
      <div style={{ fontSize: "40px" }}>
        <LoadingOutlined />
      </div>
    </div>
  );
};

export default () => (
  <Provider store={store}>
    <Sentry.ErrorBoundary fallback={ErrorScreen} showDialog={false}>
      <BrowserRouter>
        <DndProvider backend={HTML5Backend}>
          {/* Suspense is required by react-18next */}
          <Suspense fallback="Loading...">
            <App />
          </Suspense>
        </DndProvider>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  </Provider>
);
