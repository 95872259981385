import React from "react";

import { useTranslation } from "react-i18next";

import { GoogleInstall } from "../../../../../domain/google-install/GoogleInstall";

// Ant
import { Checkbox, Form, Tooltip, Button, Spin } from "antd";
import { QuestionCircleOutlined, TeamOutlined } from "@ant-design/icons";

type Props = {
  defaultValues: GoogleInstall | Record<string, unknown>;
  isLoading: boolean;
  onFinish: (values: any) => void;
};

export const GoogleIntegrationForm = (props: Props) => {
  const { defaultValues, onFinish, isLoading } = props;

  // hooks
  const { t } = useTranslation();
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 20,
      }}
      name="google-settings-form"
      onFinish={onFinish}
      initialValues={{
        ...defaultValues,
      }}
    >
      <Form.Item
        wrapperCol={{ span: 24 }}
        name={"userProvisioningEnabled"}
        valuePropName="checked"
      >
        <Checkbox>
          <span>
            <TeamOutlined />{" "}
          </span>
          {t("google.user-provisioning")}
          <Tooltip className={"ml-xs"} title={t("google.user-provisioning-description")}>
            <QuestionCircleOutlined />
          </Tooltip>
        </Checkbox>
      </Form.Item>

      <Form.Item wrapperCol={{ span: 24 }} name={"syncBookings"} valuePropName="checked">
        <Checkbox>
          <span>
            <TeamOutlined />{" "}
          </span>
          {t("google.bookings")}
          <Tooltip className={"ml-xs"} title={t("google.bookings-description")}>
            <QuestionCircleOutlined />
          </Tooltip>
        </Checkbox>
      </Form.Item>

      <Form.Item wrapperCol={{ span: 16, offset: 9 }}>
        <Button disabled={isLoading} type="primary" htmlType="submit">
          {!isLoading && t("main.save")}
          {isLoading && <Spin size="small" />}
        </Button>
      </Form.Item>
    </Form>
  );
};
