export const validateButtonBackground = "rgb(89,162,54)";
export const validateButtonBorder = "#237804";
export const diffGreen = "#00800026";
export const diffRed = "#ff000026";
export const successGreen = "#52c41a"; // Ant success notif
export const backgroundGrey = "#f9f9f9";

/**
 * Chart colors
 */

export const chartColors = {
  blue: { red: "76", green: "109", blue: "235" },
  gray: { red: "212", green: "212", blue: "228" },
  purple: { red: "133", green: "76", blue: "235" },
  yellow: { red: "253", green: "206", blue: "058" },
};
