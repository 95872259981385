import React, { useCallback } from "react";
import classNames from "classnames";
import Tippy from "@tippyjs/react";

import { Booking, BookingType } from "../../../domain/booking/Booking";
import { addBooking, locateCollaborator, removeBooking } from "../../redux/actions";
import { OfficeIcon, OffIcon, RemoteIcon } from "../../icons/Building";
import { Delete } from "../../icons/delete";

import "./Blocks.css";
import BookingPermission from "../../../domain/booking/BookingPermission";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import OfficeSpace from "../../../domain/building/OfficeSpace";
import { useTranslation } from "react-i18next";
import { Collaborator } from "../../../domain/collaborator/Collaborator";
import { useOrganization, usePageChanger } from "../../hook/hooks";
import { Pages } from "../../page/LeftMainMenu";

export const NonBookedBlock = (props: {
  day: string;
  collaborator: Collaborator;
  permissions: BookingPermission;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!props.permissions.isDayBookable(props.collaborator.id, props.day)) return null;

  const clickOnBooking = (type: BookingType) => {
    if (props.permissions.isDayBookable(props.collaborator.id, props.day, type)) {
      dispatch(
        addBooking({ date: props.day, collaboratorId: props.collaborator.id, type })
      );
    }
  };

  return (
    <div className="non-booked-block">
      <Tippy content={t("main.office-day")}>
        <Button
          disabled={
            !props.permissions.isDayBookable(props.collaborator.id, props.day, "office")
          }
          onClick={() => clickOnBooking("office")}
        >
          <OfficeIcon />
        </Button>
      </Tippy>
      <Tippy content={t("main.remote-day")}>
        <Button
          disabled={
            !props.permissions.isDayBookable(props.collaborator.id, props.day, "remote")
          }
          onClick={() => clickOnBooking("remote")}
        >
          <RemoteIcon />
        </Button>
      </Tippy>
      <Tippy content={t("main.day-off")}>
        <Button onClick={() => clickOnBooking("off")}>
          <OffIcon />
        </Button>
      </Tippy>
    </div>
  );
};

export const BookedBlock = (props: {
  booking: Booking;
  permissions: BookingPermission;
  space: OfficeSpace;
}) => {
  const { t } = useTranslation();
  const { booking, permissions, space } = props;

  const dispatch = useDispatch();
  const pageChanger = usePageChanger();
  const org = useOrganization();

  const backgroundColor =
    booking.type === "office" ? org.getCollaboratorColor(booking.collaboratorId) : "#eee";
  const foregroundColor = booking.type === "office" ? "white" : "#555";

  const floorId = booking.deskId ? space.getFloorIdByDeskId(booking.deskId) : undefined;

  const onClickLocate = useCallback(() => {
    if (booking.type !== "office" || !floorId) return;

    pageChanger(Pages.floorplans);

    dispatch(
      locateCollaborator({
        collaboratorId: booking.collaboratorId,
        date: booking.date,
        floorId: floorId,
      })
    );
  }, [
    booking.type,
    booking.collaboratorId,
    booking.date,
    floorId,
    pageChanger,
    dispatch,
  ]);

  const onClickRemove = useCallback(() => {
    if (permissions.isBookingRemovable(booking)) {
      dispatch(removeBooking(booking));
    }
  }, [booking, dispatch, permissions]);

  return (
    <div className="booked-block" style={{ backgroundColor }}>
      <div className="booked-block-content">
        <div
          className="booking-main-icon"
          style={{
            justifyContent: "left",
          }}
        >
          {booking.type === "office" ? (
            <OfficeIcon color={foregroundColor} />
          ) : booking.type === "remote" ? (
            <RemoteIcon color={foregroundColor} />
          ) : booking.type === "off" ? (
            <OffIcon color={foregroundColor} />
          ) : (
            <></>
          )}
          <div
            className={classNames({
              "block-floor-name": booking.type === "office",
              "booked-block-label": true,
            })}
            onClick={onClickLocate}
          >
            {booking.type === "office" && floorId
              ? space.getFloorName(floorId)
              : booking.type === "off"
              ? t("main.day-off")
              : t("main.remote")}
          </div>
        </div>
      </div>

      {permissions.isBookingRemovable(booking) && (
        <Tippy content={t("main.remove-booking")}>
          <div
            className={classNames("block-delete-icon", {
              [`block-delete-${booking.type}`]: true,
            })}
            onClick={onClickRemove}
          >
            <Delete color={booking.type === "office" ? "white" : "black"} />
          </div>
        </Tippy>
      )}
    </div>
  );
};
