import React, { useEffect, useState } from "react";
import { Moment } from "moment";
import { useTranslation } from "react-i18next";
import { Col, DatePicker, Row, Select } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import "./dashboard.less";

import { DateTime } from "../../../domain/date-time/DateTime";

// Components
import OccupancyChart from "./occupancy-chart/OccupancyChart";
import CommunitiesChart from "./communities-chart/CommunitiesChart";

// type
import { ValuesType } from "../../../domain/history/History";

export type DateFilter = "lastTwoWeeks" | "lastWeek" | "month";

const { Option } = Select;
const { RangePicker } = DatePicker;

export default () => {
  // State
  const [dateRange, setDateRange] = useState<Array<string>>([]);
  const [showPercentage, setShowPercentage] = useState<ValuesType>(ValuesType.VALUES);
  const [period, setPeriod] = useState({
    start: DateTime.subtractFromNow(6, "days"),
    end: DateTime.todayString(),
  });

  // hooks
  const { t } = useTranslation();

  useEffect(() => {
    const defaultStartDate = DateTime.subtractFromNow(6, "days", "MMM DD");
    const defaultEndDate = DateTime.todayString("MMM DD");

    setDateRange([...generateDateRange(defaultStartDate, defaultEndDate, "MMM DD")]);
  }, []);

  const generateDateRange = (
    startDate: string,
    endDate: string,
    format: string = DateTime.DATE_PATTERN
  ) => {
    return DateTime.getDateRange(startDate, endDate, format).map(d =>
      DateTime.format(d, format)
    );
  };

  const onChangeShowPercentage = (type: ValuesType) => {
    setShowPercentage(type);
  };

  const onPeriodChange = (values: [Moment | null, Moment | null] | null) => {
    const startPeriod =
      values && values?.length > 0 && values[0]
        ? values[0].format(DateTime.DATE_PATTERN)
        : "";
    const endPeriod =
      values && values?.length > 1 && values[1]
        ? values[1].format(DateTime.DATE_PATTERN)
        : "";

    const startRange =
      values && values?.length > 0 && values[0] ? values[0].format("MMM DD") : "";
    const endRange =
      values && values?.length > 1 && values[1] ? values[1].format("MMM DD") : "";

    setDateRange([...generateDateRange(startRange, endRange, "MMM DD")]);
    setPeriod({ start: startPeriod, end: endPeriod });
  };

  return (
    <div className={"fullwidth root-page"}>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <h2>{t("dashboard.dashboard")}</h2>
          <p>{t("dashboard.dashboard-description")}</p>
        </Col>
        <Col span={24}>
          <div className={"header-filter-input"}>
            <CalendarOutlined className={"calendar-icon"} style={{ color: "#e24357" }} />

            <RangePicker
              defaultValue={[
                DateTime.dateFormat(period.start, DateTime.DATE_PATTERN),
                DateTime.dateFormat(period.end, DateTime.DATE_PATTERN),
              ]}
              disabledDate={date =>
                DateTime.isAfter(date, DateTime.addFromNow(6, "weeks"))
              }
              format={DateTime.DATE_PATTERN}
              onChange={range => onPeriodChange(range)}
            />

            <Select
              onChange={onChangeShowPercentage}
              defaultValue={ValuesType.VALUES}
              dropdownMatchSelectWidth={false}
            >
              <Option value={ValuesType.VALUES}>{t("dashboard.show-values")}</Option>
              <Option value={ValuesType.PERCENTAGE}>
                {t("dashboard.show-percentage")}
              </Option>
            </Select>
          </div>
        </Col>
        <Col span={24}>
          <OccupancyChart
            showPercentage={showPercentage}
            startDate={period.start}
            endDate={period.end}
            dateRange={dateRange}
          />
        </Col>

        <Col span={24}>
          <CommunitiesChart
            showPercentage={showPercentage}
            startDate={period.start}
            endDate={period.end}
            dateRange={dateRange}
          />
        </Col>
      </Row>
    </div>
  );
};
