import moment from "moment";
import Week from "../week/Week";
import { Booking, BookingType } from "./Booking";
import OfficeSpace from "../building/OfficeSpace";
import { AuthUser, UserRole } from "../auth/AuthService";
import { Workspace } from "../workspace/Workspace";
import { Policy } from "../policy/Policy";
import Organization from "../community/Organization";

export type BookingBalance = {
  remote: { curr: number; max: number };
  office: { curr: number; max: number };
};

export default class BookingPermission {
  readonly bookingTimeWindow: number;

  private readonly role: UserRole;
  private readonly bookings: Booking[];

  private readonly organization: Organization;

  private readonly policies: Policy[];
  private readonly userCollaboratorId: number;
  private readonly space: OfficeSpace;

  constructor(
    user: AuthUser,
    workspace: Workspace,
    bookings: Booking[],
    space: OfficeSpace,
    organization: Organization,
    policies: Policy[]
  ) {
    this.role = user.role;
    this.userCollaboratorId = user.collaboratorId;
    this.bookingTimeWindow = workspace.bookingTimeWindow || 1;

    this.space = space;

    this.bookings = bookings;
    this.organization = organization;

    this.policies = policies;
  }

  /**
   * Is the user can book desk for the given employee and desk and day
   *
   * @param collaboratorId
   * @param day
   * @param deskId
   */
  isDeskBookable(collaboratorId: number, day: string, deskId: number) {
    // Check if user can book for the employee at office this day
    if (!this.isDayBookable(collaboratorId, day, "office")) return false;

    // Check desk is disabled
    const desk = this.space.getDesk(deskId);
    if (!desk || desk.disabled) return false;

    // Check desk already have booking
    const bookingOnThatDesk = this.bookings.find(
      b => b.deskId === deskId && b.date === day
    );
    if (bookingOnThatDesk) return false;

    // Check for manager
    if (this.role === "admin") return true;

    // Check Floor max booking is reached
    const floorId = this.space.getFloorIdByDeskId(deskId);
    if (floorId) {
      const bookingCount = this.bookings.filter(
        b =>
          b.type === "office" &&
          b.date === day &&
          b.deskId &&
          this.space.isDeskInFloor(b.deskId, floorId)
      ).length;

      const maxBooking = this.space.getMaxBookingByFloorId(floorId);

      if (maxBooking !== undefined && bookingCount >= maxBooking) return false;
    }

    // check desk is not dedicated
    if (!desk.zoneId) return true;

    // Check collab matches zone
    const zoneId = this.organization.findZoneForCollab(collaboratorId);

    if (zoneId && desk.zoneId === zoneId) {
      return true;
    }

    return false;
  }

  isDayBookable(collaboratorId: number, day: string, type?: BookingType) {
    if (this.role === "admin") return true;

    if (this.isLoggedUser(collaboratorId) && this.isInBookingWindow(day)) {
      if (type === "office" && this.hasBookingToMove(collaboratorId, day)) return true;

      if (
        (type === "office" || type === "remote") &&
        !this.isConstraintRespected(collaboratorId, day, type)
      )
        return false;

      return true;
    }

    return false;
  }

  isBookingMovable(booking: Booking) {
    if (this.role === "admin") return true;

    if (
      this.isLoggedUser(booking.collaboratorId) &&
      this.isInBookingWindow(booking.date)
    ) {
      return true;
    }

    return false;
  }

  isBookingRemovable(booking: Booking) {
    // Same rule to book any type and remove
    return this.isDayBookable(booking.collaboratorId, booking.date);
  }

  canEditInThePastAndBeyondBookingWindow() {
    return this.role === "admin";
  }

  isPast(day: string) {
    return Week.isBeforeToday(day);
  }

  isInBookingWindow(date: string) {
    if (this.isPast(date)) return false;

    const nextMonday = moment().startOf("isoWeek").add(1, "week");
    const limit = nextMonday.add(this.bookingTimeWindow, "week");

    return moment(date).isBefore(limit);
  }

  private isLoggedUser(collaboratorId: number) {
    return this.userCollaboratorId === collaboratorId;
  }

  private isConstraintRespected(
    collaboratorId: number,
    day: string,
    type: "office" | "remote"
  ) {
    const week = new Week({ selected: day });

    // Look only for "max" days in the week
    const balance = this.getBookingBalance(collaboratorId, week.days);

    if (type === "office") return balance.office.curr < balance.office.max;
    else if (type === "remote") return balance.remote.curr < balance.remote.max;

    return false;
  }

  getBookingBalance(collaboratorId: number, weekDays: string[]): BookingBalance {
    const policy = this.getPolicyApplyingTo(collaboratorId);

    const balance = {
      remote: { curr: 0, max: policy ? policy.maxRemote : 5 },
      office: { curr: 0, max: policy ? policy.maxOffice : 5 },
    };

    this.bookings
      .filter(b => b.status === "ok")
      .filter(b => b.collaboratorId === collaboratorId)
      .filter(b => weekDays.includes(b.date))
      .forEach(b => {
        if (b.type === "remote") balance.remote.curr++;
        if (b.type === "office") balance.office.curr++;
      });

    return balance;
  }

  findOfficeBooking(collaboratorId: number, day: string) {
    return this.bookings.find(
      b => b.type === "office" && b.collaboratorId === collaboratorId && b.date === day
    );
  }

  hasBookingToMove(collaboratorId: number, day: string) {
    return this.findOfficeBooking(collaboratorId, day);
  }

  private getPolicyApplyingTo(collaboratorId: number) {
    const policy = this.policies.find(p => p.collaboratorId === collaboratorId);
    if (policy) return policy;

    const collab = this.organization.findCollaborator(collaboratorId);
    if (!collab) return;

    if (collab.communityId) {
      return this.organization.findInClosestParent(collab.communityId, c =>
        this.policies.find(p => p.communityId === c.id)
      );
    }
  }
}
