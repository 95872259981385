import React from "react";
import Tippy from "@tippyjs/react";
import { Booking } from "../../../domain/booking/Booking";
import BookingPermission, {
  BookingBalance,
} from "../../../domain/booking/BookingPermission";

import "tippy.js/themes/light.css";
import "tippy.js/dist/tippy.css";
import {
  useBookingPermissions,
  useCollaboratorBookingByDay,
  useImageURL,
  useMyCollaborator,
  useOrganization,
  useOfficeSpace,
  useWeekDays,
} from "../../hook/hooks";
import { BookedBlock, NonBookedBlock } from "../blocks/Blocks";
import OfficeSpace from "../../../domain/building/OfficeSpace";

import "./my-booking-row.less";
import { useTranslation } from "react-i18next";
import { Collaborator } from "../../../domain/collaborator/Collaborator";
import { Progress, Space } from "antd";

const GenericAvatar = (props: { path?: string }) => {
  const imageURL = useImageURL(props.path);

  return (
    <div
      style={{
        alignItems: "center",
        backgroundColor: "#ccc",
        border: "1px solid #aaa",
        borderRadius: "32px",
        display: "flex",
        height: "32px",
        justifyContent: "center",
        marginRight: "10px",
        overflow: "hidden",
        width: "32px",
      }}
    >
      <img src={imageURL} style={{ width: "100%" }} />
    </div>
  );
};

const DayBlockTooltip = (props: {
  children?: React.ReactElement;
  permissions: BookingPermission;
  isPresent: boolean;
  day: string;
  collaboratorId: number;
}) => {
  let tooltipContent;

  const { permissions } = props;
  const { t } = useTranslation();

  if (!permissions.isDayBookable(props.collaboratorId, props.day)) {
    if (permissions.isPast(props.day)) {
      if (props.isPresent) {
        tooltipContent = <div>{t("booking.cant-remove-in-past")}</div>;
      } else {
        tooltipContent = <div>{t("booking.cant-book-in-past")}</div>;
      }
    } else if (!permissions.isInBookingWindow(props.day)) {
      const policyMessage = (
        <div style={{ textAlign: "center" }}>
          {t("booking.window-policy", { count: permissions.bookingTimeWindow })}
        </div>
      );

      if (permissions.isPast(props.day)) {
        tooltipContent = (
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>{t("booking.date-not-bookable")}</div>
            <div style={{ marginBottom: "8px" }}>{t("booking.go-next-to-book")}</div>
            {policyMessage}
          </div>
        );
      } else {
        tooltipContent = (
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: "8px" }}>
              {t("booking.date-not-yet-bookable")}
            </div>
            {policyMessage}
          </div>
        );
      }
    }
  }

  if (tooltipContent) {
    return (
      <Tippy content={tooltipContent} delay={[10, 0]} placement="bottom" theme="light">
        {props.children}
      </Tippy>
    );
  } else {
    return <>{props.children}</>;
  }
};

export const DayBlock = (props: {
  booking?: Booking;
  color: string;
  day: string;
  collaborator: Collaborator;
  isBookingBar?: boolean;
  weekDays: string[];
  permissions: BookingPermission;
  space: OfficeSpace;
}) => {
  const index = props.weekDays.indexOf(props.day);

  return (
    <DayBlockTooltip
      isPresent={!!props.booking}
      permissions={props.permissions}
      day={props.day}
      collaboratorId={props.collaborator.id}
    >
      <div
        className="day-block"
        id={`day-block-${props.day}`}
        key={`day-block-${props.day}`}
        style={{
          borderBottom: props.isBookingBar ? "unset" : "1px solid #e5e5e5",
          borderRight: index === 4 ? "1px solid #e5e5e5" : "unset",
          borderTop: props.isBookingBar ? "unset" : "1px solid #e5e5e5",
          gridColumnStart: index + 2,
        }}
      >
        {!props.booking && (
          <NonBookedBlock
            day={props.day}
            collaborator={props.collaborator}
            permissions={props.permissions}
          />
        )}

        {!!props.booking && (
          <BookedBlock
            booking={props.booking}
            permissions={props.permissions}
            space={props.space}
          />
        )}
      </div>
    </DayBlockTooltip>
  );
};

export const BookingStatus = (props: { balance: BookingBalance }) => {
  const { t } = useTranslation();
  const { balance } = props;

  return (
    <Space direction="horizontal">
      <Space direction="vertical">
        <div className="text-center">{t("main.remote")}</div>
        <Progress
          type="circle"
          strokeColor="#e24357"
          status="normal"
          percent={(balance.remote.curr / balance.remote.max) * 100}
          format={() => balance.remote.curr + " / " + balance.remote.max}
        />
      </Space>
      <Space direction="vertical">
        <div className="text-center">{t("main.office")}</div>
        <Progress
          type="circle"
          strokeColor="#e24357"
          status="normal"
          percent={(balance.office.curr / balance.office.max) * 100}
          format={() => balance.office.curr + " / " + balance.office.max}
        />
      </Space>
    </Space>
  );
};

export const MyBookingRow = (props: { isBookingBar?: boolean }) => {
  const { isBookingBar = false } = props;

  const myEmployee = useMyCollaborator();
  if (!myEmployee) return null;

  const myBookingByDay = useCollaboratorBookingByDay();
  const weekDays = useWeekDays();

  const permissions = useBookingPermissions();
  const space = useOfficeSpace();
  const organization = useOrganization();
  const color = organization.getCollaboratorColor(myEmployee.id);

  const balance = permissions.getBookingBalance(myEmployee.id, weekDays);

  return (
    <>
      {!isBookingBar && (
        <Tippy content={<BookingStatus balance={balance} />} theme="light">
          <div className="weekly-row my-booking-row-name " id="my-row" key="something">
            <GenericAvatar path={myEmployee?.picturePath} /> {myEmployee?.name}
          </div>
        </Tippy>
      )}
      {weekDays.map(day => (
        <DayBlock
          collaborator={myEmployee}
          key={day}
          booking={myBookingByDay[day]}
          color={color}
          day={day}
          isBookingBar={props.isBookingBar}
          weekDays={weekDays}
          permissions={permissions}
          space={space}
        />
      ))}
    </>
  );
};
