import { AuthResult, AuthUser } from "./AuthService";
import RestRepository from "../../infra/RestRepository";

export default class AuthRestRepo extends RestRepository {
  constructor() {
    super("/api/auth", false);
  }

  async getAuthUser(): Promise<AuthUser> {
    return super.get("");
  }

  async login(authResult: AuthResult): Promise<AuthUser> {
    return super.post("/login", authResult);
  }

  logout() {
    return super.post("/logout", {});
  }
}
