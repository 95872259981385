import React from "react";
import classNames from "classnames";

import { Booking } from "../../../domain/booking/Booking";
import {
  useBookingPermissions,
  useCollaboratorBookingByDay,
  useImageURL,
  useOfficeSpace,
  useWeekDays,
} from "../../hook/hooks";
import BookingPermission from "../../../domain/booking/BookingPermission";
import { BookedBlock, NonBookedBlock } from "../../component/blocks/Blocks";
import OfficeSpace from "../../../domain/building/OfficeSpace";
import { Collaborator } from "../../../domain/collaborator/Collaborator";

const ROW_HEIGHT = "36px";
const HIGHTLIGHT_COLOR = "#f1f1f1";

const cellStyle: any = {
  alignItems: "center",
  display: "flex",
  height: ROW_HEIGHT,
  justifyContent: "center",
};

export const GenericAvatar = (props: { path?: string }) => {
  const imageURL = useImageURL(props.path);

  return (
    <div
      style={{
        alignItems: "center",
        backgroundColor: "#ccc",
        border: "1px solid #aaa",
        borderRadius: "32px",
        display: "flex",
        height: "30px",
        justifyContent: "center",
        marginRight: "10px",
        overflow: "hidden",
        width: "30px",
      }}
    >
      <img src={imageURL} style={{ width: "100%" }} />
    </div>
  );
};

const DayCell = (props: {
  booking?: Booking;
  color: any;
  collaborator: Collaborator;
  day: string;
  lastRow: any;
  rowIndex: any;
  weekDays: string[];
  permissions: BookingPermission;
  space: OfficeSpace;
}) => {
  const { collaborator, lastRow, rowIndex, permissions, space } = props;

  const index = props.weekDays.indexOf(props.day);

  const backgroundColor = props.booking?.type === "office" ? props.color : "#eee";

  return (
    <div
      className={classNames({
        "booking-cell": true,
        "booking-cell-present": !!props.booking,
      })}
      key={`day-block-${index}`}
      style={{
        backgroundColor: (props.booking ? backgroundColor : "#f9f9f9") as string,
        borderBottom: lastRow ? "1px solid #ddd" : "unset",
        borderBottomLeftRadius: lastRow && index === 0 ? "5px" : "unset",
        borderBottomRightRadius: lastRow && index === 4 ? "5px" : "unset",
        borderLeft: "1px solid #ddd",
        borderRight: index === 4 ? "1px solid #ddd" : "unset",
        borderTop: rowIndex === 0 ? "1px solid #ddd" : "unset",
        borderTopLeftRadius: rowIndex === 0 && index === 0 ? "5px" : "unset",
        borderTopRightRadius: rowIndex === 0 && index === 4 ? "5px" : "unset",
        boxSizing: "border-box",
        gridColumnStart: index + 2,
        paddingBottom: "0px",
        paddingTop: "0px",
        position: "static",
        top: "unset",
        ...cellStyle,
      }}
    >
      {!props.booking && (
        <NonBookedBlock
          day={props.day}
          collaborator={collaborator}
          permissions={permissions}
        />
      )}

      {!!props.booking && (
        <BookedBlock booking={props.booking} permissions={permissions} space={space} />
      )}
    </div>
  );
};

export const EmployeeBookingRow = (props: {
  color?: string;
  collaborator: Collaborator;
  isHightlighted?: boolean;
  lastRow?: boolean;
  rowId: number;
  rowIndex: number;
  todayIndex?: number;
}) => {
  const {
    color = "#ccc",
    collaborator,
    isHightlighted,
    lastRow = false,
    rowId,
    rowIndex,
  } = props;

  const permissions = useBookingPermissions();
  const space = useOfficeSpace();
  const weekDays = useWeekDays();

  const employeeBookingByDay = useCollaboratorBookingByDay(collaborator.id);

  return (
    <>
      {[1, 2, 3, 4, 5, 6].map(index => (
        <div
          key={`inbetween-before-${index}`}
          style={{
            borderLeft: rowId !== 4 && index !== 1 ? "1px solid #ddd" : "unset",
            borderRight: rowId !== 4 && index === 6 ? "1px solid #ddd" : "unset",
            boxSizing: "border-box",
            gridColumnStart: index,
            height: "5px",
            width: "100%",
          }}
        ></div>
      ))}
      <div
        className="weekly-row"
        id={`employee-row-${collaborator.id}`}
        key="something"
        style={{
          gridColumnStart: 1,
          marginLeft: "20px",
          ...cellStyle,
          backgroundColor: isHightlighted ? HIGHTLIGHT_COLOR : "unset",
          height: "100%",
          justifyContent: "left",
          paddingBottom: "0px",
          position: "static",
          top: "unset",
        }}
      >
        <GenericAvatar path={collaborator.picturePath} /> {collaborator.name}{" "}
      </div>
      {weekDays.map(day => (
        <DayCell
          key={day}
          permissions={permissions}
          space={space}
          booking={employeeBookingByDay[day]}
          color={color}
          day={day}
          collaborator={collaborator}
          lastRow={lastRow}
          rowIndex={rowIndex}
          weekDays={weekDays}
        />
      ))}
    </>
  );
};
