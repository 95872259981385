import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import GoogleInstallRepo from "../../domain/google-install/GoogleInstallRestRepo";
import { GoogleInstall } from "../../domain/google-install/GoogleInstall";
import { showError, showSuccess } from "../../domain/notification/NotificationService";
import { getUserMessage } from "../../infra/RestRepository";

const googleInstallRepo = new GoogleInstallRepo();

type UpdateGoogleInstall = GoogleInstall & { code?: string };

export default () => {
  const [googleInstall, setGoogleInstall] = useState<GoogleInstall | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();

  const createGoogleInstall = useCallback(
    // TODO @Billy
    (code: string) => {
      setIsLoading(true);

      return googleInstallRepo
        .post("/", { code })
        .then(res => {
          setIsLoading(false);

          setGoogleInstall(res);

          showSuccess(t("google.connect-success"));

          return res;
        })
        .catch(e => {
          setIsLoading(false);
          showError(getUserMessage(e) || t("google.connect-error"));
        });
    },
    [t]
  );

  const updateGoogleInstall = useCallback(
    (data: UpdateGoogleInstall) => {
      if (!googleInstall) return;

      setIsLoading(true);

      return googleInstallRepo
        .put(`/${googleInstall.id}`, { ...data })
        .then(res => {
          setGoogleInstall(res);

          setIsLoading(false);

          showSuccess(t("google.update-success"));

          return res;
        })
        .catch(e => {
          showError(getUserMessage(e) || t("google.update-error"));
          setIsLoading(false);
        });
    },
    [googleInstall, t]
  );

  const deleteGoogleInstall = useCallback(() => {
    if (!googleInstall?.id) return null;

    setIsLoading(true);

    return googleInstallRepo
      .delete(`/${googleInstall.id}`)
      .then(res => {
        setGoogleInstall(null);

        setIsLoading(false);

        showSuccess(t("google.disconnect-success"));

        return res;
      })
      .catch(() => {
        setIsLoading(false);

        showError(t("google.disconnect-error"));
      });
  }, [googleInstall, t]);

  useEffect(() => {
    googleInstallRepo
      .get("/")
      .then(res => {
        if (Object.keys(res).length > 0) {
          setGoogleInstall(res);
        }

        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  return {
    isLoading,
    googleInstall,
    deleteGoogleInstall,
    setGoogleInstall,
    createGoogleInstall,
    updateGoogleInstall,
  };
};
