import { Booking } from "../../../../domain/booking/Booking";
import BookingPermission from "../../../../domain/booking/BookingPermission";
import {
  addBooking,
  removeBooking,
  setHighlightedCollaborators,
  switchBookingDesk,
} from "../../../redux/actions";
import { Dispatch } from "redux";
import { Desk } from "../../../../domain/floorPlan/FloorPlan";
import { showWarning } from "../../../../domain/notification/NotificationService";
import { Collaborator } from "../../../../domain/collaborator/Collaborator";
import { AuthUser } from "../../../../domain/auth/AuthService";

export enum DragType {
  drag_to_desk = "drag-to-desk",
}

export type DragToDesk = {
  type: DragType.drag_to_desk;
  collaboratorId: number;
  bookingId?: number;
};

export default class FloorViewerController {
  readonly permissions: BookingPermission;
  readonly dispatch: Dispatch<any>;
  readonly day: string;
  readonly user: AuthUser;
  readonly collaborators: Collaborator[];

  constructor(
    dispatch: Dispatch,
    permissions: BookingPermission,
    day: string,
    user: AuthUser,
    collaborators: Collaborator[]
  ) {
    this.permissions = permissions;
    this.dispatch = dispatch;
    this.day = day;
    this.user = user;
    this.collaborators = collaborators;
  }

  hasUserBookingToMove() {
    return this.permissions.hasBookingToMove(this.user.collaboratorId, this.day);
  }

  isDeskBookable(desk: Desk, collaboratorId?: number) {
    return this.permissions.isDeskBookable(
      collaboratorId || this.user.collaboratorId,
      this.day,
      desk.id
    );
  }

  isBookingRemovable(booking: Booking) {
    return this.permissions.isBookingRemovable(booking);
  }

  isBookingMovable(booking: Booking) {
    return this.permissions.isBookingMovable(booking);
  }

  removeBooking(booking: Booking) {
    this.dispatch(removeBooking(booking));
  }

  bookDesk(desk: Desk) {
    const existing = this.permissions.findOfficeBooking(
      this.user.collaboratorId,
      this.day
    );

    if (existing) {
      this.dispatch(switchBookingDesk({ bookingId: existing.id, deskId: desk.id }));
    } else {
      this.dispatch(
        addBooking({
          collaboratorId: this.user.collaboratorId,
          date: this.day,
          deskId: desk.id,
          type: "office",
        })
      );
    }

    this.dispatch(setHighlightedCollaborators(null));
  }

  getBookingName(booking: Booking) {
    return (
      this.collaborators.find(emp => emp.id === booking.collaboratorId)?.name || "NA"
    );
  }

  getBookingImagePath(booking: Booking) {
    return this.collaborators.find(emp => emp.id === booking.collaboratorId)?.picturePath;
  }

  onDropDesk(data: DragToDesk, desk: Desk) {
    if (!data.collaboratorId) return;

    if (!this.isDeskBookable(desk, data.collaboratorId)) {
      showWarning("This desk is not eligible");
      return;
    }

    if (data.bookingId) {
      this.dispatch(
        switchBookingDesk({
          bookingId: data.bookingId,
          deskId: desk.id,
        })
      );
    } else {
      this.dispatch(
        addBooking({
          date: this.day,
          deskId: desk.id,
          collaboratorId: data.collaboratorId,
          type: "office",
        })
      );
    }

    this.dispatch(setHighlightedCollaborators(null));
  }
}
