import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { GoogleLoginResponse, GoogleLoginResponseOffline } from "react-google-login";

// Ant
import { Card, Col, Row } from "antd";

import { ModalSetting } from "../../../../component/modal-settings/ModalSetting";
import { GoogleIntegrationForm } from "./GoogleIntegrationForm";
import "../../integration-setting.less";

// hooks
import useGoogleIntegration from "../../../../hook/useGoogleIntegration";
import { showError } from "../../../../../domain/notification/NotificationService";

import GoogleIntegrationButton from "./GoogleIntegrationButton";
import { GoogleInstall } from "../../../../../domain/google-install/GoogleInstall";

export type GoogleRenderProps = {
  disabled?: boolean | undefined;
  onClick: () => void;
};

export type GoogleResponseSuccess = GoogleLoginResponseOffline | GoogleLoginResponse;

export type GoogleResponseFailure = {
  error?: string;
};

export default () => {
  // State
  const [openSettings, setOpenSettings] = useState(false);

  // hooks
  const { t } = useTranslation();
  const {
    isLoading,
    googleInstall,
    createGoogleInstall,
    deleteGoogleInstall,
    updateGoogleInstall,
  } = useGoogleIntegration();

  const submit = useCallback(
    async (values: GoogleInstall) => {
      await updateGoogleInstall(values);

      setOpenSettings(false);
    },
    [updateGoogleInstall]
  );

  const responseGoogleFailure = useCallback(
    (response: GoogleResponseFailure) => {
      if (response.error) {
        showError(t("google.connect-error"));
      }
    },
    [t]
  );

  const responseGoogleSuccess = useCallback(
    async (response: GoogleResponseSuccess) => {
      if (response.code) {
        if (!googleInstall) {
          await createGoogleInstall(response.code);
        } else {
          await updateGoogleInstall({ ...googleInstall, code: response.code });
        }
      }
    },
    [createGoogleInstall, updateGoogleInstall, googleInstall]
  );

  const onDisconnect = useCallback(async () => {
    await deleteGoogleInstall();
  }, [deleteGoogleInstall]);

  const handleOpenSettings = () => {
    setOpenSettings(true);
  };

  return (
    <Card className="integration-card">
      <Row gutter={[0, 15]} align={"middle"}>
        <Col flex="50px">
          <img
            alt="Connect google"
            height="40"
            src={"/google-log.png"}
            srcSet={"/google-log.png 1x, /google-log.png 2x"}
          />
        </Col>
        <Col md={24} lg={12} xl={12}>
          <span className={"integration-title"}>Google</span>
        </Col>
        <Col md={24} lg={24} xl={24}>
          <span className={"integration-description"}>
            {t("google.integration-description")}
          </span>
        </Col>
        <Col span={24}>
          <GoogleIntegrationButton
            {...{
              responseGoogleSuccess,
              responseGoogleFailure,
              handleOpenSettings,
              onDisconnect,
              isLoading,
            }}
            refreshTokenIsExpired={googleInstall?.refreshTokenExpired}
            isConnected={googleInstall !== null}
          />
        </Col>
      </Row>

      <ModalSetting
        onCancel={() => setOpenSettings(false)}
        title={t("google.settings")}
        state={openSettings}
      >
        <GoogleIntegrationForm
          onFinish={submit}
          defaultValues={{
            ...googleInstall,
          }}
          isLoading={isLoading}
        />
      </ModalSetting>
    </Card>
  );
};
