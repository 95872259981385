import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useWorkspace } from "../../hook/hooks";
import { Col, Divider, Row } from "antd";
import { useDispatch } from "react-redux";
import { saveWorkspace } from "../../redux/actions";

// Components
import BookingSettingsForm from "./workspace/BookingSettingsForm";
import EmailsSettingsForm from "./workspace/EmailsSettingsForm";

import { BookingSettingsFormData } from "../../../domain/workspace/Workspace";

export default () => {
  const { t } = useTranslation();
  const workspace = useWorkspace();

  const dispatch = useDispatch();

  const onSave = useCallback(
    (data: BookingSettingsFormData) => {
      dispatch(saveWorkspace({ ...data }));
    },
    [dispatch]
  );

  return (
    <Row>
      <Col span={24}>
        <h2>{t("workspace.collaborator-bookings")}</h2>
      </Col>
      <Col span={10}>
        <BookingSettingsForm onSave={onSave} initialValues={workspace} />
      </Col>

      <Divider />

      <Col span={14}>
        <EmailsSettingsForm />
      </Col>
    </Row>
  );
};
