import * as React from "react";

import { EmployeeBookingRow } from "./EmployeeBookingRow";
import { MyBookingRow } from "../../component/my-booking-bar/MyBookingRow";

import "./weeklyCalendar.css";
import { useOrganization, useSelectedDay } from "../../hook/hooks";
import Week from "../../../domain/week/Week";
import { Community } from "../../../domain/community/Community";
import { Collaborator } from "../../../domain/collaborator/Collaborator";
import CommunityTag from "../../component/people-tag/PeopleTag";

type CalendarSection = {
  community: Community;
  collaborators: Collaborator[];
};

export const WeeklyCalendar = (props: {
  collaboratorId?: number;
  communityId?: number;
}) => {
  const { collaboratorId, communityId } = props;

  const org = useOrganization();
  const selectedDay = useSelectedDay();
  const selectedDayIndex = Week.dayIndex(selectedDay);

  let community;
  if (collaboratorId) {
    community = org.findCommunityByCollaboratorId(collaboratorId);
  } else if (communityId) {
    community = org.findCommunityById(communityId);
  }

  const selectedCommunities = community ? org.findAllChildren(community) : [];

  const calendarSections: CalendarSection[] = selectedCommunities
    .map(c => ({
      community: c,
      collaborators: org.findEnabledCollaboratorsByCommunityId(c.id),
    }))
    .filter(section => section.collaborators.length > 0);

  React.useEffect(() => {
    if (collaboratorId) {
      const elId = `employee-row-${collaboratorId}`;
      const el = document.getElementById(elId);
      if (el) {
        el.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
  }, [collaboratorId]);

  return (
    <div
      style={{
        border: "0px solid red",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "200px 1fr 1fr 1fr 1fr 1fr",
          gridTemplateRows: "20px 42px auto",
          overflow: "auto",
          paddingRight: "20px",
        }}
      >
        <div style={{ gridColumnEnd: 7, gridColumnStart: 1 }}></div>

        <MyBookingRow />

        {calendarSections.map(section => (
          <React.Fragment key={section.community.id}>
            <div
              className="mt-md"
              style={{
                alignItems: "center",
                display: "flex",
                gridColumnEnd: 7,
                gridColumnStart: 1,
                height: "30px",
              }}
            >
              <CommunityTag community={section.community} size="lg" />
            </div>

            {section.collaborators.map((collab, index) => (
              <EmployeeBookingRow
                key={collab.id}
                color={org.getCollaboratorColor(collab.id)}
                collaborator={collab}
                isHightlighted={collab.id === collaboratorId}
                lastRow={index === section.collaborators.length - 1}
                rowId={index + 4}
                rowIndex={index}
                todayIndex={selectedDayIndex}
              />
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
