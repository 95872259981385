import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Menu } from "antd";
import {
  CalendarOutlined,
  DashboardOutlined,
  EnvironmentOutlined,
  LineChartOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useAuthUser, useRoutePage, useWorkspace } from "../hook/hooks";

export enum Pages {
  floorplans = "floorplans",
  policies = "policies",
  calendars = "calendars",
  userAccount = "user-account",
  settings = "settings",
  dashboard = "dashboard",
}

export type Page =
  | Pages.policies
  | Pages.calendars
  | Pages.floorplans
  | Pages.userAccount
  | Pages.settings;

export default () => {
  const isHrManager = useAuthUser().role === "admin";
  const { t } = useTranslation();

  const history = useHistory();
  const workspace = useWorkspace();
  const page = useRoutePage() || Pages.calendars;

  const onSelectMenu = useCallback(
    data => {
      history.push(`/${workspace.name}/${data.key}`);
    },
    [history, workspace.name]
  );

  return (
    <Menu
      defaultOpenKeys={[]}
      inlineCollapsed={true}
      mode="inline"
      onSelect={onSelectMenu}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "absolute",
        zIndex: 10000,
      }}
      theme="dark"
      selectedKeys={[page]}
    >
      <div
        style={{
          alignContent: "center",
          alignItems: "center",
          color: "white",
          display: "flex",
          height: "60px",
          justifyContent: "center",
        }}
      >
        Semana
      </div>

      <Menu.Item icon={<CalendarOutlined />} key={Pages.calendars}>
        {t("main.calendar")}
      </Menu.Item>

      <Menu.Item icon={<EnvironmentOutlined />} key={Pages.floorplans}>
        {t("main.floorplan")}
      </Menu.Item>

      {isHrManager && (
        <Menu.Item
          icon={<LineChartOutlined />}
          key={Pages.policies}
          style={{ marginTop: "0px" }}
        >
          {t("main.planning")}
        </Menu.Item>
      )}

      {isHrManager && (
        <Menu.Item
          icon={<DashboardOutlined />}
          key={Pages.dashboard}
          style={{ marginTop: "0px" }}
        >
          {t("main.dashboard")}
        </Menu.Item>
      )}

      {isHrManager && (
        <Menu.Item
          icon={<SettingOutlined />}
          key={Pages.settings}
          style={{ marginTop: "0px" }}
        >
          {t("main.settings")}
        </Menu.Item>
      )}

      <Menu.Item key="user-account" icon={<UserOutlined />}>
        {t("main.user-account")}
      </Menu.Item>
    </Menu>
  );
};
