import { Building, Floor } from "./Building";
import { FloorPlan } from "../floorPlan/FloorPlan";

type BuildingSpace = Building & { floorSpaces: FloorSpace[] };
type FloorSpace = Floor & { floorPlan?: FloorPlan };

export default class OfficeSpace {
  readonly buildings: BuildingSpace[];

  constructor(buildings: Building[], floorPlans: FloorPlan[]) {
    this.buildings = buildings.map(b => ({
      ...b,
      floorSpaces: b.floors.map(f => ({
        ...f,
        floorPlan: floorPlans.find(fp => fp.id === f.floorPlanId),
      })),
    }));
  }

  isDeskInFloor(deskId: number, floorId: number) {
    for (const building of this.buildings) {
      for (const floor of building.floorSpaces) {
        for (const desk of floor.floorPlan?.desks || []) {
          if (desk.id === deskId) return floor.id === floorId;
        }
      }
    }
  }

  getDesk(deskId: number) {
    for (const building of this.buildings) {
      for (const floor of building.floorSpaces) {
        for (const desk of floor.floorPlan?.desks || []) {
          if (desk.id === deskId) return desk;
        }
      }
    }
  }

  getFloorName(floorId: number) {
    for (const building of this.buildings) {
      for (const floor of building.floors) {
        if (floor.id === floorId) {
          return building.showInFloorName ? `${building.name} ${floor.name}` : floor.name;
        }
      }
    }
  }

  getFloorIdByDeskId(deskId: number) {
    return this.getFloorByDeskId(deskId)?.id;
  }

  getFloorByDeskId(deskId: number) {
    for (const building of this.buildings) {
      for (const floor of building.floorSpaces || []) {
        for (const desk of floor.floorPlan?.desks || []) {
          if (desk.id === deskId) return floor;
        }
      }
    }
  }

  buildDeskIdToFloorId() {
    const res: { [key: number]: number } = {};

    for (const building of this.buildings) {
      for (const floor of building.floorSpaces || []) {
        for (const desk of floor.floorPlan?.desks || []) {
          res[desk.id] = floor.id;
        }
      }
    }

    return res;
  }

  getAllFloors() {
    let floors: Floor[] = [];
    for (const building of this.buildings) {
      floors = [...floors, ...building.floors];
    }
    return floors;
  }

  getFloorPlanByFloorId(floorId: number) {
    for (const building of this.buildings) {
      for (const floor of building.floorSpaces) {
        if (floor.id === floorId) return floor.floorPlan;
      }
    }
  }

  getFloorById(floorId: number) {
    return this.getAllFloors().find(f => f.id === floorId);
  }

  getNotDisableDeskByFloorId(floorId?: number) {
    if (!floorId) return;

    const floorSpace = this.findFloorSpace(floorId);
    return floorSpace?.floorPlan?.desks.filter(d => !d.disabled);
  }

  private findFloorSpace(floorId: number) {
    for (const building of this.buildings) {
      for (const floorSpace of building.floorSpaces) {
        if (floorSpace.id === floorId) return floorSpace;
      }
    }
  }

  getMaxBookingByFloorId(floorId?: number) {
    if (!floorId) return;

    const floorSpace = this.findFloorSpace(floorId);
    if (!floorSpace) return;

    const notDisabledDeskCount = this.getNotDisableDeskByFloorId(floorId)?.length || 0;

    if (
      floorSpace.maxBooking !== undefined &&
      floorSpace.maxBooking !== null &&
      floorSpace.maxBooking >= 0
    )
      return Math.min(floorSpace.maxBooking, notDisabledDeskCount);

    return notDisabledDeskCount;
  }
}
